<template>
    <div class="all three">
        <div>
            <img v-lazy="require(`../../assets/20230905/${this.$i18n.locale}/index001.png`)" v-show="vindex==0" class="img"/>
            <img v-lazy="require(`../../assets/20230905/${this.$i18n.locale}/index002.png`)" v-show="vindex==1" class="img"/>
            <img v-lazy="require('../../assets/20230905/index003.png')" v-show="vindex==2" class="img"/>
        </div>

        <div class="w-002 flex row-start row-center">
                <div class="txt_01" :class="vindex == 0 ? 'border001' : 'color_07'" @click="go(0)"> {{ $t('message.index.message') }}</div>
                <div class="txt_01" :class="vindex == 1 ? 'border001' : 'color_07'" @click="go(1)"> {{ $t('message.index.call') }}</div>
                <div class="txt_01 w-34" :class="vindex == 2 ? 'border001' : 'color_07'" @click="go(2)">{{ $t('message.index.alert') }}</div>
            </div>
            <!-- <div class="swiper-scrollbar">
                <div class="swiper-scrollbar-drag" :class="tclass"></div>
            </div> -->


        <!-- <div class="swiper-container">
            <div class="swiper-wrapper" >


                <div class="swiper-slide" v-for="(item, index) in swiperList" :key="index">
                    <img :src="item" alt class="img" />

                </div>
            </div>
            <div class="w-002 flex row-between row-center">
                <div class="txt_01" :class="vindex == 0 ? '' : 'color_07'" @click="go(0)">Message{{ vindex }}</div>
                <div class="txt_01" :class="vindex == 1 ? '' : 'color_07'" @click="go(1)">Call</div>
                <div class="txt_01" :class="vindex == 2 ? '' : 'color_07'" @click="go(2)">Notification</div>
            </div>
            <div class="swiper-scrollbar">
                <div class="swiper-scrollbar-drag" :class="tclass"></div>
            </div>
        </div> -->
    </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";


export default {
    data() {
        return {
            // 轮播图
            // swiperList: [
            //     require("../../assets/20230905/index001.png"),
            //     require("../../assets/20230905/index002.png"),
            //     require("../../assets/20230905/index003.png"),
            // ],
            vindex: 0,
            tclass:'anm0',
            sclass:'s_0',
            // index1:require('../../assets/20230905/'+this.$i18n.locale+'/index001.png'),
            // index2:require('../../assets/20230905/'+this.$i18n.locale+'/index002.png'),
        };
    },
    activated() {
        // this.$nextTick(() => {
        //     this.swiper();
        // });
    },
    methods: {
        // 轮播图
        swiper() {
            let vm = this;
            new Swiper(".three .swiper-container", {
                scrollbar: {
                    el: '.three .swiper-scrollbar'
                },
                on: {
                    slideChangeTransitionEnd: function () {
                        var mindex = this.realIndex;
                        vm.onUpdate(mindex);

                    }
                }
                
            });
        },
        onUpdate(mindex) {
            this.vindex = mindex;
            var temp=['anm0','anm1','anm2'];
            this.tclass=temp[mindex];
        },
        go(index){
            console.log('index',index);
            this.vindex = index;
            // var temp=['anm0','anm1','anm2'];
            // this.tclass=temp[index];
            // var temp2=['s_0','s_1','s_2'];
            // this.sclass=temp2[index];
        }
    },
};
</script>
<style lang="less">
/*小圆点的样式，需要单独写一个不带scoped属性的style标签*/
// .swiper-pagination-bullet {
//     width: 20px !important;
//     height: 2px !important;
//     background-color: #fff !important;
//     border-radius: 0px;
//     opacity: 0.3 !important;

//     &.swiper-pagination-bullet-active {
//         background-color: #fff;
//         opacity: 1 !important;
//     }
// }

// .swiper-pagination-fraction,
// .swiper-pagination-custom,
// .swiper-container-horizontal>.swiper-pagination-bullets {
//     // bottom: -0.4rem !important;
// }

// .swiper-pagination-fraction,
// .swiper-pagination-custom,
// .swiper-container-horizontal>.swiper-pagination-bullets {
//     bottom: 1px !important;
// }
</style>
<style lang='less' scoped>
.all {
    width: 100%;

    .swiper-container {
        padding-bottom: 0.15rem;
    }

    .w-002 {
        width: 3.3rem;
        margin: 0 auto;
        margin-top: 0.24rem;
        //border-bottom: 0.04rem solid rgba(255, 255, 255, 0.3);
    }

    .txt_01 {
        font-family: League Spartan;
        font-size: 0.21rem;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        color: #fff;
        width: 33%;
        text-align: center;
        padding-bottom: 0.14rem;
        border-bottom: 0.04rem solid rgba(255, 255, 255, 0.3);
    }
    .w-34{
        width: 34%;
    }
    .border001{
        border-bottom: 0.04rem solid rgba(255, 255, 255, 1);
    }
    .color_07 {
        color: rgba(255, 255, 255, 0.7);
    }



    .swiper-container-horizontal>.swiper-scrollbar {
        position: absolute;
        bottom: 0.03rem;
        z-index: 50;
        height: 5px;
    }


    .swiper-scrollbar {
        background: rgba(255, 255, 255, 0.3) !important;
        width: 3.3rem;
        margin-left: 0.225rem;


    }

    .swiper-scrollbar-drag {
        height: 100%;
        width: 100%;
        position: relative;
        background: rgba(255,255,255,1);
        border-radius: 10px;
        left: 0;
        top: 0;
        width: 1.1rem;
    }

    .s_0{
        transform: translate3d(0px, 0px, 0px) !important; transition-duration: 0ms;
    }
    .s_1{
        transform: translate3d(-414px, 0px, 0px) !important; transition-duration: 0ms;
    }
    .s_2{
        transform: translate3d(-828px, 0px, 0px) !important; transition-duration: 0ms;
    }
    
    
    .anm0{
        transform: translate3d(0px, 0px, 0px) !important
    }
    .anm1{
        transform: translate3d(1.1rem, 0px, 0px) !important
    }
    .anm2{
        transform: translate3d(2.2rem, 0px, 0px) !important
    }

    .img {
        width: 100%;
    }

    // .swiper-pagination {
    //     width: 2.21rem;
    //     height: 0.4rem;
    //     border-radius: 0.2rem;
    //     position: absolute;
    //     left: 50%;
    //     margin-left: -1.105rem;
    //     padding: 0.155rem 0.5rem;
    //     margin-top: 0.1rem;
    // }
}

.swiper-scrollbar-drag {
    background: rgba(255, 255, 255, 1) !important;
}
</style>