import { render, staticRenderFns } from "./ShiftLock.vue?vue&type=template&id=afd9484a&scoped=true&"
import script from "./ShiftLock.vue?vue&type=script&lang=js&"
export * from "./ShiftLock.vue?vue&type=script&lang=js&"
import style0 from "./ShiftLock.vue?vue&type=style&index=0&id=afd9484a&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "afd9484a",
  null
  
)

export default component.exports