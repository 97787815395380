<template>
  <div class="all">
    <div class="flex row-between w-1">
      <div>
        <img src="../../../assets/20240109/001.svg" class="img-w-1" />
        <div class="w-2">
          <div class="title">Full-featured Navigation App</div>
          <div class="txt-1">
            The Blucap App integrates map SDKs from
            <span class="border">Google Maps</span> and
            <span class="border">MapBox</span>. It includes all the essential
            features for riding and maintaining a classic and simple user
            interface.
          </div>
        </div>
      </div>
      <div>
        <img src="../../../assets/20240109/002.svg" class="img-w" />
      </div>
    </div>
    <div class="w-2-01" id="sc">
      <div class="container w1">
        <img src="../../../assets/20240109/pc/01.png" class="card" />
        <img src="../../../assets/20240109/pc/02.png" class="card" />
        <img src="../../../assets/20240109/pc/03.png" class="card" />
        <img src="../../../assets/20240109/pc/04.png" class="card" />
        <img src="../../../assets/20240109/pc/05.png" class="card" />
        <img src="../../../assets/20240109/pc/06.png" class="card" />
        <img src="../../../assets/20240109/pc/07.png" class="card" />
        <img src="../../../assets/20240109/pc/08.png" class="card" />
        <div class="blank"></div>
        <!-- 添加一个空白的div -->
      </div>
    </div>
    <div class="w-3-02">
      <div class="wcenter flex row-end">
        <div
          class="next"
          :class="position1 == 0 ? 'active' : ''"
          @click="prev"
        ></div>
        <div class="prev" :class="end == 1 ? 'active' : ''" @click="next"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cards: [],
      position: 0,
      position1: 0,
      end: 0,
    }
  },
  mounted() {
    let cards = document.querySelectorAll('.card')
    this.cards = cards
    document
      .querySelector('#sc') //绑定指定页面滚动区域
      .addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll(e) {
      const sc = document.getElementById('sc')
      var left = sc.scrollLeft
      var wh = sc.clientWidth
      var sh = sc.scrollWidth

      if (left == 0) {
        this.position1 = 0
      } else {
        this.position1 = 1
      }
      if (left + wh < sh - 10) {
        this.end = 0
      } else {
        this.end = 1
      }
    },

    prev() {
      //this.position = Math.max(this.position - 1, 0)
      const sc = document.getElementById('sc')
      var left = sc.scrollLeft
      var wh = sc.clientWidth
      var sh = sc.scrollWidth
      console.log('left', left, wh, sh)
      this.end = 0
      // this.cards[this.position].scrollIntoView({
      //   behavior: 'smooth',
      //   block: 'nearest',
      //   inline: 'start',
      // })
      if (left > 300) {
        sc.scrollTo({
          left: left - 300,
          behavior: 'smooth',
        })
      } else {
        //const sc = document.getElementById('sc')
        sc.scrollTo({
          left: 0,
          behavior: 'smooth',
        })
        this.position1 = 0
      }

      // if (this.position == 0) {
      // }
    },
    next() {
      const sc = document.getElementById('sc')
      var left = sc.scrollLeft
      var wh = sc.clientWidth
      var sh = sc.scrollWidth
      this.position1 = 1
      if (left + wh < sh) {
        //const sc = document.getElementById('sc')
        sc.scrollTo({
          left: left + 300,
          behavior: 'smooth',
        })
        // this.position = Math.min(this.position + 1, this.cards.length - 1)
        // this.cards[this.position].scrollIntoView({
        //   behavior: 'smooth',
        //   block: 'nearest',
        //   inline: 'start',
        // })
      } else {
        this.end = 1
      }
    },
  },
}
</script>

<style lang="less" scoped>
.all {
  width: 100%;
  background-color: #000;
  padding-top: 90px;

  .w-1 {
    width: 100%;
    max-width: 1431px;
    margin: 0 auto;
    .img-w-1 {
      width: 148px;
    }
    .w-2 {
      width: 748px;

      .title {
        color: #fff;
        font-feature-settings: 'ss02' on;
        font-family: League Spartan;
        font-size: 42px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 48px;
      }

      .txt-1 {
        color: rgba(255, 255, 255, 0.7);
        font-feature-settings: 'ss02' on;
        /* Mobile-text1 */
        font-family: League Spartan;
        font-size: 21px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        margin-top: 24px;
        .border {
          font-weight: 500;
          color: #fff;
        }
      }
    }
    .img-w {
      width: 683px;
    }
  }
  .w-2-01 {
    width: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: auto;
    // max-width: 1431px;
    // margin: 0 auto;
    margin-top: -213px;
  }
  .w1 {
    max-width: 1400px;
    display: flex;
    overflow-x: visible;
    white-space: nowrap;
  }
  .blank {
    //width: calc((100vw - 800px) / 2);
    width: 300px;
    /* 这里计算空白区域的宽度，使卡片能在屏幕上居中 */
    flex-shrink: 0;
  }

  /* 隐藏滚动条 */
  .w1::-webkit-scrollbar {
    display: none;
  }
  .w-2-01::-webkit-scrollbar {
    display: none;
  }
  .card {
    display: inline-block;
    height: 595px;
    margin-right: 24px;
  }
  .w-3-02 {
    width: 100%;
    .wcenter {
      width: 100%;
      max-width: 1431px;
      margin: 0 auto;
      margin-top: 42px;
      margin-bottom: 42px;
      .next {
        background-image: url(../../../assets/20230905/ArrowCircleLeft.svg) !important;
        width: 42px;
        height: 42px;
        background-size: 100% 100%;
        cursor: pointer;
      }
      .prev {
        background-image: url(../../../assets/20230905/ArrowCircleRight.svg) !important;
        width: 42px;
        height: 42px;
        background-size: 100% 100%;
        margin-left: 42px;
        cursor: pointer;
      }
      .active {
        opacity: 0.35;
      }
    }
  }
}
</style>
