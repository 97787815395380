import Vue from "vue"
import VueI18n from "vue-i18n"
import zhLange from './zh'
import enLange from './en'
import itLange from './it'
import deLange from './de'
import jaLange from './ja'
import frLange from './fr'
import esLange from './es'
import twLange from './tw'


Vue.use(VueI18n)

// const messages = {
//     //简体中文
//     cn: {
//         message: {
//             hello: '你好',
//             author: '龙的传人'
//         }
//     },
//     //英文
//     en: {
//         message: {
//             hello: 'Hello',
//             author: 'Chinese'
//         }
//     },
//     // 繁体中文
//     tw: {
//         message: {
//             hello: '你好',
//             author: '龍的傳人'
//         }
//     }
// }

let getLocal = () => {//获取浏览器当前设置的语言
    return 'en'
    // // 获取当前网站的完整URL
    // // var url = window.location.href;
    // // // 通过正则表达式解析出域名
    // // var matches = url.match(/^https?:\/\/([^:/]+)/i);
    // // var domain = matches && matches[1];
    // // console.log('domain',domain);

    // //return 'en';
    // let myLocal = localStorage.getItem('language')//从本地缓存获取类型
    // console.log('myLocal',myLocal);
    // let localName ='en';
    // if (myLocal) {//如果本地有资源包，就从本地获取资源包  
    //     if(myLocal.indexOf('it')!=-1){
    //         localName='it'
    //     }
    //     else if(myLocal.indexOf('de')!=-1){
    //         localName='de';
    //     }
    //     else if(myLocal.indexOf('ja')!=-1){
    //         localName='ja';
    //     } 
    //     else if(myLocal.indexOf('fr')!=-1){
    //         localName='fr';
    //     }
    //     else if(myLocal.indexOf('es')!=-1){
    //         localName='es';
    //     }
    //     // else if(myLocal.indexOf('zh')!=-1){
    //     //     localName='zh';
    //     // }
    //     else if(myLocal.indexOf('tw')!=-1){
    //         localName='tw';
    //     }
    //     return localName;
    // }
    // // 否则获取当前网络语言(navigator对象，用于提供当前浏览器及操作系统等信息，这些信息都放在navigator的各个属性中)
    // //let localName ='en';
    // if(navigator.language.indexOf('it')!=-1){
    //     localName='it';
    // }
    // else if(navigator.language.indexOf('de')!=-1){
    //     localName='de';
    // }
    // else if(navigator.language.indexOf('ja')!=-1){
    //     localName='ja';
    // }
    // else if(navigator.language.indexOf('fr')!=-1){
    //     localName='fr';
    // }
    // else if(navigator.language.indexOf('es')!=-1){
    //     localName='es';
    // }
    // // else if(navigator.language.indexOf('zh-CN')!=-1){
    // //     localName='zh';
    // // }
    // else if((navigator.language.indexOf('zh-TW')!=-1)||(navigator.language.indexOf('zh-HK')!=-1)){
    //     localName='tw';
    // }
    // // if(navigator.language.indexOf('zh')!=-1){
    // //     localName='zh';
    // // }else if(navigator.language.indexOf('it')!=-1){
    // //     localName='it'
    // // }
    // //navigator.language.indexOf('zh') !== -1 ? 'zh' : 'en';
    // // 将语言资源存储到本地中(防止刷新后需要重新设置语言)
    // localStorage.setItem('language', localName);
    // console.log('navigator.language',navigator.language,'localName',localName);
    // return localName;

}


// export const i18n = new VueI18n({
//     locale: 'cn', // 默认语言
//     messages: messages

// })

export const i18n = new VueI18n({
    locale: getLocal(),
    messages: {
        'en': enLange,
        'zh': zhLange,
        'it': itLange,
        'de': deLange,
        'ja': jaLange,
        'fr': frLange,
        'es': esLange,
        'tw': twLange,
    }
})