<template>
  <div class="all one">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide bg01"
          v-lazy:background-image="require('../../assets/20230905/111.png')"
        >
          <div class="title">
            {{ $t('message.index.most_cutting') }}
          </div>
          <div class="txt-1">
            <!-- {{ $t('message.index.only_the') }} -->
            Only the utilization of cutting-edge technology can meet Blucap's
            high demands for both <span class="col-lan">design</span> and
            <span class="col-lan">performance</span>.
          </div>
        </div>
        <div
          class="swiper-slide bg02"
          v-lazy:background-image="require('../../assets/20230905/222.png')"
        >
          <div class="title">
            {{ $t('message.index.innovation') }}
          </div>
          <div class="txt-1">
            {{ $t('message.index.customized') }}
          </div>
        </div>
        <div
          class="swiper-slide bg03"
          v-lazy:background-image="require('../../assets/20230905/333.png')"
        >
          <div class="title">
            {{ $t('message.index.smallest') }}
          </div>
          <div class="txt-1">
            The world's
            <span class="col-lan">smallest 0.13" MicroLED Display</span>, with
            high brightness, and ultra-low power consumption that make the
            temples the same as ordinary glasses.
          </div>
        </div>

        <!-- <div class="swiper-slide" v-for="(item, index) in swiperList" :key="index">
                    <img :src="item" alt class="img" />
                </div> -->
      </div>
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'

export default {
  data() {
    return {
      // 轮播图
      // swiperList: [
      //     require("../../assets/20230905/m01.png"),
      //     require("../../assets/20230905/m02.png"),
      //     require("../../assets/20230905/m03.png"),
      // ],
    }
  },
  activated() {
    this.$nextTick(() => {
      this.swiper()
    })
  },

  methods: {
    // 轮播图
    swiper() {
      new Swiper('.one .swiper-container', {
        slidesPerView: 1.16,
        spaceBetween: 10,
        centeredSlides: true,
        pagination: {
          el: '.one .swiper-pagination',
          clickable: true,
        },
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
      })

      // new Swiper(".one .swiper-container", {
      //     pagination: { el: ".one .swiper-pagination" },
      //     //loop: true,
      //     // autoplay: {
      //     //     disableOnInteraction: false,
      //     //     delay: 2000,
      //     // },
      // });
    },
  },
}
</script>
<style lang="less" scoped>
.all {
  width: 100%;

  .swiper-container {
    width: 100%;
    height: 6.6rem;
    padding-bottom: 0.3rem;
  }

  .swiper-slide {
    width: 100%;
    //padding-bottom: 0.3rem;
    //height: 6rem;
  }

  .bg01 {
    //background-image: url(../../assets/20230905/111.png);
    background-size: cover;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
  }

  .bg02 {
    //background-image: url(../../assets/20230905/222.png);
    background-size: cover;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
  }

  .bg03 {
    //background-image: url(../../assets/20230905/333.png);
    background-size: cover;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
  }

  .title {
    margin-top: 3.42rem;
    font-family: League Spartan;
    font-size: 0.27rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    color: #fff;
  }

  .txt-1 {
    font-family: League Spartan;
    font-size: 0.21rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    color: rgba(255, 255, 255, 0.7);
    margin-top: 0.1rem;
    .col-lan {
      color: #fff;
      font-weight: 500;
    }
  }

  .img {
    width: 3.3rem;
    // height: 100%;
    display: block;
    object-fit: cover;
  }

  .anm01 {
    transform: translate3d(30px, 0px, 0px) !important;
    transition-duration: 0ms;
  }
}
</style>
