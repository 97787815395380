<template>
  <div class="footer">
    <img src="../../assets/20230905/logo.png" class="logo" />
    <div class="flex row-between row-center w-1">
      <a href="https://www.instagram.com/blucap_global/"
        ><img src="../../assets/20230905/InstagramLogo.svg" class="simg"
      /></a>
      <a href="https://www.facebook.com/blucapsport/"
        ><img src="../../assets/20230905/FacebookLogo.svg" class="simg"
      /></a>
      <a href="https://www.youtube.com/@Blucap"
        ><img src="../../assets/20230905/YoutubeLogo.svg" class="simg"
      /></a>
      <a href="https://twitter.com/Blucap_global"
        ><img src="../../assets/20230905/TwitterLogo.svg" class="simg"
      /></a>
    </div>
    <div class="txt-1 cus" @click="privacy">
      {{ $t('message.index.privacy_policy') }}
    </div>
    <div class="txt-1">
      {{ $t('message.index.copyright') }}
    </div>
    <div class="xz">
      <select
        class="slect"
        @change="changes"
        v-model="lang"
        style="display: none"
      >
        <option value="en">Engish</option>
        <!-- <option value="it">Italiano</option>
        <option value="de">Deutsch</option>
        <option value="ja">日本語</option>
        <option value="fr">français</option>
        <option value="es">español</option>
        <option value="tw">中文（繁体）</option> -->
        <!-- <option value="zh">中文（简体）</option> -->
      </select>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      lang: this.$i18n.locale.length == 0 ? 'en' : this.$i18n.locale,
    }
  },
  methods: {
    privacy() {
      window.location.href =
        'https://store.blucapsport.com/policies/privacy-policy'
      //this.$router.push({ path: "/privacy" });
    },
    changes(event) {
      var vals = event.target.value
      this.lang = vals
      this.$i18n.locale = vals
      localStorage.setItem('language', vals)
      console.log('lange', vals)
    },
  },
}
</script>
<style lang="less" scoped>
.footer {
  width: 100%;
  background-color: #000;
  text-align: center;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;

  .logo {
    width: 0.91rem;
  }

  .w-1 {
    width: 2.16rem;
    margin-left: 0.795rem;
    margin-right: 0.795rem;
    margin-top: 0.18rem;
  }

  .txt-1 {
    font-family: League Spartan;
    font-size: 0.16rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    color: #fff;
    margin-top: 0.18rem;
  }

  .slect {
    font-family: League Spartan;
    font-size: 0.16rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    color: #fff;
    background-color: #000;
    border: 1.5px solid #fff;
    padding: 0.1rem 0.35rem;
    margin-top: 0.18rem;
    padding-left: 0.16rem;
  }

  .cus {
    cursor: pointer;
  }

  select {
    /*去除外边框*/
    border: none;
    /*适应safari浏览器内容不跑偏*/
    line-height: 1;
    /*将默认的select选择框样式清除*/
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    /*在选择框的最右侧中间显示小箭头图片*/
    background: url('../../assets/20230905/CaretDown.svg') no-repeat scroll
      right center transparent;
    background-size: 0.36rem 0.2rem;
    /*为下拉小箭头留出一点位置，避免被文字覆盖*/
    padding-left: 0.16rem;
    font-size: 1.4rem;
    color: #666666;
    /*去除选中时样式*/
    outline: none;

    /*去除点击一闪而过的灰色背景*/

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
}
</style>
