// en.js
export default {
    message: {
        headers: {
            Moto: 'オートバイ',
            Prescription: '処方箋',
            Replacement_Lenses: '交換用レンズ',
            Blucap_Go: 'Blucap Go',
            News: 'ニュース',
            About_Us: '私たちについて',
            Dealer: 'ディーラー'
        },
        buttons: {
            button1: '予約注文',
            // button2:'今予約します！',
            // button3:'今予約します！',
            button2: 'Kickstarter',
            button3: 'Buy Now',
        },
        index: {
            //tips:'メーカー希望小売価格: 399 ドル。 9月下旬にキックスターター発売。 今すぐ予約して、25% オフの超早割価格で手に入れましょう。',
            //tips:'MSRP：399ドル。 Kickstarterキャンペーンはまもなく開始します。',//0928
            tips: 'Kickstarterでライブ中！20%の特別割引を受けるための制限時間があります！',//1003
            meet: '走行中のあらゆる瞬間に没頭できる、世界初のオートバイ用ナビゲーションサングラスをご紹介します。',
            subscribe: '購読',
            subscribe_for: 'お得な情報を購読する',
            email_placeholder: 'メールアドレス',
            thanks_for: 'サブスクライブいただきありがとうございます！',
            thanks_error: 'サブスクライブに失敗しました',
            pleass_input: '正しいメールアドレスをご入力ください',
            watch_video: 'ビデオを見る',
            most_cutting: '最先端のAR テクノロジー',
            only_the: ' デザインとパフォーマンスの双方に対するBlucap Moto の高い要求を満たすことができるのは、最先端のテクノロジーだけです',
            innovation: 'Blucap Moto™ イノベーション導波路レンズ',
            customized: 'Blucap Moto のカスタマイズされた特殊な導波路レンズは、高い透明性、曇り止め機能、アンチグレア反射、処方オプションなどの優れた機能を備えています。',
            smallest: '世界最小のマイクロLEDディスプレイ',
            temples: ' 世界最小の0.13インチMicroLEDディスプレイで、高輝度と超低消費電力を備えており、テンプルは普通のメガネと変わりません',
            navigation_map: 'ナビゲーションマップ',
            navigation_map_seamless: ' 視界内にあるリアルタイムの地図でスムーズなナビゲーションを体験してください。 ハンドルバーのリモコンで簡単にコントロール。複雑な道路状況でも全てのナビゲーションニーズを満たします。 そのために、ナビゲーショングラスと呼ばれるのです。',
            turn_directions: '方向転換',
            turn_directions_seamlessly: 'Blucap Motoモトは、方向転換時には方向指示器を自動的に表示します。 交差点を通過した後は、集中力に影響の無い通常のサングラス状態にスムーズに移行します。',
            technology: 'アンチテックなデザインに隠されたテクノロジー',
            technology_seamlessly: 'ボタン、LED、USB ソケットのないメガネです。 まさに時間を超えたエレガンスと目に見えない革新性が出会いました。',
            power: '瞬時に電源をオン/オフ',
            power_seamlessly: 'サングラスのテンプルを開くだけで電源が入り、自動的に携帯電話に接続します。 メガネをたたむと電源が切れます。',
            shiftLock: 'シフトロック™ クイックリリース設計',
            shiftLock_seamlessly: 'Blucap Moto 独自の シフトロック™ クイック リリース設計で、交換簡単。 夜間走行、カラー交換レンズ、度付きメガネのニーズに対応します。',
            polarized: '偏光',
            anti_fog: '曇り止め',
            water: '撥水及び撥油剤',
            anti_scratch: 'ひっかき傷防止',
            protection: '100の% UVA/UVB 防止',
            performance: '高性能フレーム',
            contrast: 'コントラストの強調',
            definition: '高解像度',
            message: 'メッセージ',
            call: '電話',
            alert: '警告',
            stay_informed: 'Blucap Moto モトのリアルタイム通話、メッセージ、制限速度アラートにより、情報を入手しながら、道路に集中できます。 走行中の安全性と集中力を維持しながらも、決して重要な通知を見逃しません。',
            easy_to_use: '使いやすく、機能完備したナビゲーション',
            easy_to_use_seamlessly: 'Blucap Motoモトは、ナビゲーションのために ブルートゥース経由で ブルキャップ アプリに接続し、スマートフォンの GPS ハードウェア機能を最大限に活用します。',
            it_contains_all: '複数ストップの追加、ルートオプション、渋滞回避オプションなど、乗車に必要な機能がすべて含まれています。',
            smart_Routing: 'スマートなルート設定、思いがけない冒険',
            set_your_direction: '方向と距離を設定して、壮大な往復の旅を生成。 特定の道路を避けたり、カーブ率を調整したりとルートをカスタマイズを行い、あなただけのライディング体験を作り出します。',
            basic_features: '',
            voice_commands: '音声コマンド',
            perfectly_compatible: 'お手持ちのブルートゥースヘッドセットと完全な互換性があり、音声コントロール、ハンズフリー通話などが可能です。',
            handlebar_remote_control: 'ハンドルバーによる遠隔操作',
            compatibility: 'すべてのオートバイモデルとの互換性',
            waterproof: '防水加工',
            built_to_withstand: '衝撃や水への耐性を備えています',
            battery_life: '電池寿命',
            powered_by_a_button: 'ボタン電池を使用しており、最大6か月間交換の必要がありません。',
            full_featured_navigation: 'スタイリッシュに乗る',
            full_featured_seamlessly: 'Blucap Motoは、洗練された高品質のレンズオプションを提供し、個性と優れた光学性能を持って乗ることを可能にします',
            lenses: '度付レンズ',
            lenses_seamlessly: '外側のサングラスレンズを外すと、Blucap Motoモトはヘッドアップ ディスプレイ (HUD) 機能を備えた普通のメガネに変わります。',
            contact: 'ご連絡先: support@blucapsport.com',
            copyright: '著作権@ 2023.全ての権利を留保する',
            privacy_policy: 'プライバシーポリシー'
        },
        lenses: {
            replacement: 'BlucapのShiftLock™ 交換レンズ',
            money: '$49.00',
            sunglasses: 'Blucap独自の シフトロック™ 多機能サングラス用交換レンズは、強い光の下でも鮮明な視界を提供し、あらゆる旅に無限の可能性をもたらします。',
            polarization: '分極',
            bayer_level: 'ベイヤーレベル 7 の耐傷性',
            oil_water: '撥水・撥油コーティング',
            anti_fog: '曇り止め',
            protection: '100% UVA/UVB 保護',
            contrast: 'コントラスト強調',
            photochromic_lens: 'Blucap独自の シフトロック™ フォトクロミック レンズは、数秒で別のレンズに切り替えることができます。',
            light: '光透過率超広範囲: 80% - 10%',
            color: '色',
            Navy_Blue: 'ネイビー',
            Dark_Green: 'ダークグリーン',
            Dark_Grey: 'ダークグレー',
            Tea_Brown: 'ティ―ブラウン',
            Mirrored_Yellow: 'ミラー仕上げ イエロー',
            Mirrored_Green: 'ミラー仕上げ グリーン',
            Mirrored_Red: 'ミラー仕上げ レッド',
            Mirrored_Silver: 'ミラー仕上げ シルバー',
            Mirrored_Blue: 'ミラー仕上げ ブルー',
            Black: '黒',
            Photochromic: 'フォトクロミック',
            coming_soon: 'まもなく発売',
            care_instructions: '取扱い上の御注意',
            gentle_cleaning: '丁寧な洗浄: ぬるま湯と中性洗剤により、度付きレンズを丁寧に洗浄してください。 アルコールや強酸・アルカリを含むクリーナーは塗装やフレーム素材を傷める恐れがありますのでご使用にならないでください。',
            special_cloth: '専用クロス：付属の専用クリーニングクロスを使用して、レンズを軽く拭きます。 レンズ表面を傷つける可能性があるため、通常のティッシュや衣類の使用はご使用にならないでください。',
            avoid_scratches: '傷の防止：レンズの傷や汚れを防ぐため、メガネを着用されていないときは、専用のメガネケースに保管してください。',
            avoid_high: '高温を避ける：レンズやフレームの損傷を防ぐため、高温の車や直射日光などの高温環境からはメガネを遠ざけてください。',
            no_saliva: '唾液や衣類の使用禁止: レンズの汚れや損傷につながる可能性があるため、唾液、家庭用洗剤、通常の衣類はご使用にならないでください。',
            regular_inspection: '定期検査: レンズに傷や損傷がないか定期的に検査してください。 異常が見つかった場合は、速やかに眼鏡専門店にご相談ください。',
            avoid_chemical: '化学物質との接触禁止：コーティングやレンズの損傷を防ぐため、メガネが強酸、強アルカリ、化学洗剤、および類似の物質と接触することがないようにしてください。',

        },
        prescription: {
            prescription_lenses: 'Blucap Motoモト用度付きレンズ',
            money: '$209.00',
            order_for: 'お客様の処方せんについてのご注文はご購入日から6～8週間以内に履行および出荷されます。',
            experience: '度付きレンズで Blucap Motoモトを体験したい場合は、度付きレンズを別途注文できます。',
            smart_glasses: '※スマートグラスは付属品ではありません。別途ご購入ください。',
            prescription_information: '処方箋について',
            how_to_read: '処方箋の見方は？',
            right_sphere: '右目 sphere',
            select: '選択する',
            left_sphere: '左目 sphere',
            right_cylinder: '右目 cylinder',
            none: '無し',
            left_cylinder: '左目 cylinder',
            right_axis: '右目 axs',
            left_axis: '左目 axs',
            right_pd: '右目 PD',
            left_pd: '左目 PD',
            pd: 'PD',
            have_2pd: '２PD番号を持っています',
            the_AXIS: '挿入されたAXIS値が間違っています。これらの値は1～180にしてください。',
            remark: '備考',
            type_your: 'こちらにメッセージをタイプしてください',
            i_confirm: '私は処方箋の有効性と正確性を確認し、それが私の身体的要件と一致していることを認めます。 また、処方箋から逸脱する情報や仕様に関して、いかなるクレームや要求も提起しないことを了承します。',
            i_acknowledge: '私は、処方箋に基づく書面による仕様書が注文を完了するために不可欠であることを認めます。 また、私は ブルキャップ が、取引の処理と注文の履行のみを目的として、私の処方箋の詳細を第三者のサプライヤーおよびパートナーと利用および共有することを理解し、同意します。',
            i_understand: '発注後、専門の眼鏡師がお客様にご連絡して処方せん情報をご確認させていただきます。処方せん情報が確認され後は、返金不可となります。',
            i_ifthere: 'Blucapに関係する理由で出荷に遅延があった場合、当社はお客様に至急ご連絡いたします。お客様は待ち続ける、または返金を請求できます。',
            coming_soon: 'まもなく発売',
            understanding: 'あなたの処方箋を理解します',
            right_eye: '右目 (OD)',
            stands: 'ODは「oculus dexter」の略で、ラテン語で「左目」を意味します。',
            left_eye: '左目 (OS)',
            left_stands: 'OSは「oculus sinister」の略で、ラテン語で「左目」を意味します。',
            sphere: '球面度数 (SPH) - パワー (PWR) とも呼ばれます',
            they_mean: '意味しているのは同じこと、つまり処方の強度です。 近視の場合は値の前にマイナス (-) が付き、遠視の場合はプラス (+) が付きます。',
            cyl: '乱視度数 (CYL)',
            people: 'こちらは乱視の方向けで、目の一部が他の部分よりも矯正が必要な場合です。 シリンダー値はマイナス (-) 記号で示されます。',
            axis: '軸',
            this_is_only: 'こちらは乱視のある人のみ限定です。 軸は 0 ～ 180 の数値で、乱視度数 (CYL) の方向を決定します。',
            oK: 'OK',

        },
        news: {
            blucap_unveils: 'Blucap、Blucap Moto バイク用ナビゲーションサングラスの正式リリースを発表',
            the_blucap_team: 'Blucapチーム、2023年9月13日',
            september_13: '[2023年9月13日] - Blucap、先進的なスマートアイウェアブランドは、Blucap Motoナビゲーションサングラスを発表し、バイク愛好家に新しいナビゲーションと乗車体験を提供します。',
            motorcycle: '革命的なバイクナビゲーション',
            blucap_moto_is: ' Blucap Motoは、ライダーの視線の正面に情報を直接表示するナビゲーションデバイスです。必要な時だけ表示される方向矢印を備え、気を取られることなくナビゲーションができる体験を提供します。Blucap Motoは、複雑な道路条件のための地図ナビゲーションで際立っています',
            in_addition: 'さらに、Blucap MotoはBluetoothを介して携帯電話に接続でき、ナビゲーション機能を使用する場合に電話画面をオンにする必要がありません。ライダーは、バッグやポケットに携帯電話を入れて、電話をホルダーに取り付ける手間や、カメラレンズの振動や雨の損傷、電話の過熱、バッテリー消費などの問題を避けることができます。',
            with_blucap_moto: ' Blucap Motoを使えば、ライダーはスマートフォンを見る必要がなく、安全かつ注意散漫なく、没入型の乗車体験を楽しむことができます。',
            navigation_display: 'ナビゲーションディスプレイ',
            blucap_has_developed: 'また、Blucapはオートバイライダーに特化した完全な機能を備えたナビゲーションアプリを開発しました。このアプリには、複数のルートオプション、複数の停留所の追加、交通回避のオプションなどの機能があり、市場の主流のナビゲーションアプリケーションを置き換えることができます。',
            app_functionality: 'アプリケーションの基本機能',
            blucap_has_designed: 'Blucapは、アルゴリズムで生成された推奨ライディングルートを取得することができるスマートルーティング機能を設計しました。このルートは、ライダーの個人的な好みに基づいて一般的な方向や距離、曲がりくねった道、景色の好みなどが含まれています。この機能は、バイクライダーに個人的で楽しい乗車体験を提供します。',
            basic_features: '',
            app_advanced: 'アプリケーションの高度な機能',
            design: '快適な着用体験を提供する優れたデザイン',
            according_to_blucap: ' Blucapのデザイン哲学によると、スマートグラスは通常のメガネのように快適な着用体験を提供する必要があります。Blucap Motoは、軽量で通常のサングラスの外観をしており、他のスマートグラスやARグラスとは異なり、薄いテンプルと末端の柔軟な素材により、ヘルメットの下でも快適に着用できます。',
            the_blucap_design: 'Blucapのデザインチームは、電子機能の相互作用のための「アンチテック」デザインを定義しました。グラスには、ボタン、LEDインジケーター、またはUSBポートがありません。外から見ると、地味でクラシックなサングラスのように見えますが、実際には最先端のAR技術が内部に搭載されています。',
            when_unfolding: 'グラスを展開して着用すると、自動的に電源が入り、Bluetoothを介してモバイル電話に接続されます。これにより、通常のメガネと同じように、余分な手順なしに毎日使用できます。',
            power_on: '電源オン',
            the_charging: '充電LEDと低周波ボタンは、磁気充電ドックに移動され、グラス上の電子部品が少なくなりました。この設計変更により、グラスの重量と消費電力がさらに削減されました。また、Blucapは、異なる光透過率の外部レンズを迅速かつ簡単に交換できるShiftLock™というユニークな設計機能を開発しました。これにより、夜間のバイクライディングに適応したり、処方箋の眼鏡として使用したりすることができます。偏光レンズや光応変レンズも購入できます。',
            release: 'ShiftLock™ クイックリリースデザイン',
            customized: 'カスタマイズされた処方箋レンズ',
            traditional_motorcycle: '一部のバイク用サングラスや他のARグラスは、処方箋レンズ用のRXアダプターを使用します。これは見た目が悪く、グラスに重量を追加します。しかし、Blucapは、外観を変えずに処方箋レンズを取り付けることができるユニークなサプライヤーシステムを設計・確立しました。これにより、Blucap Motoは、魅力的で軽量なライダー向けの解決策を提供できます。',
            prescription_lenses: '度付レンズ',
            cutting_edge: '最先端のAR テクノロジー',
            focused: 'Blucapは2年前から、伝統的なARグラスのかさばる形状、重量、高消費電力、厚いテンプルを避けるため、最先端のMicro LEDと波長分割レンズを光学ソリューションとして重視してきました。これは、グラスの外観や重量に対するBlucapブランドのデザイン要件を満たす唯一の方法でした。Blucap Motoは、世界で最も小さく明るいディスプレイであるJBDの0.13インチMicro LEDを使用しています。それによって構成される光エンジンは、鉛筆の端に消しゴムの大きさしかなく、グラスの足の根元に目立たなく埋め込むことができます。他のARビジュアルソリューションに比べ、視野を侵害しない小さなサイズだけでなく、高輝度と低消費電力の利点があります。',
            microled: 'Blucap Moto とMicroLEDライトエンジン',
            uniquely: 'Blucap Motoは、実際のライディングシーンに対応するために、独自に波長分割ウェーブガイドレンズを設計しました。このタイプのウェーブガイドレンズは、通常のウェーブガイドレンズと比較して、高い透明度、高い明るさ伝達、防霧、反射防止、処方箋のカスタマイズなどの複雑な光学性能要件を満たすことができます。Blucap Motoは、ARサプライヤーと製造パートナーからの強力なサポートを受け、量産前の主要技術確認の準備を完了しました。',
            sales_plan: '販売計画',
            official: 'Blucap Motoの正規小売価格は$399です。現在、ディーラープログラムが進行中であり、Blucapディーラーになりたい場合は、公式ディーラー申請フォームに記入して申請することができます。9月には、Blucap Motoが早期鳥割引価格でキックスタータープロジェクトを開始します。詳細については、後続のキックスターターページをお待ちください。',
            fully: 'Blucap Motoはまだ完全にリリースされていませんが、過去3ヶ月間にFacebookやInstagramなどのソーシャルメディアプラットフォームで15,000人以上のフォロワーを獲得しています。1か月前に開始されたディーラープログラムは、世界中から100件以上の申請を受け取りました。申請者には、北米、欧州、アジアのバイクディーラー、装備店、カスタムショップ、眼鏡店、さまざまなオンラインチャンネル、個人などが含まれています。世界中のライダーからBlucap Motoの革新的な製品に対する強い関心と期待が寄せられており、その成功の可能性を示しています。',
            market_resonance: 'マーケットレゾナンス',
            product_key: '製品の主な特徴と仕様',
            real_time: 'ナビゲーション：リアルタイムHUDマップナビゲーション、ターン方向指示。メガネをかけたままBluetoothで携帯電話に接続し、携帯電話の演算能力とGPSハードウェアを利用します。',
            other_hud: 'その他のHUD機能：スピード、スピード違反警告、着信、メッセージ。',
            glasses_appearance: '普通のメガネの外観、ヘルメットとの完全な互換性、アンチテックのデザイン：フレームにボタン、LED、USBソケットはありません。',
            external_lens: 'ShiftLock™（シフトロック）外付けレンズのクイックリリースデザイン。',
            replacement_lenses: 'オプションの交換レンズ：10色の偏光レンズとフォトクロミックレンズ。すべて100％UVA/UVBプロテクション、曇り止め、傷防止、疎水性・撥油性コーティング、コントラスト強化。',
            inner_lens: 'インナーレンズは度付きレンズのカスタマイズに対応。Blucap Motoを購入する際、ユーザーは注文時に公式サイトで処方箋情報を同時に送信することができます。',
            voice_control: '音声コントロール機能はリモコンで操作でき、他社製Bluetoothヘッドフォンにも対応します。',
            nose_pad: 'ノーズパッドはMサイズとLサイズで交換可能です。',
            comes_with_a: 'Blucap Motoには、簡単に充電できるマグネット式充電アダプターが付属しています。',
            light_engine: '超低消費電力のMicroLED Lightエンジンとコンピューティング・チップが、一日中ライディング・ナビゲーションをサポートします（平均的なナビゲーション使用で10時間以上）。',
            remote_control: 'リモコンはBluetoothでスマホアプリに接続し、複数の取り付けアクセサリにより、様々なハンドルバーに対応します。',
            about_blucap: 'Blucapについて',
            about_us: '会社概要→',
            this_article: 'この記事の本文',
            copy_text: 'コピーテキスト',
            download_all: 'すべての写真要素をダウンロード',
            zip569: 'ZIP | 56.9MB',
            advertisement: 'Advertisement_Video_EN.mp4',
            mp4106: 'MP4 | 106MB',
            vertical_advertisement: '30s_Vertical_Advertisement_Video_EN.mp4',
            mp4378: 'MP4 | 37.8MB',
            cg: 'CG_QuickShift_Video.mp4',
            mp447: 'MP4 | 4.7MB',
            cg_auto: 'CG_AutoConnection_Video.mp4',
            mp445: 'MP4 | 4.5MB',
            for_more: '詳細はお問い合わせください：',
            zhaot: 'Zhao Tingting',
            brand_marketing: 'ブランドマーケティング',
            ztt: 'ztt@blucapsport.com',
            b_m: 'Blucap media',
            mb: 'media@blucapsport.com',
            b_m_img: 'Blucap Moto 画像 1',
            b_m_pic: 'Blucap Moto モデル画像 1',
            b_m_color: 'Blucap Moto カラーコンタクトレンズの画像 1',
            b_m_unique: 'Blucap Motoユニークなレンズ',
            b_m_factor: 'Blucap Moto 工場の写真',
            press_contacts: '報道関係者連絡先',
        },
        about: {
            us: '私たちについて',
            starting: '欲望から始まる',
            initially: '朱寧は最初にバイクを乗りながら携帯電話をナビゲーションに使用する問題に遭遇し、理想的なバイク用HUD製品を見つけることができませんでした。その結果、彼はDIYの努力を通じて独自のHUDデバイスを作り始めました。',
            journey: '旅が始まった',
            zhu_ning: '2021年、朱寧はBlucapを設立し、熱心なライダー、エンジニア、デザイナー、眼鏡の専門家、地図システムの専門家からなるチームを集めました。 私たちの技術革新と洗練されたデザインへの献身心は、私たちを常にバイクライディングの世界で可能な限りの境界を押し広げるよう駆り立てています。',
            built: '辛抱強く作られました',
            throughout: '私たちの初めての製品であるBlucap Motoを三年間にわたる探索の中で、私たち：',
            bravely: '大規模な生産供給チェーンの成熟と共に、先進のAR技術を勇敢に選択しました。',
            spent: 'HUDライディングインタラクション、眼鏡のサイズ、および電力消費の要件を調和させる技術ソリューションを探索し、半年以上かけて検証しました。',
            redesigned: 'レンズの素早い取り外しとより良い視覚体験を提供するために、製品の発売を半年以上延期することになるとしても、メガネ全体を信頼性の高いデザインに再設計しました。',
            continuously: '世界中のチームライダーとテストユーザーからのフィードバックを受けて、製品を継続的にテストし改善しました。メガネのプロトタイプを100回以上調整し、ソフトウェアシステムを継続的に最適化しました。',
            reliability_0: '信頼性、専門性',
            reliability_1: ', そして ',
            reliability_2: '誠実さ ',
            reliability_3: 'はBlucapの中核価値です。私たちは製品のすべての詳細に注意を払い、各側面を辛抱強く磨き上げ、究極の最高を実現します。Blucapへようこそ。革新がオープンロードで出会う場所です。"',
            hq: 'Hangzhou HQ',
            dream: 'No.22 Dream Town Hangzhou, ZJ 311100, P.R.C',
            support: 'support@blucapsport.com',
            hk: 'Hong Kong',
            ug: 'Unit 2A, 17/F, Glenealy Tower, No.1 Glenealy Central',
            zb: 'zn@blucapsport.com',
            portland: 'Portland',
            upcoming: 'Upcoming'

        }
    }
}