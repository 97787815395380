<template>
  <div class="all">
    <div class="top">
      <img src="../../../assets/20240109/shoujibeijing.png" class="img-w-1" />
    </div>
    <div class="content">
      <div class="title">Full-featured Navigation App</div>
      <div class="txt-1">
        The Blucap App integrates map SDKs from
        <span class="border">Google Maps</span> and
        <span class="border">MapBox</span>. It includes all the essential
        features for riding and maintaining a classic and simple user interface.
      </div>
    </div>
    <div class="ones">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide bg01"
            v-lazy:background-image="
              require('../../../assets/20240109/phone/01.png')
            "
          ></div>
          <div
            class="swiper-slide bg02"
            v-lazy:background-image="
              require('../../../assets/20240109/phone/02.png')
            "
          ></div>
          <div
            class="swiper-slide bg03"
            v-lazy:background-image="
              require('../../../assets/20240109/phone/03.png')
            "
          ></div>
          <div
            class="swiper-slide bg04"
            v-lazy:background-image="
              require('../../../assets/20240109/phone/04.png')
            "
          ></div>
          <div
            class="swiper-slide bg05"
            v-lazy:background-image="
              require('../../../assets/20240109/phone/05.png')
            "
          ></div>
          <div
            class="swiper-slide bg06"
            v-lazy:background-image="
              require('../../../assets/20240109/phone/06.png')
            "
          ></div>
          <div
            class="swiper-slide bg07"
            v-lazy:background-image="
              require('../../../assets/20240109/phone/07.png')
            "
          ></div>
          <div
            class="swiper-slide bg08"
            v-lazy:background-image="
              require('../../../assets/20240109/phone/08.png')
            "
          ></div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
export default {
  activated() {
    this.$nextTick(() => {
      this.swiper()
    })
  },

  methods: {
    // 轮播图
    swiper() {
      new Swiper('.ones .swiper-container', {
        slidesPerView: 1.16,
        spaceBetween: 10,
        centeredSlides: true,
        pagination: {
          el: '.ones .swiper-pagination',
          clickable: true,
        },
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
      })
    },
  },
}
</script>

<style lang="less" scoped>
.all {
  width: 100%;
  .top {
    width: 100%;
    .img-w-1 {
      width: 100%;
    }
  }
  .content {
    width: 3.3rem;
    margin: 0 auto;
    margin-top: -1rem;
    text-align: left;
    .title {
      font-size: 0.42rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: #fff;
    }
    .txt-1 {
      color: rgba(255, 255, 255, 0.7);
      /* Mobile-text1 */
      font-family: League Spartan;
      font-size: 0.21rem;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 25.2px */
      margin-top: 0.24rem;
      margin-bottom: 0.25rem;
    }
  }
  .ones {
    width: 100%;
    .swiper-container {
      width: 100%;
      height: 6rem;
      padding-bottom: 0.3rem;
    }

    .swiper-slide {
      width: 100%;
      //padding-bottom: 0.3rem;
      //height: 6rem;
    }

    .bg01 {
      //background-image: url(../../assets/20230905/111.png);
      background-size: contain;
      padding-left: 0.3rem;
      padding-right: 0.3rem;
    }

    .bg02 {
      //background-image: url(../../assets/20230905/222.png);
      background-size: contain;
      padding-left: 0.3rem;
      padding-right: 0.3rem;
    }

    .bg03 {
      //background-image: url(../../assets/20230905/333.png);
      background-size: contain;
      padding-left: 0.3rem;
      padding-right: 0.3rem;
    }
    .bg04 {
      //background-image: url(../../assets/20230905/111.png);
      background-size: contain;
      padding-left: 0.3rem;
      padding-right: 0.3rem;
    }

    .bg05 {
      //background-image: url(../../assets/20230905/222.png);
      background-size: contain;
      padding-left: 0.3rem;
      padding-right: 0.3rem;
    }

    .bg06 {
      //background-image: url(../../assets/20230905/333.png);
      background-size: contain;
      padding-left: 0.3rem;
      padding-right: 0.3rem;
    }
    .bg07 {
      //background-image: url(../../assets/20230905/222.png);
      background-size: contain;
      padding-left: 0.3rem;
      padding-right: 0.3rem;
    }

    .bg08 {
      //background-image: url(../../assets/20230905/333.png);
      background-size: contain;
      padding-left: 0.3rem;
      padding-right: 0.3rem;
    }

    .title {
      margin-top: 3.42rem;
      font-family: League Spartan;
      font-size: 0.27rem;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      color: #fff;
    }

    .txt-1 {
      font-family: League Spartan;
      font-size: 0.21rem;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      color: rgba(255, 255, 255, 0.7);
      margin-top: 0.1rem;
    }

    .img {
      width: 3.3rem;
      // height: 100%;
      display: block;
      object-fit: cover;
    }

    .anm01 {
      transform: translate3d(30px, 0px, 0px) !important;
      transition-duration: 0ms;
    }
  }
}
.border {
  font-weight: 500;
  color: #fff;
}
</style>
