<template>
  <div class="all" @touchend="playVideo('videoWeather')">
    <div class="zz"></div>
    <div class="four">
      <video
        playsInline
        loop
        muted
        preload="metadata"
        class="video_1"
        id="videoWeather"
      >
        <source
          src="https://blk001.oss-us-west-1.aliyuncs.com/Whether.mp4"
          type="video/mp4"
        />
      </video>
    </div>
    <div class="content">
      <div class="title">{{ $t('message.index.wether_title') }}</div>
      <div class="txt-1">
        <!-- {{ $t('message.index.weather_content') }} -->
        Easily control the map with a handlebar remote. Use the dedicated
        <span class="border">Map Button</span> to show or hide the navigation
        map. Compatible with various motorcycle handlebars.
      </div>
      <div class="flex row-start w-2">
        <img src="../../../assets/20240109/CloudRain.svg" class="img" />
        <div class="txt-2">
          {{ $t('message.index.weather_ip') }}
        </div>
      </div>
      <div class="flex row-start w-2" style="margin-top: 0.1rem">
        <img src="../../../assets/20240109/Lightning.svg" class="img m-l" />
        <div class="txt-2">
          {{ $t('message.index.weather_batter') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    playVideo(ids) {
      var myvideo = document.getElementById(ids)
      myvideo.play()
    },
  },
}
</script>

<style lang="less" scoped>
.all {
  width: 100%;
  padding-bottom: 0.6rem;
  position: relative;
  height: 6.46rem;
  .zz {
    position: absolute;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50.68%, #000 100%);
    width: 100%;
    height: 3rem;
  }
  .four {
    width: 100%;
    .video_1 {
      width: 100%;
      display: block;
      height: 3rem;
      z-index: -1;
      // opacity: 0.6;
      object-fit: cover;
    }
  }
  .content {
    width: 3.3rem;
    // margin: 0 auto;
    // margin-top: -0.5rem;
    position: absolute;
    left: 0.3rem;
    text-align: left;
    top: 2.2rem;
    z-index: 10;
    .title {
      font-size: 0.42rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: #fff;
    }
    .txt-1 {
      color: rgba(255, 255, 255, 0.7);
      /* Mobile-text1 */
      font-family: League Spartan;
      font-size: 0.21rem;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 25.2px */
      margin-top: 0.24rem;
      margin-bottom: 0.25rem;
      .border {
        font-weight: 500;
        color: #fff;
      }
    }
    .w-2 {
      width: 100%;

      .img {
        width: 0.32rem;
      }
      .txt-2 {
        color: rgba(255, 255, 255, 0.7);
        /* Mobile-text1 */
        font-family: League Spartan;
        font-size: 0.21rem;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 25.2px */
        margin-left: 0.14rem;
      }
    }
  }
}
</style>
