<template>
  <div class="all" @mouseover="playVideo('video3')">
    <div class="w-1-1">
      <video
        playsInline
        loop
        muted
        class="video_3"
        preload="metadata"
        id="video3"
      >
        <source
          src="https://blk001.oss-us-west-1.aliyuncs.com/OUT3.mp4"
          type="video/mp4"
        />
      </video>
    </div>
    <div class="w-1-2">
      <div class="content">
        <div class="title">
          {{ $t('message.index.power') }}
        </div>
        <div class="txt-1 color-07">
          <!-- {{ $t('message.index.power_seamlessly') }} -->
          To power on the sunglasses, simply open the temples. They will
          <span class="border">automatically</span> connect to your phone. To
          power off, just close the glasses. There's no need to remember button
          functions or complex touch gestures.<span class="border">
            All you have to do is wear the glasses</span
          >.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    playVideo(ids) {
      var myvideo = document.getElementById(ids)
      myvideo.play()
    },
  },
}
</script>

<style lang="less" scoped>
.all {
  width: 100%;
  background-color: #000;
  position: relative;
  .w-1-1 {
    display: grid;
    .video_3 {
      width: 100%;
      height: 820px;
      max-width: 2560px;
      object-fit: cover;
      object-position: center;
      display: block;
      margin: 0 auto;
    }
  }
  .w-1-2 {
    width: 1920px;
    margin: 0 auto;
    position: relative;
    .content {
      width: 820px;
      color: #fff;
      padding-left: 270px;
      position: absolute;
      bottom: 90px;

      .title {
        font-family: League Spartan;
        font-size: 42px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: left;
      }

      .txt-1 {
        font-family: League Spartan;
        font-size: 21px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        text-align: left;
        margin-top: 10px;
        color: rgba(255, 255, 255, 0.7);
        .border {
          font-weight: 500;
          color: #fff;
        }
      }
    }
  }
}
</style>
