import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../views/Index.vue";

Vue.use(VueRouter);

const routes = [
    // 首页
    {
        path: "/",
        name: "Index",
        component: Index,
        meta: {
            keepAlive: true
        }
    },
    // 首页
    // {
    //     path: "/zh",
    //     name: "Index",
    //     component: Index,
    //     meta: {
    //         keepAlive: true
    //     }
    // },
    // 关于
    {
        path: "/about",
        name: "About",
        component: () => import("../views/About.vue"),
        meta: {
            keepAlive: true
        }
    },
    // // 关于
    // {
    //     path: "/zh/about",
    //     name: "About",
    //     component: () => import("../views/About.vue"),
    //     meta: {
    //         keepAlive: true
    //     }
    // },
    // prescription
    {
        path: "/prescription",
        name: "Prescription",
        component: () => import("../views/Prescription.vue"),
        meta: {
            keepAlive: true
        }
    },
    // Replacement Lenses
    {
        path: "/lenses",
        name: "Lenses",
        component: () => import("../views/Lenses.vue"),
        meta: {
            keepAlive: true
        }
    },
    // News
    {
        path: "/news",
        name: "News",
        component: () => import("../views/News.vue"),
        meta: {
            keepAlive: true
        }
    },
    // dealer
    {
        path: "/dealer",
        name: "Dealer",
        component: () => import("../views/Dealer.vue"),
        meta: {
            keepAlive: true
        }
    },
    // privacy
    {
        path: "/privacy",
        name: "Privacy",
        component: () => import("../views/Privacy.vue"),
        meta: {
            keepAlive: true
        }
    },
    {
        //path:"/:pathMatch(.*)*",
        path: '/:catchAll(.*)',
        name: "Index",
        component: Index,
        meta: {
            keepAlive: true
        }
    }
];

const router = new VueRouter({
    mode: "history",//在dist文件加下要注释掉，只有正式环境打开
    // base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {
                x: 0,
                y: 0
            }
        }
    }
});

router.beforeEach((to, from, next) => {
    console.log('to',to,'from',from);
    //  从其他地方访问是否有这个地址
      if(to.matched.length == 0){
        from.path ? next({name: from.name}) : next('*')
      }
      next();
  });

export default router;
