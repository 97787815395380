<template>
  <div class="all" @mouseover="playVideo('video2')">
    <div class="content">
      <div class="title">{{ $t('message.index.turn_directions') }}</div>
      <div class="txt-1">
        The turn signal indicator
        <span class="border">automatically</span> appears and disappears.<br />
        Minimalist design of HUD system,
        <span class="border">no distraction</span> at all.
        <!-- {{ $t('message.index.turn_directions_seamlessly') }}<br>
                {{ $t('message.index.turn_directions_seamlessly2') }} -->
      </div>
    </div>
    <div class="w-1">
      <video
        playsInline
        loop
        muted
        preload="metadata"
        class="video_2"
        id="video2"
      >
        <source
          src="https://blk001.oss-us-west-1.aliyuncs.com/Turn_Directions.mp4"
          type="video/mp4"
        />
        <!-- <source src="../../assets//video/index02.mp4" type="video/mp4" /> -->
      </video>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    playVideo(ids) {
      console.log('vid', ids)
      var myvideo = document.getElementById(ids)
      myvideo.play()
    },
  },
}
</script>
<style scoped lang="less">
.all {
  width: 100%;
  background-color: #000;
  padding-top: 90px;

  .content {
    width: 100%;
    text-align: center;
    font-feature-settings: 'ss02' on;
    font-family: League Spartan;
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #fff;
    .txt-1 {
      // width: 820px;
      color: rgba(255, 255, 255, 0.7);
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-family: League Spartan;
      font-size: 21px;
      font-style: normal;
      font-weight: 400;
      margin: 0 auto;
      line-height: 120%;
      margin-top: 24px;
    }
    .border {
      font-weight: 500;
      color: #fff;
    }
  }

  .w-1 {
    margin-top: 48px;
    height: 800px;
    .video_2 {
      width: 100%;
      max-width: 2560px;
      display: block;
      height: 800px;
      object-fit: cover;
      object-position: center;
      margin: 0 auto;
    }
  }
}
</style>
