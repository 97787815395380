<template>
  <div class="all">
    <div class="w-1">
      <div class="title">{{ $t('message.index.experience') }}</div>
      <div class="flex row-between">
        <div class="w-2 bg_1" v-lazy:background-image="bg1">
          <div class="title2">
            {{ $t('message.index.most_cutting') }}
          </div>
          <div class="txt-1">
            <!-- {{ $t('message.index.only_the') }} -->
            Only the utilization of cutting-edge technology can meet Blucap's
            high demands for both <span class="col-lan">design</span> and
            <span class="col-lan">performance</span>.
          </div>
        </div>
        <div class="w-2 bg_2" v-lazy:background-image="bg2">
          <div class="title2">
            {{ $t('message.index.innovation') }}
          </div>
          <div class="txt-1">
            {{ $t('message.index.customized') }}
          </div>
        </div>
        <div class="w-2 bg_3" v-lazy:background-image="bg3">
          <div class="title2">
            {{ $t('message.index.smallest') }}
          </div>
          <div class="txt-1">
            <!-- {{ $t('message.index.temples') }} -->
            The world's
            <span class="col-lan">smallest 0.13" MicroLED Display</span>, with
            high brightness, and ultra-low power consumption that make the
            temples the same as ordinary glasses.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bg1: require('../../../assets/20230905/111.png'),
      bg2: require('../../../assets/20230905/222.png'),
      bg3: require('../../../assets/20230905/333.png'),
    }
  },
}
</script>

<style lang="less" scoped>
.all {
  width: 100%;
  background: #1b1b1b;
  padding-top: 90px;
  padding-bottom: 90px;

  .w-1 {
    width: 1380px;
    margin: 0 auto;

    .title {
      color: #fff;
      font-feature-settings: 'ss02' on;
      font-family: League Spartan;
      font-size: 42px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      /* 50.4px */
      margin-bottom: 42px;
    }

    .w-2 {
      width: 417px;
      background-color: #000;
      margin-right: 32px;
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 30px;

      .title2 {
        margin-top: 338px;
        font-family: League Spartan;
        font-size: 27px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        color: #fff;
      }

      .txt-1 {
        font-family: League Spartan;
        font-size: 21px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        color: rgba(255, 255, 255, 0.7);
        margin-top: 10px;
        .col-lan {
          color: #fff;
          font-weight: 500;
        }
      }
    }

    .bg_1 {
      background-size: cover;
    }

    .bg_2 {
      background-size: cover;
    }

    .bg_3 {
      background-size: cover;
    }
  }
}
</style>
