<template>
  <div class="all" @mouseover="playVideo('video001')">
    <div>
      <video
        playsInline
        muted
        preload="metadata"
        class="video_2"
        id="video001"
        @pause="onPlayerPause($event)"
      >
        <source
          src="https://blk001.oss-us-west-1.aliyuncs.com/001.mp4"
          type="video/mp4"
        />
      </video>
    </div>
    <div class="w-1-2" v-if="is_show == 1">
      <div class="content">
        <div class="title">Immerse yourself in your ride</div>
        <div class="txt-1 color-07">
          Bye-bye to phone holders. <br />Start navigation, <br />turn off the
          screen, <br />and pocket your phone.<br />
          Escape the distractions of digital screens while navigating.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      is_show: 0,
    }
  },
  methods: {
    playVideo(ids) {
      if (this.is_show == 0) {
        var myvideo = document.getElementById(ids)
        myvideo.play()
      }
    },
    onPlayerPause(player) {
      console.log('player', player)
      this.is_show = 1
    },
  },
}
</script>

<style lang="less" scoped>
.all {
  width: 100%;
  background-color: #000;
  padding-top: 90px;
  position: relative;
  .video_2 {
    width: 100%;
    max-width: 2560px;
    height: 800px;
    object-fit: cover;
    object-position: center;
    display: block;
    margin: 0 auto;
  }
  .w-1-2 {
    text-align: center;
    position: absolute;
    top: 50%;
    // left: 50%;
    transform: translate(-0%, -50%);
    width: 100%;
    .content {
      // width: 820px;
      color: #fff;
      //   padding-left: 270px;

      //   bottom: 90px;

      .title {
        font-family: League Spartan;
        font-size: 42px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .txt-1 {
        font-family: League Spartan;
        font-size: 21px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        margin-top: 10px;
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
}
</style>
