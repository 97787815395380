<template>
  <div class="all">
    <div class="comm">
      <div class="flex row-between row-center b-w-1">
        <div class="m-t-03">
          <img src="../../assets/20230905/logo.png" class="logoimg" />
          <div class="b-txt-1 b-m-t">
            {{ $t('message.index.copyright') }}
          </div>
          <div class="b-txt-1 cus" @click="privacy">
            {{ $t('message.index.privacy_policy') }}

            <!-- {{$t('message.author')}} -->
          </div>
        </div>
        <div class="r-w-1">
          <div class="flex row-between row-center">
            <!-- <a class="b-w-1 bg01" href="https://www.instagram.com/blucap_global/" target="_blank"><img
                                src="../../assets/20230905/InstagramLogo.svg" class="simg"></a>
                        <a href="https://www.facebook.com/blucapsport/" target="_blank"><img
                                src="../../assets/20230905/FacebookLogo.svg" class="simg"></a>
                        <a href="https://www.youtube.com/@Blucap" target="_blank"><img
                                src="../../assets/20230905/YoutubeLogo.svg" class="simg"></a>
                        <a href="https://twitter.com/Blucap_global" target="_blank"><img
                                src="../../assets/20230905/TwitterLogo.svg" class="simg"></a> -->
            <div>
              <a
                class="b-w-2 bg01"
                href="https://www.instagram.com/blucap_global/"
                target="_blank"
              ></a>
            </div>
            <div>
              <a
                class="b-w-2 bg02"
                href="https://www.facebook.com/blucapsport/"
                target="_blank"
              ></a>
            </div>
            <div>
              <a
                class="b-w-2 bg03"
                href="https://www.youtube.com/@Blucap"
                target="_blank"
              ></a>
            </div>
            <div>
              <a
                class="b-w-2 bg04"
                href="https://twitter.com/Blucap_global"
                target="_blank"
              ></a>
            </div>
          </div>
          <div class="xz">
            <select
              class="slect"
              @change="changes"
              v-model="lang"
              style="display: none"
            >
              <option value="en">Engish</option>
              <!-- <option value="it">Italiano</option>
                            <option value="de">Deutsch</option>
                            <option value="ja">日本語</option>
                            <option value="fr">français</option>
                            <option value="es">español</option>
                            <option value="tw">中文（繁体）</option> -->
              <!-- <option value="zh">中文（简体）</option> -->
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lang: this.$i18n.locale.length == 0 ? 'en' : this.$i18n.locale,
    }
  },
  methods: {
    privacy() {
      //this.$router.push({ path: "/privacy" });
      window.location.href =
        'https://store.blucapsport.com/policies/privacy-policy'
    },
    changes(event) {
      // var url = window.location.href;
      // var temps=url.split('localhost:8081/');
      // console.log('url',temps[0],temps[1]);

      var vals = event.target.value
      this.lang = vals
      this.$i18n.locale = vals
      localStorage.setItem('language', vals)
      console.log('lange', vals)
    },
  },
}
</script>

<style lang="less" scoped>
.cus {
  cursor: pointer;
}

@media screen and(min-width: 1920px) {
  a {
    display: block;
  }

  .all {
    width: 100%;
    background-color: #000;
    // height: 273px;
    //padding-bottom: 90px;

    .comm {
      width: 1920px;
      margin: 0 auto;
      padding-top: 90px;
      padding-bottom: 90px;
      .b-w-1 {
        width: 1380px;
        margin: 0 auto;

        .logoimg {
          width: 92px;
          display: block;
          //margin-top: 66px;
        }

        .b-txt-1 {
          font-family: League Spartan;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          color: #fff;
          margin-top: 14px;
        }

        .b-m-t {
          //margin-top: -40px;
        }

        .r-w-1 {
          //margin-top: 66px;
          width: 216px;
        }

        .simg {
          cursor: pointer;
        }

        .slect {
          font-family: League Spartan;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          color: #fff;
          background-color: #000;
          border: 1.5px solid #fff;
          padding: 10px 35px;
          display: block;
          padding-left: 16px;
        }

        .xz {
          margin-top: 26px;
          float: right;
        }
      }
    }
  }

  .b-w-2 {
    width: 27px;
    height: 27px;
    display: block;
  }

  .bg01 {
    background-image: url(../../assets/20230905/InstagramLogo.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }

  .bg02 {
    background-image: url(../../assets/20230905/FacebookLogo.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }

  .bg03 {
    background-image: url(../../assets/20230905/YoutubeLogo.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }

  .bg04 {
    background-image: url(../../assets/20230905/TwitterLogo.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }
}
</style>

<style lang="less" scoped>
@media screen and(max-width: 1919px) {
  // a {
  //     margin-top: -37px;
  // }

  .all {
    width: 100%;
    background-color: #000;
    //padding-bottom: 30px;

    .comm {
      width: 1440px;
      margin: 0 auto;
      padding-top: 60px;
      padding-bottom: 60px;
      .b-w-1 {
        width: 1380px;
        margin: 0 auto;

        .logoimg {
          width: 92px;
          //margin-top: 40px;
          display: block;
        }

        .b-txt-1 {
          font-family: League Spartan;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          color: #fff;
          margin-top: 14px;
        }

        .b-m-t {
        }

        .r-w-1 {
          //margin-top: 40px;
          width: 216px;
        }

        .simg {
        }

        .slect {
          font-family: League Spartan;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          color: #fff;
          background-color: #000;
          border: 1.5px solid #fff;
          padding: 10px 35px;
          display: block;
          padding-left: 16px;
        }

        .xz {
          margin-top: 26px;
          float: right;
        }
      }
    }
  }

  .m-t-03 {
    // margin-top: -30px;
  }

  .b-w-2 {
    width: 27px;
    height: 27px;
    display: block;
  }

  .bg01 {
    background-image: url(../../assets/20230905/InstagramLogo.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }

  .bg02 {
    background-image: url(../../assets/20230905/FacebookLogo.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }

  .bg03 {
    background-image: url(../../assets/20230905/YoutubeLogo.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }

  .bg04 {
    background-image: url(../../assets/20230905/TwitterLogo.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }
}

select {
  /*去除外边框*/
  border: none;
  /*适应safari浏览器内容不跑偏*/
  line-height: 1;
  width: 100%;
  /*将默认的select选择框样式清除*/
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  /*在选择框的最右侧中间显示小箭头图片*/
  //background:url("../../assets/20230905/CaretDown.svg") no-repeat scroll right center transparent;
  background: url(../../assets/20230905/CaretDown.svg) no-repeat scroll right
    center transparent;

  background-size: 36px 20px;
  /*为下拉小箭头留出一点位置，避免被文字覆盖*/
  padding-left: 16px;
  color: #666666;
  /*去除选中时样式*/
  outline: none;

  /*去除点击一闪而过的灰色背景*/

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
</style>
