<template>
  <div class="all">
    <div class="dhsm"></div>
    <div class="w-1-1">
      <video playsInline autoPlay muted class="video_1_1">
        <source
          src="../../../assets/video2/v1_0.mp4?source=1"
          type="video/mp4"
        />
      </video>
      <div class="w-1-2">
        <div class="w-2">
          <div class="w-2-1">
            <img src="../../../assets/20230905/mo01.svg" class="img01" />

            <div class="txt-1" style="margin-top: 16px">
              {{ $t('message.index.meet') }}
            </div>
            <div class="m-t-36">
              <button02 />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="dhsm">MSRP</div> -->
    <div class="pad01">
      <email01 />
    </div>

    <div class="w-5">
      <div class="w-5-1">
        <video
          playsInline
          autoPlay
          loop
          muted
          class="video_1"
          style="display: block"
        >
          <source
            src="https://blk001.oss-us-west-1.aliyuncs.com/banner_1.mp4"
            type="video/mp4"
          />
        </video>
      </div>
      <div class="btn-w">
        <div class="btn-w-1 flex row-start" @click="showPlay">
          <img src="../../../assets/20230905/Play.svg" class="play_img" />
          <div style="margin-left: 10px">
            {{ $t('message.index.watch_video') }}
          </div>
        </div>
      </div>
    </div>
    <div class="tcc" v-show="showYT">
      <iframe
        v-show="this.$i18n.locale != 'zh'"
        id="player"
        class="tcc-w-3-video"
        :src="vsrc"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>

      <iframe
        class="tcc-w-3-video"
        v-show="this.$i18n.locale == 'zh'"
        :src="vsrc2"
        scrolling="no"
        frameborder="no"
        framespacing="0"
        allowfullscreen="true"
      >
      </iframe>

      <!-- <iframe id="player" type="text/html" class="tcc-w-3-video"
                src="https://www.youtube.com/embed/dv5O68wfEWg?si=sB8uxCcwxTtVq6ns" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe> -->
      <div class="abso" @click="showPlay">
        <img src="../../../assets/20230905/X.svg" class="closeImage" />
      </div>
    </div>
  </div>
</template>

<script>
import button02 from '@/components/com/button02.vue' //头部
import email01 from '@/components/com/email.vue' //头部
export default {
  components: {
    button02,
    email01,
  },
  data() {
    return {
      vsrc: '',
      vsrc2: '',
      showYT: false,
    }
  },
  methods: {
    showPlay() {
      if (this.showYT) {
        this.vsrc = ''
        this.vsrc2 = ''
      } else {
        this.vsrc =
          'https://www.youtube.com/embed/hZXzbKbc53c?si=BLKDzATD9mFpSCqY'
        this.vsrc2 =
          'https://player.bilibili.com/player.html?aid=788942498&bvid=BV1FC4y1f7eW&cid=1280556712&p=1'
      }
      this.showYT = !this.showYT
    },
  },
}
</script>

<style lang="less" scoped>
.all {
  width: 100%;
  .dhsm {
    width: 100%;
    text-align: center;
    background: #236192;
    color: #fff;
    font-size: 16px;
    margin-top: 62px;
    font-weight: 400;
    height: 36px;
    //font-family: League Spartan;
    line-height: 36px;
    font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
      Microsoft YaHei, Arial, sans-serif;
    display: none;
  }
  .w-1-1 {
    width: 100%;
    position: relative;
    height: 900px;
    .video_1_1 {
      width: 100%;
      max-width: 2560px;
      height: 900px;
      display: block;
      object-fit: cover;
      margin: 0 auto;
      object-position: center;
    }
    .w-1-2 {
      width: 1920px;
      margin: 0 auto;
      position: relative;
      .w-2 {
        width: 820px;
        margin-left: 270px;
        .img01 {
          width: 235px;
        }
        .w-2-1 {
          position: absolute;
          bottom: 90px;
          width: 820px;
          .txt-1 {
            font-size: 21px;
            font-weight: 400;
            line-height: 25.2px;
            color: #fff;
            margin-top: 16px;
            font-family: League Spartan;
          }
        }
      }
      .m-t-36 {
        margin-top: 36px;
      }
    }
  }
  .pad01 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .w-5 {
    width: 100%;
    padding-top: 90px;
    padding-bottom: 90px;
    position: relative;
    .w-5-1 {
      width: 1440px;
      margin: 0 auto;
      .video_1 {
        width: 100%;
        height: 360px;
        object-fit: cover;
        object-position: center;
      }
    }
    .btn-w {
      position: absolute;
      //width: 100%;
      z-index: 10;
      cursor: pointer;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .btn-w-1 {
        border: 1px solid #fff;
        //width: 174px;
        height: 50px;
        font-size: 21px;
        font-weight: 400;
        color: #fff;
        line-height: 50px;
        padding-left: 14px;
        padding-right: 14px;
        background: rgba(0, 0, 0, 0.01);
        backdrop-filter: blur(15px);
      }
    }
  }

  .tcc {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    z-index: 200;
    top: 0;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    text-align: center;

    .tcc-w-3-video {
      width: 100%;
      height: 70%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
    }

    .abso {
      position: absolute;
      right: 31px;
      top: -10px;
    }
  }
}
</style>
