<template>
  <div class="all" @mouseover="playVideo('video_whether')">
    <div class="flex row-between w-1">
      <div>
        <video
          playsInline
          loop
          muted
          preload="metadata"
          class="video_2"
          id="video_whether"
        >
          <source
            src="https://blk001.oss-us-west-1.aliyuncs.com/Whether.mp4"
            type="video/mp4"
          />
          <!-- <source src="../../assets//video/index02.mp4" type="video/mp4" /> -->
        </video>
      </div>
      <div class="content">
        <div class="title">{{ $t('message.index.wether_title') }}</div>
        <div class="txt-1">
          <!-- {{ $t('message.index.weather_content') }} -->
          Easily control the map with a handlebar remote. Use the dedicated
          <span class="border">Map Button</span> to show or hide the navigation
          map. Compatible with various motorcycle handlebars.
        </div>
        <div class="flex row-start w-2">
          <img src="../../../assets/20240109/CloudRain.svg" class="img" />
          <div class="txt-2">
            {{ $t('message.index.weather_ip') }}
          </div>
          <img src="../../../assets/20240109/Lightning.svg" class="img m-l" />
          <div class="txt-2">
            {{ $t('message.index.weather_batter') }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="wifi-youtube-add"
      id="wifi-youtube-add"
      style="display: block"
    ></div>
    <!-- <div class="flex row-center w-1">
      <iframe
        src="https://blucapsport.com/Wi-Fi-ADD/youtubevideo.html"
        style="width: 100%; height: 700px; border: none"
      ></iframe>
    </div> -->
  </div>
</template>

<script>
export default {
  methods: {
    playVideo(ids) {
      var myvideo = document.getElementById(ids)
      myvideo.play()
    },
  },
}
</script>

<style lang="less" scoped>
.all {
  width: 100%;
  background-color: #000;
  padding-top: 90px;
  // padding-bottom: 90px;

  .w-1 {
    width: 1380px;
    margin: 0 auto;

    .video_2 {
      width: 645px;
      object-fit: cover;
      object-position: center;
    }

    .content {
      width: 645px;
      text-align: left;
      font-feature-settings: 'ss02' on;
      font-family: League Spartan;
      font-size: 42px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: #fff;
      padding-top: 135px;

      .txt-1 {
        color: rgba(255, 255, 255, 0.7);
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: League Spartan;
        font-size: 21px;
        font-style: normal;
        font-weight: 400;
        margin: 0 auto;
        line-height: 120%;
        margin-top: 24px;
        .border {
          font-weight: 500;
          color: #fff;
        }
      }

      .w-2 {
        margin-top: 29.5px;

        .txt-2 {
          color: rgba(255, 255, 255, 0.7);
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          font-family: League Spartan;
          font-size: 21px;
          font-style: normal;
          font-weight: 400;
          line-height: 32px;
          margin-left: 14px;
        }
        .img {
          width: 32px;
        }
        .m-l {
          margin-left: 48px;
        }
      }
    }
  }
}
</style>
