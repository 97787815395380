<template>
  <div class="all001">
    <div class="flex row-start w-1" @click="tz">
      <img src="../../assets/20230905/ShoppingBag.svg" class="bimg" />
      <div class="txt-01">{{ $t('message.buttons.button1') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tab: Number,
  },
  methods: {
    tz() {
      if (this.tab == 0) {
        this.$store.state.less_state = 0
        this.$router.push({ path: '/lenses' })
      } else if (this.tab == 1) {
        this.$router.push({ path: '/prescription' })
      } else if (this.tab == 2) {
        this.$store.state.less_state = 1
        this.$router.push({ path: '/lenses?id=1' })
      }
    },
  },
}
</script>

<style lang="less" scoped>
.w-1 {
  // width: 181px;
  padding: 12px 35px;
  background-color: #fff;
  border: 1.5px solid #fff;
  backdrop-filter: blur(15px);
  cursor: pointer;
  width: fit-content;
}

.bimg {
  width: 19px;
  height: 19px;
}

.txt-01 {
  font-family: League Spartan;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: #000;
  margin-left: 10px;
}
</style>
