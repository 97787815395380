<template>
  <div class="all">
    <div class="w-9-0">
      <email01 background="bl" />
      <div class="flex row-start mmw">
        <img src="../../../assets/20230905/Chats.svg" class="eimg" />
        <div class="mtxt">
          {{ $t('message.index.contact') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import email01 from '@/components/com/email.vue' //头部
export default {
  components: {
    email01,
  },
}
</script>

<style lang="less" scoped>
.all {
  width: 100%;
  .w-9-0 {
    width: 100%;
    background-color: #1b1b1b;
    padding-top: 90px;
    padding-bottom: 90px;
    .mmw {
      // width: 250px;
      margin: 0 auto;
      margin-top: 24px;
      width: fit-content;

      .eimg {
        width: 18px;
      }

      .mtxt {
        margin-left: 10px;
        font-family: League Spartan;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
}
</style>
