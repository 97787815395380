<template>
  <div class="all">
    <div class="w-4">
      <div class="bot">{{ $t('message.index.subscribe_for') }}</div>
      <div class="flex row-center">
        <div class="border">
          <img src="../../assets/20230905/email.svg" class="img-1" />
          <input
            type="text"
            :placeholder="this.$t('message.index.email_placeholder')"
            class="input"
            :class="inputClass"
            v-model="email"
            @keyup.enter="getData"
            autocomplete="off"
          />
        </div>
        <div class="m-t btn" v-show="issub" @click="getData">
          {{ $t('message.index.subscribe') }}
        </div>
        <div class="m-t btn sub" v-show="!issub && !isok">
          <img src="../../assets/20230905/wait.svg" class="waitimg" />
        </div>

        <div class="m-t btn sub" v-show="!issub && isok">
          <img src="../../assets/20230905/ok.svg" class="waitimg" />
        </div>
      </div>
      <div v-show="suberror" class="errors-txt">{{ errortxt }}</div>
      <div v-show="isok" class="success-txt">
        {{ $t('message.index.thanks_for') }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      inputClass: '', //有三种样式，根据传入的changeClass1来判断显示那种样式
      email: '',
      suberror: false,
      errortxt: '',
      issub: true,
      isok: false,
      email_placeholder: this.$t('message.index.email_placeholder'),
    }
  },
  props: {
    background: String, //有三种样式，显示第几种
  },
  created() {
    this.inputClass = this.background
  },
  watch: {
    email(newVal) {
      console.log(newVal)
      this.suberror = false
      this.issub = true
      this.isok = false
    },
  },
  methods: {
    getData() {
      var search = window.location.search
      var tp = ''
      if (search.length > 0) {
        console.log('search', search)
        var params = new URLSearchParams(search)
        tp = params.get('qudao')
        console.log('tp', tp)
      }
      console.log('ee', this.email)
      if (this.email.length == 0) {
        this.suberror = true
        this.isok = false
        this.errortxt = this.$t('message.index.pleass_input')
        return
      }
      this.issub = false
      // const data=this.email;
      this.face(this.email)
      var data = {
        email: this.email,
        platform: tp == null ? '' : tp,
      }
      this.$store.dispatch('getData', data).then((res) => {
        const data = res.data
        if (data.code != 0) {
          this.errortxt = this.$t('message.index.thanks_error')
          this.suberror = true
          this.issub = true
          this.isok = false
        } else {
          this.suberror = false
          this.errortxt = ''
          this.isok = true
        }
      })
    },
    face(para) {
      console.log('para', para)
      window.fbq('track', 'Subscribe', {
        predicted_ltv: para,
      })
      window.gtag_report_conversion(para)
    },
  },
}
</script>
<style lang="less" scoped>
.errors-txt {
  font-family: League Spartan;
  font-size: 0.16rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: #ff6060;
  margin-top: 0.12rem;
  text-align: center;
}

.success-txt {
  font-family: League Spartan;
  font-size: 0.16rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: #249eff;
  margin-top: 0.12rem;
  text-align: center;
}
.all {
  width: 100%;

  .w-4 {
    width: 3.33rem;
    margin: 0 auto;

    .bot {
      width: 100%;
      text-align: center;
      font-family: League Spartan;
      font-size: 0.27rem;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      color: #fff;
      margin-bottom: 0.04rem;
    }

    .border {
      border: 1.5px solid #fff;
      background: rgba(0, 0, 0, 0.01);
      backdrop-filter: blur(15px);
      width: 2.21rem;
      // padding: 0.08rem 0.07rem;
      margin-right: 0.1rem;
      height: 0.42rem;
      .img-1 {
        width: 0.18rem;
        margin-left: 0.07rem;
        //margin-top: 0.1rem;
      }

      .input {
        width: 1.61rem;
        margin-left: 0.1rem;
        background-color: #000;
        font-size: 0.16rem;
        line-height: 0.42rem;
        color: #fff;
        // margin-top: 0.12rem;
        height: 100%;
      }

      input:-webkit-autofill {
        box-shadow: 0 0 0 1000px #000 inset !important;
      }

      input:-internal-autofill-previewed,
      input:-internal-autofill-selected {
        -webkit-text-fill-color: #fff !important;
        transition: background-color 5000s ease-in-out 0s !important;
      }

      .bl1 {
        background-color: #1b1b1b;
      }

      .bl1:-webkit-autofill {
        box-shadow: 0 0 0 1000px #1b1b1b inset !important;
      }
    }
    .error-border {
      border: 1.5px solid #ff6060;
      background: rgba(0, 0, 0, 0.01);
      backdrop-filter: blur(15px);
    }
    .btn {
      width: 0.96rem;
      height: 0.42rem;
      border: 1.5px solid #fff;
      background: rgba(0, 0, 0, 0.01);
      backdrop-filter: blur(15px);
      line-height: 0.42rem;
      color: #fff;

      color: #fff;
      leading-trim: both;
      text-edge: cap;
      font-family: League Spartan;
      font-size: 0.16rem;
      font-style: normal;
      font-weight: 400;
      text-align: center;
    }
    .sub {
      background-color: #fff;
      padding-left: 0.36rem;
      padding-right: 0.36rem;
    }
  }
}
</style>
