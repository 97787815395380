<template>
  <div class="all">
    <div class="top">
      <PCHeader1 :changeClass1="changeClass1" :tab-index1="tabIndexs1" />
      <PCIndexTop />
    </div>
    <div>
      <!--TurnDirections-->
      <TurnDirections />
    </div>
    <div>
      <!--NavigationMap-->
      <NavigationMap />
    </div>
    <div>
      <!--Whether-->
      <Whether />
    </div>
    <div>
      <!--Video01-->
      <Video01 />
    </div>
    <div>
      <!--FullFeatured-->
      <FullFeatured />
    </div>
    <div>
      <!--RidersCan-->
      <RidersCan />
    </div>
    <div>
      <!--Experience-->
      <Experience />
    </div>
    <div>
      <!--HUDFunctionality-->
      <HUDFunctionality />
    </div>
    <div>
      <!--AntiTech-->
      <AntiTech />
    </div>
    <div>
      <!--Comfortable-->
      <Comfortable />
    </div>
    <div>
      <!--InstantPower-->
      <InstantPower />
    </div>
    <div>
      <!--ShiftLock-->
      <ShiftLock />
    </div>
    <div>
      <!--VoiceCommands-->
      <VoiceCommands />
    </div>
    <div>
      <!--Prescription-->
      <Prescription />
    </div>
    <div>
      <!--Polarized-->
      <Polarized />
    </div>
    <div>
      <!--RideWithStyle-->
      <RideWithStyle />
    </div>
    <div>
      <!--OuterPhotochromic-->
      <OuterPhotochromic />
    </div>
    <div>
      <!--EmailBottom-->
      <EmailBottom />
    </div>

    <!-- 中间三张图 -->
    <!-- <PCIndexMid /> -->
    <!-- <PCIndexMid2 />
    <PCIndexMid3 /> -->
    <!-- <PCIndexMid4 /> -->

    <PCFooter />
  </div>
</template>

<script>
import PCHeader1 from '@/components/header/PCHeader04.vue' //头部
// import PCIndexTop from '@/components/index/PCIndexTop.vue'

import PCIndexTop from './components/Top.vue'
import TurnDirections from './components/TurnDirections.vue'
import NavigationMap from './components/NavigationMap.vue'
import Whether from './components/Whether01.vue'
import Video01 from './components/Video01.vue'
import FullFeatured from './components/FullFeatured.vue'
import RidersCan from './components/RidersCan.vue'
import Experience from './components/Experience.vue'
import HUDFunctionality from './components/HUDFunctionality.vue'
import AntiTech from './components/AntiTech.vue'
import Comfortable from './components/Comfortable.vue'
import InstantPower from './components/InstantPower.vue'
import ShiftLock from './components/ShiftLock.vue'
import VoiceCommands from './components/VoiceCommands.vue'
import Prescription from './components/Prescription.vue'
import Polarized from './components/Polarized.vue'
import RideWithStyle from './components/RideWithStyle.vue'
import OuterPhotochromic from './components/OuterPhotochromic.vue'
import EmailBottom from './components/EmailBottom.vue'

// import PCIndexMid from "@/components/index/PCIndexMid.vue";
// import PCIndexMid2 from '@/components/index/PCIndexMid2.vue'
// import PCIndexMid3 from '@/components/index/PCIndexMid3.vue'
// import PCIndexMid4 from '@/components/index/PCIndexMid4.vue'
import PCFooter from '@/components/footer/PCFoote01.vue' //底部
// import PCNews from "@/components/news/PCNews.vue"; //新闻

export default {
  components: {
    PCHeader1,
    PCIndexTop,
    TurnDirections,
    NavigationMap,
    Whether,
    Video01,
    FullFeatured,
    RidersCan,
    Experience,
    HUDFunctionality,
    AntiTech,
    Comfortable,
    InstantPower,
    ShiftLock,
    VoiceCommands,
    Prescription,
    Polarized,
    RideWithStyle,
    OuterPhotochromic,
    EmailBottom,
    // PCIndexMid2,
    // PCIndexMid3,
    // PCIndexMid4,
    PCFooter,
    //PCNews,
  },
  data() {
    return {
      changeClass1: 2,
      tabIndexs1: 0, //用来传值给子组件,来显示点击了第几个
      author: this.$t('message.Moto'),
    }
  },
  activated() {},
  mounted() {
    //监听页面滚动事件
    // 要防抖
    window.addEventListener('scroll', this.debounce(this.handleScroll, 10))
  },
  methods: {
    // 滑动
    handleScroll: function () {
      let top =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      if (top < 60) {
        this.changeClass1 = 2
      } else {
        this.changeClass1 = 2
      }
    },
    // 防抖函数
    debounce(fn, wait) {
      let timeout = null
      return function () {
        if (timeout !== null) clearTimeout(timeout)
        timeout = setTimeout(fn, wait)
      }
    },
  },
}
</script>

<style lang="less" scoped>
// 公共样式
.font01 {
  font-family: League Spartan;
  font-size: 42px;
  font-style: normal;
  line-height: 100px;
  color: #fff;
  margin-left: 100px;
}
.test100 {
  font-weight: 100;
}
.test200 {
  font-weight: 200;
}
.test300 {
  font-weight: 300;
}
.test400 {
  font-weight: 400;
}
.test500 {
  font-weight: 500;
}
.test600 {
  font-weight: 600;
}
.test700 {
  font-weight: 700;
}
.test800 {
  font-weight: 800;
}
.test900 {
  font-weight: 900;
}
</style>
