// en.js
export default {
    message: {
        headers: {
            Moto: 'Moto',
            Prescription: 'Prescription',
            Blucap_Go: 'Blucap Go',
            Replacement_Lenses: 'Replacement Lenses',
            News: 'News',
            About_Us: 'About Us',
            Dealer: 'Dealer'
        },
        buttons: {
            button1: 'Learn More',
            // button2:'Reserve Now!',
            // button3:'Reserve now!',
            button2: 'Kickstarter',
            button3: 'Buy Now',
        },
        index: {
            //tips:'MSRP: $399. Kickstarter campaign launching soon. Limited-time Reserve to secure the 25% off Super Early Bird price.',//origin
            //tips:'MSRP:$399. Kickstarter campaign launching soon.',//0928
            tips: 'Live on Kickstarter! Limited time to get the 20% special discount!',//1003
            meet: 'Meet the world\'s first motorcycle navigation sunglasses, offering a brand new navigation experience while immersing yourself in every moment of the ride.',
            subscribe: 'Subscribe',
            subscribe_for: 'Subscribe for deals and news!',
            email_placeholder: 'Email address',
            thanks_for: 'Thanks for subscribing!',
            thanks_error: 'Subscribing failed',
            pleass_input: 'Please input the correct email address',
            watch_video: 'Watch Video',
            most_cutting: 'Most Cutting-edge AR Technology',
            only_the: ' Only the utilization of cutting-edge technology can meet Blucap\'s high demands for both design and performance.',
            innovation: 'Blucap™ Innovation Waveguide Lens',
            customized: 'Blucap\'s customized, specialized waveguide lens excels with high transparency, anti-fog capabilities, anti-glare reflection, and prescription options, among other features.',
            smallest: 'The World\'s Smallest MicroLED Display',
            temples: ' The world\'s smallest 0.13" MicroLED Display, with high brightness, and ultra-low power consumption that make the temples the same as ordinary glasses.',
            navigation_map: 'Navigation Map',
            navigation_map_seamless: 'Navigate with a real-time map, meeting all your navigation needs in complex road conditions.',
            navigation_map_seamless2: 'That\'s why we call them navigation glasses.',
            turn_directions: 'Turn Directions',
            turn_directions_seamlessly: 'The turn signal indicator automatically appears and disappears. ',
            turn_directions_seamlessly2: 'Minimalist design of HUD system, no distraction at all.',
            technology: 'Look and feel just like ordinary glasses.',
            technology_seamlessly: 'Glasses with no buttons, LEDs, or USB sockets; just timeless elegance meeting invisible innovation.',
            power: 'Instant Power On/Off',
            power_seamlessly: 'Simply open the temples of the sunglasses to power on, automatically connecting to your phone. Close the glasses to power off.Effortlessly Worn Like Regular Sunglasses',
            shiftLock: 'ShiftLock™ Quick Release Design',
            shiftLock_seamlessly: 'Blucap\'s exclusive ShiftLock™ quick release design allows for easy replacement. Meets the needs of night riding, colored replacement lenses, and prescription eyewear.',
            polarized: 'Polarized',
            anti_fog: 'Anti-Fog',
            water: 'Water and Oil Repellent',
            anti_scratch: 'Anti-Scratch',
            protection: '100% UVA/UVB Protection',
            performance: 'High Performance Frames',
            contrast: 'Contrast Enhancement',
            definition: 'High Definition',
            message: 'Message',
            call: 'Call',
            alert: 'Alert',
            stay_informed: 'HUD Functionality Stay informed and focused on the road with Blucap Moto\'s real-time call, message, and speed limit alerts. Never miss important notifications while maintaining your safety and concentration during your ride.',
            easy_to_use: 'Easy-to-use and full-featured navigation',
            easy_to_use_seamlessly: 'Blucap Moto connects to the Blucap App via Bluetooth for navigation, fully utilizing the GPS hardware capabilities of your smartphone.',
            it_contains_all: 'It contains all the necessary functions for riding, such as adding multiple stops, route options, and the option to avoid traffic.',
            smart_Routing: 'Smart Routing, Unexpected Adventure',
            set_your_direction: 'Set your direction and distance to generate epic round-trip journeys.Customize your route even further by avoiding certain roads or adjusting the curvature, creating a tailored riding experience unique to you.',
            basic_features: '',
            voice_commands: 'Voice Commands',
            perfectly_compatible: 'Perfectly Compatible with Your Own Bluetooth Headset for Voice Control, Hands-Free Calls, and More.',
            handlebar_remote_control: 'Handlebar Remote Control',
            compatibility: 'Compatibility with all motorcycle models.',
            waterproof: 'Waterproof',
            built_to_withstand: 'Built to withstand impact and water exposure.',
            battery_life: 'Battery Life',
            powered_by_a_button: 'Powered by a button cell battery, requiring no replacement for up to six months.',
            full_featured_navigation: 'Ride with Style',
            full_featured_seamlessly: 'Blucap Moto presents an upcoming array of chic and high-quality lens options, empowering you to ride with a touch of individuality and exceptional optical performance.',
            lenses: 'Prescription Customization',
            lenses_seamlessly: 'Transform your Blucap Moto into regular eyeglasses with Heads-Up Display (HUD) capabilities by removing the outer sunglass lenses. Get a unique version of Blucap Moto glasses with prescription lenses tailored for you.',
            contact: 'contact: support@blucapsport.com',
            copyright: 'Copyright @ 2024. All rights reserved.',
            privacy_policy: 'Privacy Policy',
            wether_title: 'Whether to display the map is up to the rider',
            weather_content: 'Easily control the map with a handlebar remote. Use the dedicated Map Button to show or hide the navigation map. Compatible with various motorcycle handlebars.',
            weather_ip: 'IP68 waterproof',
            weather_batter: 'Two batteries last 6 months',
            riders: 'Riders can easily transition from Google Maps, Apple Maps, or Waze to Blucap Moto\'s App. ',
            experience: 'Experience the road like never before with Blucap Moto - where cutting-edge AR technology meets the elegance of classic sunglasses',
            hud: 'HUD Functionality',
            hud_content: 'Stay informed and focused on the road with Blucap Moto\'s real-time call, message, and speed',
            hud_content2: 'limit alerts. Never miss important notifications while maintaining your safety and',
            hud_content3: 'concentration during your ride.',
            ant: 'Anti-Tech™ Design',
            ant_content1: 'Blucap Moto has no buttons, LEDs, or USB sockets.',
            ant_content2: 'From its appearance, no one would suspect it\'s a pair of smart glasses.',
            comfortable: 'Comfortable wearing experience inside the helmet',
            comfortable_content: 'Blucap was founded by a group of experienced riders, we understand the feeling of wearing eyewear when we ride. We have minimized the size and weight of the Blucap Moto to the extreme, ensuring that it is comfortable to wear under a helmet during long rides.',
            outer_title: 'Outer Photochromic Replacement Lenses',
            outer_content: 'Adapts to different light intensities. Transition from clear to dark in just 20 seconds when exposed to sunlight.',
            immerse_title: 'Immerse yourself in your ride',
            immerse_content_1: 'Bye-bye to phone holders. Start navigation, turn off the screen, and pocket your phone.',
            immerse_content_2: 'Escape the distractions of digital screens while navigating.',
        },
        lenses: {
            replacement: 'ShiftLock™ Replacement Lenses for Blucap Moto',
            money: '$49.00',
            sunglasses: 'Blucap\'s unique ShiftLock™ Multi-functional sunglasses replacement lenses provide a crisp view under strong light, and add infinite possibilities to every journey',
            polarization: 'Polarization',
            bayer_level: 'Bayer level 7 scratch resistance',
            oil_water: 'Oil & Water repellent coating',
            anti_fog: 'Anti-fog',
            protection: '100% UVA/UVB protection',
            contrast: 'Contrast enhancement',
            photochromic_lens: 'Blucap\'s unique ShiftLock™ photochromic lens can switch to different lenses in seconds.',
            light: 'Light Transmittance super wide range: 80% - 10%',
            color: 'Color',
            Navy_Blue: 'Navy Blue',
            Dark_Green: 'Dark Green',
            Dark_Grey: 'Dark Grey',
            Tea_Brown: 'Tea Brown',
            Mirrored_Yellow: 'Mirrored Yellow',
            Mirrored_Green: 'Mirrored Green',
            Mirrored_Red: 'Mirrored Red',
            Mirrored_Silver: 'Mirrored Silver',
            Mirrored_Blue: 'Mirrored Blue',
            Black: 'Black',
            Photochromic: 'Photochromic',
            coming_soon: 'Coming soon',
            care_instructions: 'Care Instructions',
            gentle_cleaning: 'Gentle Cleaning: Use lukewarm water and a mild detergent to gently clean your prescription lenses. Avoid using cleaners containing alcohol or strong acids and alkalis, as they can damage coatings or frame materials.',
            special_cloth: 'Special Cloth: Use the provided specialized cleaning cloth to gently wipe the lenses. Avoid using regular tissues or clothing, as they might scratch the lens surface.',
            avoid_scratches: 'Avoid Scratches: When not wearing your glasses, store them in a dedicated eyeglass case to prevent scratches or contamination to the lenses.',
            avoid_high: 'Avoid High Temperatures: Keep your glasses away from high-temperature environments, such as hot cars or direct sunlight, to prevent damage to the lenses or frames.',
            no_saliva: 'No Saliva or Clothing: Avoid using saliva, household cleaners, or ordinary clothing to clean the lenses, as this can lead to smudging or damage.',
            regular_inspection: 'Regular Inspection: Periodically inspect your lenses for scratches or damage.If any issues are found, contact a professional eyewear store promptly.',
            avoid_chemical: 'Avoid Chemical Contact: Prevent your glasses from coming into contact with strong acids, strong alkalis, chemical cleaners, and similar substances to avoid damaging coatings or lenses',

        },
        prescription: {
            prescription_lenses: 'Prescription Customization',
            money: '$209.00',
            order_for: 'Your order for the prescription  will be fulfilled and shipped within 6-8 weeks from the date of purchase.',
            experience: 'If you want to experience Blucap Moto with prescription lenses, you can order prescription lenses separately.',
            smart_glasses: '*The smart glasses are not included and must be purchased separately.',
            prescription_information: 'Prescription information',
            how_to_read: 'How to read your prescription?',
            right_sphere: 'Right sphere',
            select: 'Select',
            left_sphere: 'Left sphere',
            right_cylinder: 'Right cylinder',
            none: 'None',
            left_cylinder: 'Left cylinder',
            right_axis: 'Right axis',
            left_axis: 'Left axis',
            right_pd: 'Right PD',
            left_pd: 'Left PD',
            pd: 'PD',
            have_2pd: 'I have 2 PD numbers',
            the_AXIS: 'The AXIS values you’ve inserted are not correct!These values must be between 1 and 180.',
            remark: 'Remark',
            type_your: 'Type your message here',
            i_confirm: 'I confirm the validity and accuracy of my prescription, ensuring it aligns with my physical requirements. I also acknowledge that I will not raise any claims or requests regarding any information or specifications that deviate from the prescription.',
            i_acknowledge: 'I acknowledge that the provided written specifications, originating from the prescription, are essential for completing the order. I also comprehend and grant my consent to Blucap for the utilization and sharing of my prescription details with its third-party supplier(s) and partners,exclusively for the purpose of processing the transaction(s) and fulfilling my order.',
            i_understand: 'After placing an order, a professional optician will contact you to confirm your prescription information. Once the prescription information is confirmed, it will be non-refundable.',
            i_ifthere: 'If there is a delay in shipment due to reasons related to Blucap, we will contact you promptly. You can choose to either continue waiting or request a refund.',
            coming_soon: 'Coming soon',
            understanding: 'Understanding your prescription',
            right_eye: 'Right Eye (OD)',
            stands: 'OD stands for "oculus dexter" which is Latin for "right eye"',
            left_eye: 'Left Eye (OS)',
            left_stands: 'OS stands for "oculus sinister" which is Latin for "left eye"',
            sphere: 'Sphere (SPH) -also known as Power (PWR)',
            they_mean: 'They mean the same thing: the strength of your prescription. If you\'re nearsighted you will have a minus (-) before your values, and if you\'re or farsighted you will have a plus (+).',
            cyl: 'Cylinder (CYL)',
            people: 'This is for people with astigmatism. This is when one part of the eye needs more correction than the rest. The Cylinder value is written with a minus (-)sign.',
            axis: 'Axis',
            this_is_only: 'This is only for people with astigmatism. The axis is a number between O and 180 and determines the orientation of the Cylinder (CYL).',
            oK: 'OK',

        },
        news: {
            blucap_unveils: 'Blucap Unveils Official Release of Blucap Moto Motorcycle Navigation Sunglasses',
            the_blucap_team: 'The Blucap Team, September 13, 2023',
            september_13: '[September 13, 2023] – Blucap, the pioneering smart eyewear brand, announces the Blucap Moto navigation sunglasses, creating a new way of navigation and riding experience for motorcycle enthusiasts.',
            motorcycle: 'A Revolutionary Motorcycle Navigation',
            blucap_moto_is: ' Blucap Moto is a navigation device that displays information directly in front of the rider\'s line of sight. It offers a distraction-free experience, with turn arrows that only appear when needed. In the name of navigation glasses, Blucap Moto stands out for its map navigation, which can be controlled with a handlebar remote, meeting all your navigation needs in complex road conditions.',
            in_addition: 'In addition, Blucap Moto connects to phones via Bluetooth, eliminating the need to keep the phone screen on when using its navigation features. Riders can choose to keep their phones in a bag or pocket, avoiding the inconvenience of mounting the phone on a holder and potential issues like camera lens vibration damage, rain damage, phone overheating, and battery consumption.',
            with_blucap_moto: ' With Blucap Moto, riders can ride safely and without distractions, enjoying an immersive riding experience without the need to look down at their phones.',
            navigation_display: 'Navigation Display',
            blucap_has_developed: 'Blucap has developed a full-featured navigation app designed specifically for motorcycle riders. This app offers features such as multiple route options, the ability to add multiple stops, and the option to avoid traffic. It can replace mainstream navigation applications on the market.',
            app_functionality: 'App Functionality - Basic',
            blucap_has_designed: 'Blucap has designed a smart routing function, allowing riders to obtain a recommended riding route generated by an algorithm. The route is based on the rider\'s personal preferences, including the general direction and distance of the ride, as well as their preference for winding roads or scenic spots. This feature provides a personalized and enjoyable riding experience for riders.',
            basic_features: '',
            app_advanced: 'App Functionality - Advanced',
            design: 'Design Excellence and Wearing Experience',
            according_to_blucap: ' According to Blucap\'s design philosophy, smart glasses should first and foremost provide a comfortable wearing experience, just like regular glasses. Unlike other smart or AR glasses, the Blucap Moto is lightweight and has the appearance of regular sunglasses. The thin temples and flexible material at the ends make it easy to wear comfortably under a helmet.',
            the_blucap_design: 'The Blucap design team has defined an "Anti-Tech" design for electronic function interaction. The glasses do not have any buttons, LED indicators, or USB ports. From the outside, they look like a pair of understated, classic-style sunglasses, but they actually contain cutting-edge AR technology inside.',
            when_unfolding: 'When unfolding and wearing the glasses, they automatically power on and connect to a mobile phone via Bluetooth. This makes daily usage as simple as wearing and removing normal glasses, without any redundant steps.',
            power_on: 'Power-on',
            the_charging: 'The charging LED and low-frequency button have been relocated to the magnetic charging dock, resulting in fewer electronic components on the glasses. This design change further reduces the weight and power consumption of the glasses. Blucap has also developed a unique design feature called ShiftLock™, which enables quick and easy replacement of outer lenses with different levels of light transmission. This makes it possible to adapt to night riding and use as prescription glasses. Polarized and photochromic lenses are also available for purchase.',
            release: 'ShiftLock™ Quick Release Design',
            customized: 'Customized Prescription Lenses',
            traditional_motorcycle: 'Traditional motorcycle sunglasses use an RX adapter for prescription lenses. This not only looks unattractive but also adds weight to the glasses. However, Blucap has designed and established a unique supplier system that allows for prescription customization through an upgrade service. This means that Blucap Moto can be fitted with prescription lenses without altering the appearance or adding an RX adapter. This provides an attractive and lightweight solution for riders.',
            prescription_lenses: 'Prescription Lenses',
            cutting_edge: 'Cutting-edge AR Technology',
            focused: 'Blucap focused on cutting-edge Micro LED and waveguide lenses as its optical solution two years ago, in order to avoid the bulky shape, heavy weight, and high power consumption of traditional AR glasses,as well as their thick temples. This was the only way to meet the design requirements of the Blucap brand for glasses\' appearance and weight. Blucap Moto uses JBD\'s 0.13-inch Micro LED, which is the smallest and brightest display in the world. The light engine composed of it is only the size of an eraser at the end of a pencil,allowing it to be discreetly implanted at the root of the glasses\' legs. Compared to other AR visual solutions, it not only has a small size that does not invade the field of view but also has the advantages of high brightness and low power consumption.',
            microled: 'Blucap Moto and MicroLED Light Engine',
            uniquely: 'Blucap Moto has uniquely designed a diffractive waveguide lens to cater to actual riding scenes. This type of waveguide lens can meet complex optical performance requirements, such as high transparency,high brightness transmission, fog resistance, anti-glare reflection, and prescription customization, compared to ordinary waveguide lenses. With strong support from AR suppliers and manufacturing partners, Blucap Moto has completed the preparation of the key technical confirmation before mass production.',
            sales_plan: 'Sales Plan',
            official: 'The official retail price of Blucap Moto is $399. The dealer program is currently underway, and those interested in becoming a Blucap dealer can apply by filling out the official dealer application form. At the end of September, Blucap Moto will launch a project on Kickstarter with early bird pricing available. For more details, stay tuned for a follow-up Kickstarter page.',
            fully: 'Blucap Moto has not yet been fully released, but it has already amassed over 26,000 followers on social media platforms such as Facebook and Instagram in the past three months. The dealer program,launched just one month ago, has received over 200 applications from around the world. The applicants include motorcycle dealerships, equipment stores, custom shops, eyewear stores, and various online channels and individuals from North America, Europe, and Asia. This strong interest and anticipation for Blucap Moto\'s innovative product from riders all over the world demonstrates its potential for success.',
            market_resonance: 'Market Resonance',
            product_key: 'Product Key Features',
            real_time: 'Navigation: Real-time HUD Map Navigation, Turn Directions. Connects to phone via Bluetooth when worn as glasses, utilizing the phone\'s computing power and GPS hardware; the phone can remain locked during connection and use.',
            other_hud: 'Other HUD features: Speed, speeding alerts, incoming calls, and messages.',
            glasses_appearance: 'Ordinary glasses appearance, full helmet compatibility, Anti-Tech design: No buttons,LEDs, or USB sockets on the frame.',
            external_lens: 'ShiftLock™ external lens quick-release design, suitable for night riding.',
            replacement_lenses: 'Optional replacement lenses: 10-color polarized lenses and photochromic lenses, all with 100% UVA/UVB protection, anti-fog, scratch-resistant, hydrophobic and oleophobic coating, and contrast enhancement.',
            inner_lens: 'The inner lens supports prescription lens customization. When purchasing Blucap Moto,the user can simultaneously submit prescription information on the official website when placing an order.',
            voice_control: 'The voice control function can be triggered through the remote control, and it is compatible with Bluetooth headphones from other brands.',
            nose_pad: 'The nose pad is interchangeable in sizes M and L.',
            comes_with_a: 'The Blucap Moto comes with a magnetic charging adapter for easy charging.',
            light_engine: 'Ultra-low-power MicroLED Light engine and computing chip support all-day riding navigation(with a battery life of an average navigation usage of over 10 hours).',
            remote_control: 'The remote control connects to the phone app via Bluetooth. With multiple installation accessories, it can support various handlebar types.',
            about_blucap: 'About Blucap',
            about_us: 'About us→',
            this_article: 'Text of this article',
            copy_text: 'Copy text',
            download_all: 'Download all photos elements',
            zip569: 'ZIP | 56.9MB',
            advertisement: 'Advertisement_Video_EN.mp4',
            mp4106: 'MP4 | 106MB',
            vertical_advertisement: '30s_Vertical_Advertisement_Video_EN.mp4',
            mp4378: 'MP4 | 37.8MB',
            cg: 'CG_QuickShift_Video.mp4',
            mp447: 'MP4 | 4.7MB',
            cg_auto: 'CG_AutoConnection_Video.mp4',
            mp445: 'MP4 | 4.5MB',
            for_more: 'For more information, please contact:',
            zhaot: 'Zhao Tingting',
            brand_marketing: 'Brand Marketing',
            ztt: 'ztt@blucapsport.com',
            b_m: 'Blucap media',
            mb: 'media@blucapsport.com',
            b_m_img: 'Blucap Moto Image 1',
            b_m_pic: 'Blucap Moto Model Picture 1',
            b_m_color: 'Blucap Moto Color Lenses Image 1',
            b_m_unique: 'Blucap Moto\'s Unique Lenses',
            b_m_factor: 'Blucap Moto Factory Photo',
            press_contacts: 'Press Contacts',
        },
        about: {
            us: 'About Us',
            starting: 'Starting from Desire',
            initially: 'Zhu Ning initially encountered the problem of using a mobile phone for navigation while riding, and he was unable to find the ideal motorcycle HUD product. As a result, he began to create his own HUD device through DIY efforts.',
            journey: 'Journey Started',
            zhu_ning: 'In 2021, Zhu Ning founded Blucap, which gathered a team of committed riders, engineers,designers, eyewear experts, and map system experts. Our dedication to technological innovation and tasteful design drives us to constantly push the boundaries of what\'s possible in the world of motorcycle riding experience.',
            built: 'Built with Patience',
            throughout: 'Throughout our three-year exploration of our first product, Blucap Moto, we:',
            bravely: 'Bravely chosen cutting-edge AR technology to accompany the large-scale production maturity of the supply chain.',
            spent: 'Spent over half a year exploring and verifying a technical solution that could balance the requirements for HUD riding interaction, glasses volume, and power consumption.',
            redesigned: 'Redesigned the entire glasses to have a more reliable design for quick release of the lenses and a better visual experience, even if it meant delaying the product launch for more than half a year.',
            continuously: 'Continuously tested and improved the product with feedback from our team\'s riders and testing users around the world. We adjusted the prototype of the glasses more than 100 times and continuously optimized the software system',
            reliability_0: 'Reliability, Professionalism',
            reliability_1: ', and ',
            reliability_2: 'Sincerity ',
            reliability_3: 'are the core values of Blucap. We pay attention to every detail of our products and patiently polish each aspect to achieve the ultimate best.Welcome to Blucap. Where innovation meets the open road.',
            hq: 'Hangzhou HQ',
            dream: 'No.22 Dream Town Hangzhou, ZJ 311100, P.R.C',
            support: 'support@blucapsport.com',
            hk: 'Hong Kong',
            ug: 'Unit 2A, 17/F, Glenealy Tower, No.1 Glenealy Central',
            zb: 'zn@blucapsport.com',
            portland: 'Portland',
            upcoming: 'Upcoming'

        }
    }
}