// en.js
export default {
    message: {
        headers: {
            Moto: 'Moto',
            Prescription: 'Graduadas',
            Replacement_Lenses: 'Lentes de Repuesto',
            Blucap_Go: 'Blucap Go',
            News: 'Noticias',
            About_Us: 'Sobre Nosotros',
            Dealer: 'Distribuidor'
        },
        buttons: {
            button1: 'Preventa',
            // button2:'¡Resérvalas ahora!',
            // button3:'¡Resérvalas ahora!',
            button2: 'Kickstarter',
            button3: 'Buy Now',
        },
        index: {
            //tips:'Precio de venta al público recomendado (PVP): $399. Lanzamiento en Kickstarter a finales de septiembre. ¡Resérvalas ahora para asegurarte un 25% de descuento para los primeros compradores!',
            //tips:'Precio de venta recomendado: 399 dólares. Próximamente se lanzará una campaña de Kickstarter.',//0928
            tips: '¡En vivo en Kickstarter! ¡Tiempo limitado para obtener el descuento especial del 20%!',//1003
            meet: 'Conoce las primeras gafas de sol con navegación para motocicletas del mundo y sumérgete en cada momento del paseo.',
            subscribe: 'Suscríbete',
            subscribe_for: 'Suscríbase para recibir ofertas！',
            email_placeholder: 'Dirección de correo electrónico',
            thanks_for: '¡Gracias por suscribirte!',
            thanks_error: 'Error al suscribirse',
            pleass_input: 'Introduce una dirección de correo electrónico correcta',
            watch_video: 'Ver vídeo',
            most_cutting: 'Tecnología AR de Última Generación',
            only_the: ' Solo la utilización de tecnología de vanguardia puede cumplir con las exigentes demandas de Blucap en cuanto a diseño y rendimiento.',
            innovation: 'Lentes Innovadoras Blucap™ Waveguide',
            customized: 'La lente Waveguide personalizada de Blucap destaca, entre otras características, por su alta transparencia, capacidades anti-vaho, anti-reflejo y opciones de graduación.',
            smallest: 'La Pantalla MicroLED más pequeña del mundo',
            temples: ' La pantalla MicroLED de 0,13" más pequeña del mundo, con alta luminosidad y un consumo de energía ultra bajo que hace que las patillas sean iguales a las de unas gafas normales.',
            navigation_map: 'Mapa de navegación',
            navigation_map_seamless: ' Experimenta una navegación fluida con un mapa en tiempo real dentro de tu campo de visión. Controla fácilmente con un mando en el manillar, satisfaciendo todas tus necesidades de navegación en condiciones de carretera complejas. Por eso las llamamos gafas de navegación.',
            turn_directions: 'Indicaciones de giro',
            turn_directions_seamlessly: 'Blucap Moto muestra automáticamente los indicadores de giro al girar. Después de pasar intersecciones, hace una transición sin problemas a un estado de gafas de sol normales sin distracciones.',
            technology: 'Tecnología oculta en un diseño antitecnológico.',
            technology_seamlessly: 'Gafas sin botones, LED o puertos USB; simplemente elegancia atemporal combinada con innovación invisible.',
            power: 'Encendido/Apagado Instantáneo',
            power_seamlessly: 'Simplemente abre las patillas de las gafas para encenderlas, conectándose automáticamente a tu teléfono. Cierra las gafas para apagarlas.',
            shiftLock: 'Diseño de Liberación Rápida ShiftLock™',
            shiftLock_seamlessly: 'El diseño exclusivo de liberación rápida ShiftLock™ de Blucap permite un reemplazo fácil. Satisface las necesidades de conducción nocturna, lentes de repuesto de colores y gafas graduadas.',
            polarized: 'Polarizadas',
            anti_fog: 'Antivaho',
            water: 'Repelentes al agua y aceite',
            anti_scratch: 'Antirrayaduras',
            protection: 'Protección 100% UVA/UVB',
            performance: 'Monturas de alto rendimiento',
            contrast: 'Mejora del contraste',
            definition: 'alta definición',
            message: 'Mensaje',
            call: 'Llamada',
            alert: 'Alerta',
            stay_informed: 'Mantente informado y concentrado en la carretera con las alertas de llamadas, mensajes y límites de velocidad en tiempo real de Blucap Moto. Nunca te pierdas las notificaciones más importantes mientras mantienes tu seguridad y concentración en el paseo.',
            easy_to_use: 'Navegación fácil de usar y completa',
            easy_to_use_seamlessly: 'Blucap Moto se conecta a la aplicación Blucap a través de Bluetooth para la navegación, utilizando al máximo las capacidades de hardware GPS de tu smartphone.',
            it_contains_all: 'Contiene todas las funciones necesarias para montar en moto, como agregar múltiples paradas, opciones de ruta y la opción de evitar atascos de tráfico.',
            smart_Routing: 'Rutas Inteligentes, Aventuras Inesperadas',
            set_your_direction: 'Establece tu dirección y distancia para generar épicas excursiones de ida y vuelta. Personaliza tu ruta al máximo, evitando ciertas carreteras o ajustando la curvatura, creando una experiencia de conducción a medida única para ti.',
            basic_features: '',
            voice_commands: 'Comandos de Voz',
            perfectly_compatible: 'Es compatible con tu propio auricular Bluetooth para control por voz, llamadas a manos libres y mucho más.',
            handlebar_remote_control: 'Control Remoto en el Manillar',
            compatibility: 'Compatibles con todos los modelos de motocicletas.',
            waterproof: 'Impermeable',
            built_to_withstand: 'Diseñadas para resistir impactos y exposición al agua.',
            battery_life: 'Duración de la batería',
            powered_by_a_button: 'Alimentadas por una pila de botón, con una autonomía de hasta seis meses.',
            full_featured_navigation: 'Viaja con estilo',
            full_featured_seamlessly: 'Blucap Moto presenta una próxima variedad de elegantes y opciones de lentes de alta calidad, que te permiten viajar con un toque de individualidad y un excepcional rendimiento óptico',
            lenses: 'Lentes Graduadas',
            lenses_seamlessly: '¡Quita las lentes de sol exteriores para convertir tus Blucap Moto en gafas de siempre con capacidad de visualización en pantalla (HUD)!',
            contact: 'Contacto: support@blucapsport.com',
            copyright: 'Derechos de autor @ 2023. Todos los derechos reservados.',
            privacy_policy: 'Política de privacidad'
        },
        lenses: {
            replacement: 'Lentes de Repuesto ShiftLock™ para Blucap Moto',
            money: '$49.00',
            sunglasses: 'Las lentes de repuesto únicas ShiftLock™ de Blucap ofrecen una vista nítida bajo luz intensa y añaden posibilidades infinitas a cada viaje.',
            polarization: 'Polarización',
            bayer_level: 'Resistencia a los arañazos de nivel 7 de Bayer',
            oil_water: 'Recubrimiento repelente al agua y al aceite',
            anti_fog: 'Antivaho',
            protection: 'Protección 100% UVA/UVB',
            contrast: 'Realce de contraste',
            photochromic_lens: 'La lente especial fotocromática ShiftLock™ de Blucap puede cambiarse a diferentes lentes en segundos. ',
            light: 'Rango súper amplio de transmisión de luz: 80% - 10%',
            color: 'Color',
            Navy_Blue: 'Azul Marino',
            Dark_Green: 'Verde oscuro',
            Dark_Grey: 'Gris Oscuro',
            Tea_Brown: 'Marrón Té',
            Mirrored_Yellow: 'Amarillo Espejado',
            Mirrored_Green: 'Verde Espejado',
            Mirrored_Red: 'Rojo Espejado',
            Mirrored_Silver: 'Plata Espejada',
            Mirrored_Blue: 'Azul Espejado',
            Black: 'Negro',
            Photochromic: 'Fotocromáticas',
            coming_soon: 'Próximamente',
            care_instructions: 'Cuidados',
            gentle_cleaning: 'Limpieza suave: Utiliza agua tibia y un detergente suave para limpiar delicadamente las lentes graduadas. Evita el uso de limpiadores que contengan alcohol o ácidos y álcalis fuertes, ya que pueden dañar los recubrimientos o materiales de la montura.',
            special_cloth: 'Paño especial: Utiliza el paño de limpieza especializado proporcionado para limpiar suavemente las lentes. Evita el uso de pañuelos o prendas de vestir normales, ya que podrían rayar la superficie de la lente.',
            avoid_scratches: 'Evita los arañazos: Cuando no uses las gafas, guárdalas en un estuche de gafas apropiado para evitar arañazos o contaminación en las lentes.',
            avoid_high: 'Evita altas temperaturas: Mantén tus gafas alejadas de ambientes de alta temperatura, como autos calientes o luz solar directa, para evitar daños en las lentes o monturas.',
            no_saliva: 'Sin saliva o ropa: No uses saliva, limpiadores domésticos o ropa común para limpiar las lentes, ya que esto puede causar manchas o daños.',
            regular_inspection: 'Inspección regular: Inspecciona periódicamente tus lentes en busca de arañazos o daños. Si encuentras algún problema, contacta de inmediato a una tienda de óptica profesional.',
            avoid_chemical: 'Evita el contacto con sustancias químicas: Evita que tus gafas entren en contacto con ácidos fuertes, álcalis fuertes, limpiadores químicos y sustancias similares para evitar dañar los recubrimientos o lentes.',

        },
        prescription: {
            prescription_lenses: 'Lentes graduadas para Blucap Moto',
            money: '$209.00',
            order_for: 'El pedido de tu graduación se completará y enviará en un plazo de 6 a 8 semanas a partir de la fecha de compra.',
            experience: 'Si deseas experimentar Blucap Moto con lentes graduadas, puedes solicitar las lentes graduadas por separado.',
            smart_glasses: '*Las gafas inteligentes no están incluidas y deben comprarse por separado.',
            prescription_information: 'Información de la graduación',
            how_to_read: 'Cómo leer tu graduación?',
            right_sphere: 'Esfera derecha',
            select: 'Seleccionar',
            left_sphere: 'Esfera izquierda',
            right_cylinder: 'Derecha Cilindro',
            none: 'Ninguno',
            left_cylinder: 'Izquierda Cilindro',
            right_axis: 'Derecha Eje',
            left_axis: 'Izquierda Eje',
            right_pd: 'Derecha DP',
            left_pd: 'Izquierda DP',
            pd: 'DP',
            have_2pd: 'Uso 2 valores distintos de DP',
            the_AXIS: '¡Los valores introducidos del EJE son incorrectos! Estos valores deben estar entre 1 y 180.',
            remark: 'Observaciones',
            type_your: 'Escribe tu mensaje aquí',
            i_confirm: 'Confirmo la validez y precisión de mi graduación, asegurando que se ajusta a mis necesidades físicas. También reconozco que no presentaré reclamaciones o solicitudes con respecto a cualquier información o especificación que se aparte de la graduación.',
            i_acknowledge: 'Reconozco que las especificaciones escritas proporcionadas, originadas a partir de la receta, son esenciales para completar el pedido. También comprendo y otorgo mi consentimiento a Blucap para la utilización y divulgación de la información relacionada con mi graduación con su(s) proveedor(es) externo(s) y socios, exclusivamente con el fin de procesar la(s) transacción(es) y cumplir con mi pedido.',
            i_understand: 'After placing an order, a professional optician will contact you to confirm your prescription information. Once the prescription information is confirmed, it will be non-refundable.',
            i_ifthere: 'Si el envío se retrasa por motivos relacionados con Blucap, nos pondremos en contacto contigo de inmediato. En tal caso, puedes esperar o solicitar el reembolso.',
            coming_soon: 'Próximamente',
            understanding: 'Entendiendo tu graduación',
            right_eye: 'Ojo Derecho (OD)',
            stands: 'OD significa "oculus dexter", que significa "ojo derecho" en latín.',
            left_eye: 'Ojo Izquierdo (OI)',
            left_stands: 'OI significa "oculus sinister", que significa "ojo izquierdo" en latín.',
            sphere: 'Esfera (ESF) -también conocida como Potencia (PWR)',
            they_mean: 'Significan lo mismo: sirven para tu graduación. Si eres miope, tendrás un menos (-) antes de tus valores, y si eres hipermétrope, tendrás un más (+).',
            cyl: 'Cilindro (CIL)',
            people: 'Esto es para personas con astigmatismo. Cuando una parte del ojo necesita más corrección que el resto. El valor del Cilindro se escribe con un signo menos (-).',
            axis: 'Eje',
            this_is_only: 'Esto es solo para personas con astigmatismo. El eje es un número entre 0 y 180 y determina la orientación del Cilindro (CIL).',
            oK: 'OK',

        },
        news: {
            blucap_unveils: 'Blucap presenta el lanzamiento oficial de las gafas de sol de navegación para motocicletas Blucap Moto',
            the_blucap_team: 'Blucap Equipo, 13 de septiembre de 2023, 2023',
            september_13: '[13 de septiembre de 2023] – Blucap, la marca pionera en gafas inteligentes, anuncia las gafas de sol de navegación Blucap Moto, que crean una nueva forma de navegación y experiencia de conducción para los entusiastas de las motocicletas.',
            motorcycle: 'Un revolucionario navegador para motocicletas',
            blucap_moto_is: ' Blucap Moto es un dispositivo de navegación que muestra información directamente en el campo de visión del conductor. Ofrece una experiencia sin distracciones, con flechas de giro que solo aparecen cuando son necesarias. Blucap Moto se destaca por su navegación por mapas en condiciones de carretera complejas.            ',
            in_addition: 'Adicionalmente, Blucap Moto se conecta a los teléfonos a través de Bluetooth, eliminando la necesidad de mantener la pantalla del teléfono encendida mientras se utilizan sus funciones de navegación. Los motociclistas tienen la opción de guardar sus teléfonos en una bolsa o en el bolsillo, evitando la incomodidad de montar el teléfono en un soporte y posibles problemas como daños causados por la vibración, daños por la lluvia, sobrecalentamiento del teléfono y consumo de batería.',
            with_blucap_moto: ' Con Blucap Moto, los motoristas pueden conducir de forma segura y sin distracciones, disfrutando de una experiencia de conducción envolvente sin necesidad de mirar hacia abajo a sus teléfonos.',
            navigation_display: 'Display de Navegación',
            blucap_has_developed: 'Blucap ha desarrollado una completa aplicación de navegación diseñada específicamente para motociclistas. Esta aplicación ofrece funciones como múltiples opciones de ruta, la posibilidad de añadir varias paradas y la opción de evitar el tráfico. Puede sustituir a las principales aplicaciones de navegación del mercado.',
            app_functionality: 'Funcionalidad Básica de la Aplicación',
            blucap_has_designed: 'Blucap ha diseñado una función de enrutamiento inteligente que permite a los conductores obtener una ruta recomendada generada por un algoritmo. La ruta se basa en las preferencias personales del conductor, incluyendo la dirección y la distancia general del recorrido, así como su preferencia por carreteras sinuosas o lugares escénicos. Esta función proporciona una experiencia de conducción personalizada y agradable para los motociclistas.',
            basic_features: '',
            app_advanced: 'Funcionalidad Avanzada de la Aplicación',
            design: 'Excelencia en el diseño y experiencia de uso',
            according_to_blucap: ' Según la filosofía de diseño de Blucap, las gafas inteligentes deben proporcionar una experiencia de uso cómoda en primer lugar, al igual que las gafas regulares. A diferencia de otras gafas inteligentes o AR, el Blucap Moto es ligero y tiene la apariencia de gafas de sol regulares. Las patillas delgadas y el material flexible en los extremos lo hacen fácil de usar cómodamente debajo de un casco.',
            the_blucap_design: 'El equipo de diseño de Blucap ha definido un diseño "Anti-Tech" para la interacción con las funciones electrónicas. Las gafas no tienen botones, indicadores LED ni puertos USB. Desde fuera, parecen un par de gafas de sol discretas y de estilo clásico, pero en realidad contienen tecnología AR de vanguardia en su interior.',
            when_unfolding: 'Al desplegarlas y ponérselas, se encienden automáticamente y se conectan a un teléfono móvil por Bluetooth. Esto hace que el uso diario sea tan sencillo como ponerse y quitarse unas gafas normales, sin pasos redundantes.',
            power_on: 'Encendido',
            the_charging: 'El LED de carga y el botón de baja frecuencia se han trasladado a la base de carga magnética, lo que resulta en menos componentes electrónicos en las gafas. Este cambio de diseño reduce aún más el peso y el consumo de energía de las gafas. Blucap también ha desarrollado una característica de diseño única llamada ShiftLock™, que permite la rápida y fácil sustitución de las lentes externas con diferentes niveles de transmisión de luz. Esto hace posible adaptarse a la conducción nocturna en motocicleta y su uso como gafas recetadas. También están disponibles lentes polarizadas y fotocromáticas para su compra.',
            release: 'Diseño de Liberación Rápida ShiftLock™',
            customized: 'Lentes graduadas personalizadas',
            traditional_motorcycle: 'Algunas gafas de sol para motocicleta y otras gafas de realidad aumentada utilizan un adaptador RX para lentes recetados. Esto no solo se ve poco atractivo, sino que también agrega peso a las gafas. Sin embargo, Blucap ha diseñado y establecido un sistema de proveedor único que permite la personalización de la prescripción a través de un servicio de actualización. Esto significa que Blucap Moto se puede equipar con lentes recetados sin alterar la apariencia o agregar un adaptador RX. Esto proporciona una solución atractiva y liviana para los motociclistas.',
            prescription_lenses: 'Lentes Graduadas',
            cutting_edge: 'Tecnología AR de Última Generación',
            focused: 'Hace dos años, Blucap se enfocó en las lentes Micro LED y de guía de ondas de última generación como solución óptica para evitar la forma voluminosa, el gran peso y el elevado consumo de energía de las gafas de realidad aumentada tradicionales, así como sus gruesas patillas. Esta fue la única forma de cumplir con los requisitos de diseño de la marca Blucap en cuanto al aspecto y peso de las gafas. Blucap Moto utiliza el Micro LED de 0,13 pulgadas de JBD, que es la pantalla más pequeña y brillante del mundo. El motor luminoso que lo compone tiene solo el tamaño de una goma de borrar de lápiz, lo que permite implantarlo discretamente en la raíz de las patas de las gafas. En comparación con otras soluciones visuales de realidad aumentada, no solo tiene un tamaño reducido que no invade el campo de visión, sino que también presenta las ventajas de una gran luminosidad y un bajo consumo de energía.',
            microled: 'Blucap Moto y Motor de Luz MicroLED',
            uniquely: 'Blucap Moto ha diseñado de forma exclusiva una lente de guía de ondas difractiva para adaptarse a las escenas reales de conducción. Este tipo de lente de guía de ondas puede satisfacer requisitos de rendimiento óptico complejos, como alta transparencia, transmisión de alto brillo, resistencia a la niebla, reflexión antideslumbrante y personalización de prescripción, en comparación con las lentes de guía de ondas ordinarias. Con un fuerte apoyo de proveedores de AR y socios de fabricación, Blucap Moto ha completado la preparación de la confirmación técnica clave antes de la producción en masa.',
            sales_plan: 'Plan de ventas',
            official: 'El precio oficial de venta al público de Blucap Moto es de 399 dólares. El programa de distribuidores está actualmente en marcha, y los interesados en convertirse en distribuidores de Blucap pueden solicitarlo rellenando el formulario oficial de solicitud de distribuidor. En septiembre, Blucap Moto lanzará un proyecto en Kickstarter con precios de reserva anticipada disponibles. Para más detalles, permanece atento a la página de Kickstarter.',
            fully: 'Blucap Moto aún no se ha lanzado por completo, pero ya ha acumulado más de 15.000 seguidores en plataformas de redes sociales como Facebook e Instagram en los últimos tres meses. El programa de concesionarios, lanzado hace apenas un mes, ha recibido más de 100 solicitudes de todo el mundo. Entre los solicitantes hay concesionarios de motocicletas, tiendas de equipamiento, tiendas custom, tiendas de gafas y diversos canales online y particulares de Norteamérica, Europa y Asia. Este gran interés y expectación por el innovador producto de Blucap Moto por parte de motoristas de todo el mundo demuestra su potencial de éxito.',
            market_resonance: 'Resonancia en el mercado',
            product_key: 'Características principales y especificaciones del producto',
            real_time: 'Navegación: Navegación por mapa HUD en tiempo real, indicaciones de giro. Se conecta al teléfono por Bluetooth cuando se lleva como gafas, utilizando la potencia de cálculo del teléfono y el hardware GPS; el teléfono puede permanecer bloqueado durante la conexión y el uso.',
            other_hud: 'Otras funciones del HUD: Velocidad, alertas de exceso de velocidad, llamadas entrantes y mensajes.',
            glasses_appearance: 'Aspecto de gafas normales, compatibilidad total con cascos, diseño Anti-Tech: Sin botones, LED ni tomas USB en la montura.',
            external_lens: 'Diseño ShiftLock™ de liberación rápida de la lente externa, apto para conducción nocturna., suitable for night riding.',
            replacement_lenses: 'Lentes de repuesto opcionales: Lentes polarizadas de 10 colores y lentes fotocromáticas, todas con protección 100% UVA/UVB, antivaho, resistentes a los arañazos, con revestimiento hidrofóbico y oleofóbico, y mejora del contraste.',
            inner_lens: 'La lente interior admite la personalización de lentes graduadas. Al comprar Blucap Moto, el usuario puede enviar simultáneamente la información de prescripción en el sitio web oficial al realizar el pedido.',
            voice_control: 'La función de control por voz puede activarse a través del mando a distancia, y es compatible con auriculares Bluetooth de otras marcas.',
            nose_pad: 'La almohadilla nasal es intercambiable en las tallas M y L.',
            comes_with_a: 'El Blucap Moto viene con un adaptador de carga magnético para facilitar la carga.',
            light_engine: 'El motor MicroLED Light de ultra bajo consumo y el chip informático permiten la navegación durante todo el día (con una duración de la batería de un uso medio de navegación de más de 10 horas).',
            remote_control: 'El mando a distancia se conecta a la aplicación del teléfono a través de Bluetooth. Con múltiples accesorios de instalación, puede soportar varios tipos de manillar.',
            about_blucap: 'Acerca de Blucap',
            about_us: 'Quiénes somos→',
            this_article: 'Texto de este artículo',
            copy_text: 'Copiar texto',
            download_all: 'Descargar todos los elementos de las fotos.',
            zip569: 'ZIP | 56.9MB',
            advertisement: 'Advertisement_Video_EN.mp4',
            mp4106: 'MP4 | 106MB',
            vertical_advertisement: '30s_Vertical_Advertisement_Video_EN.mp4',
            mp4378: 'MP4 | 37.8MB',
            cg: 'CG_QuickShift_Video.mp4',
            mp447: 'MP4 | 4.7MB',
            cg_auto: 'CG_AutoConnection_Video.mp4',
            mp445: 'MP4 | 4.5MB',
            for_more: 'Para obtener más información, por favor contacta a:',
            zhaot: 'Zhao Tingting',
            brand_marketing: 'Marketing de Marca',
            ztt: 'ztt@blucapsport.com',
            b_m: 'medios blucap',
            mb: 'media@blucapsport.com',
            b_m_img: 'Blucap Moto Imagen 1',
            b_m_pic: 'Modelo Blucap Moto Imagen 1',
            b_m_color: 'Lentes Blucap Moto Color Imagen 1',
            b_m_unique: 'Lentes únicas de Blucap Moto',
            b_m_factor: 'Foto de fábrica de Blucap Moto',
            press_contacts: 'Contactos de prensa',
        },
        about: {
            us: 'Sobre Nosotros',
            starting: 'Empezando desde el Deseo',
            initially: 'Zhu Ning inicialmente se encontró con el problema de usar un teléfono móvil para la navegación mientras conducía y no pudo encontrar el producto ideal de HUD para motocicletas. Como resultado, comenzó a crear su propio dispositivo HUD a través de esfuerzos de bricolaje.',
            journey: 'El Viaje Comenzó',
            zhu_ning: 'En 2021, Zhu Ning fundó Blucap, que reunió a un equipo de apasionados motociclistas, ingenieros, diseñadores, expertos en gafas y expertos en sistemas de mapas. Nuestra dedicación a la innovación tecnológica y al diseño de buen gusto nos impulsa constantemente a superar los límites de lo posible en el mundo de la experiencia de conducción en motocicleta.',
            built: 'Construido con Paciencia',
            throughout: 'A lo largo de nuestros tres años de exploración con nuestro primer producto, Blucap Moto, nosotros:',
            bravely: 'Valientemente elegimos la tecnología AR de vanguardia para acompañar la madurez de la producción a gran escala de la cadena de suministro.',
            spent: 'Pasamos más de medio año explorando y verificando una solución técnica que pudiera equilibrar los requisitos de interacción durante la conducción con HUD, el volumen de las gafas y el consumo de energía.',
            redesigned: 'Rediseñamos completamente las gafas para tener un diseño más fiable que permitiera la liberación rápida de las lentes y ofreciera una mejor experiencia visual, incluso si eso significaba retrasar el lanzamiento del producto durante más de medio año.',
            continuously: 'Continuamente probamos y mejoramos el producto con comentarios de los motociclistas de nuestro equipo y usuarios de pruebas de todo el mundo. Ajustamos el prototipo de las gafas más de 100 veces y optimizamos continuamente el sistema de software.',
            reliability_0: 'Confiabilidad, Profesionalismo',
            reliability_1: ', y ',
            reliability_2: 'Sinceridad ',
            reliability_3: 'La sinceridad" es uno de los valores fundamentales de Blucap. Prestamos atención a cada detalle de nuestros productos y pulimos pacientemente cada aspecto para alcanzar lo mejor en todo. Bienvenido a Blucap, donde la innovación se encuentra con el camino abierto.',
            hq: 'Hangzhou HQ',
            dream: 'No.22 Dream Town Hangzhou, ZJ 311100, P.R.C',
            support: 'support@blucapsport.com',
            hk: 'Hong Kong',
            ug: 'Unit 2A, 17/F, Glenealy Tower, No.1 Glenealy Central',
            zb: 'zn@blucapsport.com',
            portland: 'Portland',
            upcoming: 'Upcoming'

        }
    }
}