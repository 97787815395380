var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"all"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"ones"},[_c('div',{staticClass:"swiper-container"},[_c('div',{staticClass:"swiper-wrapper"},[_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(
            require('../../../assets/20240109/phone/01.png')
          ),expression:"\n            require('../../../assets/20240109/phone/01.png')\n          ",arg:"background-image"}],staticClass:"swiper-slide bg01"}),_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(
            require('../../../assets/20240109/phone/02.png')
          ),expression:"\n            require('../../../assets/20240109/phone/02.png')\n          ",arg:"background-image"}],staticClass:"swiper-slide bg02"}),_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(
            require('../../../assets/20240109/phone/03.png')
          ),expression:"\n            require('../../../assets/20240109/phone/03.png')\n          ",arg:"background-image"}],staticClass:"swiper-slide bg03"}),_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(
            require('../../../assets/20240109/phone/04.png')
          ),expression:"\n            require('../../../assets/20240109/phone/04.png')\n          ",arg:"background-image"}],staticClass:"swiper-slide bg04"}),_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(
            require('../../../assets/20240109/phone/05.png')
          ),expression:"\n            require('../../../assets/20240109/phone/05.png')\n          ",arg:"background-image"}],staticClass:"swiper-slide bg05"}),_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(
            require('../../../assets/20240109/phone/06.png')
          ),expression:"\n            require('../../../assets/20240109/phone/06.png')\n          ",arg:"background-image"}],staticClass:"swiper-slide bg06"}),_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(
            require('../../../assets/20240109/phone/07.png')
          ),expression:"\n            require('../../../assets/20240109/phone/07.png')\n          ",arg:"background-image"}],staticClass:"swiper-slide bg07"}),_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(
            require('../../../assets/20240109/phone/08.png')
          ),expression:"\n            require('../../../assets/20240109/phone/08.png')\n          ",arg:"background-image"}],staticClass:"swiper-slide bg08"})]),_c('div',{staticClass:"swiper-pagination"})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top"},[_c('img',{staticClass:"img-w-1",attrs:{"src":require("../../../assets/20240109/shoujibeijing.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._v("Full-featured Navigation App")]),_c('div',{staticClass:"txt-1"},[_vm._v(" The Blucap App integrates map SDKs from "),_c('span',{staticClass:"border"},[_vm._v("Google Maps")]),_vm._v(" and "),_c('span',{staticClass:"border"},[_vm._v("MapBox")]),_vm._v(". It includes all the essential features for riding and maintaining a classic and simple user interface. ")])])
}]

export { render, staticRenderFns }