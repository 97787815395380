// en.js
export default {
    message: {
        headers: {
            Moto: 'Moto',
            Prescription: '處方鏡片',
            Replacement_Lenses: '更換鏡片',
            Blucap_Go: 'Blucap Go',
            News: '新聞',
            About_Us: '關於我們',
            Dealer: '經銷商'
        },
        buttons: {
            button1: '預購',
            // button2:'現在預訂！',
            // button3:'現在預訂！',
            button2: 'Kickstarter',
            button3: 'Buy Now',
        },
        index: {
            //tips:'建議售價：$399。即將推出 Kickstarter 活動。限時預約，確保享受 25% 折扣的早鳥優惠價格。',
            //tips:'建議售價：399美元，即將開啟Kickstarter群募',//0928
            tips: '已在Kickstarter上推出！限時享受20%特别折扣！',//1003
            meet: '全球首創的摩托車導航太陽眼鏡，沉浸在每一個騎行時刻中',
            subscribe: '訂閱',
            subscribe_for: '訂閱優惠活動！',
            email_placeholder: '電子郵件地址',
            thanks_for: '感謝您的訂閱！',
            thanks_error: '"訂閱失敗',
            pleass_input: '"請輸入正確的電子郵件地址',
            watch_video: '觀看影片',
            most_cutting: '最先進的AR技術',
            only_the: ' 只有運用最尖端的技術，才能滿足Blucap對設計和性能的高要求',
            innovation: 'Blucap™ 創新光波導鏡片',
            customized: 'Blucap™ 的定制專用光波導鏡片在高透明度、防霧能力、抗眩光反射和處方選項等方面表現卓越。',
            smallest: '全球最小的MicroLED顯示屏',
            temples: ' 全球最小的0.13英寸MicroLED顯示屏，高亮度，極低功耗，使得這款眼鏡的外觀與普通眼鏡相同',
            navigation_map: '導航地圖',
            navigation_map_seamless: ' 在視線內體驗無縫導航，實時地圖隨時可用。輕鬆使用把手上的遙控器來控制，滿足您在複雜路況下的所有導航需求。這就是為什麼我們稱它們為導航眼鏡。',
            turn_directions: '轉彎指示',
            turn_directions_seamlessly: 'Blucap Moto在轉彎時會自動顯示轉向指示器。通過路口後，它會無縫地過渡到無干擾的普通太陽眼鏡模式。',
            technology: '科技隱藏在反科技設計之中',
            technology_seamlessly: '這副眼鏡沒有按鈕、LED燈或USB插孔；僅有永恆的優雅與隱形的創新相融。',
            power: '即時開啟/關閉电源',
            power_seamlessly: '只需打開太陽眼鏡的腿架即可開啟電源，自動連接到您的手機。關閉眼鏡以關閉電源。',
            shiftLock: 'ShiftLock™ 快速釋放設計',
            shiftLock_seamlessly: 'Blucap獨家的ShiftLock™快速釋放設計，方便更換。滿足夜間騎行、有色更換鏡片和處方眼鏡的需求。',
            polarized: '偏光',
            anti_fog: '防霧',
            water: '防水和防油污',
            anti_scratch: '防刮',
            protection: '100% UVA/UVB 保護',
            performance: '高性能眼鏡架',
            contrast: '對比度增強',
            definition: '高清晰度',
            message: '簡訊',
            call: '來電',
            alert: '速度警示',
            stay_informed: '使用Blucap Moto的實時來電、訊息和限速警報，保持資訊不漏接，專注於道路。在騎行過程中保持安全和專注，不會錯過重要通知。',
            easy_to_use: '易於使用且功能齊全的導航',
            easy_to_use_seamlessly: 'Blucap Moto 透過藍牙連接至 Blucap App 進行導航，充分利用您手機的 GPS 功能。',
            it_contains_all: '它包含了騎行所需的所有功能，例如添加多個停靠點、路線選擇，以及避開交通的選項。',
            smart_Routing: '智慧路線規劃，驚喜的冒險',
            set_your_direction: '設定您的方向和距離，生成史詩般的來回旅程。進一步自訂您的路線，避開特定道路或調整彎曲度，打造獨一無二的個性化騎行體驗。',
            basic_features: '',
            voice_commands: '語音指令',
            perfectly_compatible: '完美兼容您自己的藍牙耳機，支持語音控制、免提通話等功能。',
            handlebar_remote_control: '把手遙控控制',
            compatibility: '與所有摩托車型號兼容',
            waterproof: '防水',
            built_to_withstand: '精心設計以承受衝擊和暴露於雨水中',
            battery_life: '電池續航',
            powered_by_a_button: '由按鈕電池供電，長達六個月無需更換。',
            full_featured_navigation: '時尚騎行',
            full_featured_seamlessly: 'Blucap Moto呈現了一系列時尚高質的鏡片選擇，讓您在騎行中展現個性和優越的光學性能。',
            lenses: '處方鏡片',
            lenses_seamlessly: '移除外部太陽眼鏡鏡片，將您的Blucap Moto變成具有抬頭顯示（HUD）功能的普通眼鏡！',
            contact: '與我們聯係: support@blucapsport.com',
            copyright: 'Copyright @ 2023. All rights reserved.',
            privacy_policy: '隱私政策'
        },
        lenses: {
            replacement: 'Blucap Moto的ShiftLock™更換鏡片',
            money: '$49.00',
            sunglasses: 'Blucap獨特的ShiftLock™多功能太陽眼鏡更換鏡片，在強光下提供清晰視野，為每一次旅程增添了無限可能。',
            polarization: '偏光',
            bayer_level: '拜耳 7 級防刮耐損',
            oil_water: '油水抗潑塗層',
            anti_fog: '防霧',
            protection: '100% UVA/UVB 防護',
            contrast: '對比度增強',
            photochromic_lens: 'Blucap獨特的ShiftLock™光致變色鏡片可以在幾秒內切換至不同的顏色。            ',
            light: '光線透過率超寬範圍：80% - 10%',
            color: '顏色',
            Navy_Blue: '深藍色',
            Dark_Green: '深綠色',
            Dark_Grey: '深灰色',
            Tea_Brown: '茶褐色',
            Mirrored_Yellow: '鏡面黃色',
            Mirrored_Green: '鏡面綠色',
            Mirrored_Red: '鏡面紅色',
            Mirrored_Silver: '鏡面銀色',
            Mirrored_Blue: '鏡面藍色',
            Black: '黑色',
            Photochromic: '光致變色',
            coming_soon: '即將到來',
            care_instructions: '保養說明',
            gentle_cleaning: '溫和清潔：使用溫水和溫和的清潔劑輕輕清潔您的處方鏡片。避免使用含有酒精、強酸或強鹼的清潔劑，因為它們可能會損壞塗層或框架材料。',
            special_cloth: '特殊布：使用提供的專用清潔布輕輕擦拭鏡片。避免使用普通紙巾或衣物，以免刮傷鏡片表面。',
            avoid_scratches: '避免刮傷：不佩戴眼鏡時，請將它們放入專用的眼鏡盒中，以防止鏡片刮傷或受到污染。',
            avoid_high: '避免高溫：將眼鏡遠離高溫環境，如熱車內或直射陽光，以防止鏡片或框架受損。',
            no_saliva: '避免使用唾液、家用清潔劑或普通衣物來清潔鏡片，因為這可能會導致污漬或損壞。',
            regular_inspection: '定期檢查鏡片是否有刮痕或損壞。如果發現任何問題，請立即聯繫專業的眼鏡店。',
            avoid_chemical: '避免接觸化學物質：防止眼鏡接觸強酸、強鹼、化學清潔劑等物質，以避免損壞塗層或鏡片。',

        },
        prescription: {
            prescription_lenses: 'Blucap Moto 處方鏡片',
            money: '$209.00',
            order_for: '您的處方鏡片訂單將在購買日期後的6-8週內完成並寄出。',
            experience: '如果您希望使用處方鏡片體驗Blucap Moto，您可以另外訂購處方鏡片。',
            smart_glasses: '*智能眼鏡不包含在內，必須另行購買。',
            prescription_information: '處方信息填寫',
            how_to_read: '如何閱讀您的處方？',
            right_sphere: 'Right sphere',
            select: '選擇',
            left_sphere: 'Left sphere',
            right_cylinder: 'Right cylinder',
            none: 'None',
            left_cylinder: 'Left cylinder',
            right_axis: 'Right axis',
            left_axis: 'Left axis',
            right_pd: 'Right PD',
            left_pd: 'Left PD',
            pd: 'PD',
            have_2pd: '我有兩個PD',
            the_AXIS: '您插入的AXIS數值不正確！這些數值必須在1到180之間',
            remark: '備註',
            type_your: '在這裡輸入信息',
            i_confirm: '我確認我的處方的有效性和準確性，確保它符合我的生理需求。我也承認，我不會提出任何關於與處方不符的信息或規格的任何索賠或請求。',
            i_acknowledge: '我確認所提供的書面規格，源自處方，是完成訂單的必要條件。我也理解並同意Blucap可將我的處方詳細信息與其第三方供應商和合作夥伴分享，僅用於處理交易並履行我的訂單之目的。',
            i_understand: '在下訂單後，專業驗光師將與您聯繫，確認您的處方信息。一旦處方信息確認，將無法退款。',
            i_ifthere: '如果由於與Blucap相關的原因導致運送延遲，我們將立即與您聯繫。您可以選擇繼續等待或請求退款。',
            coming_soon: '即將到來',
            understanding: '了解您的處方驗光單',
            right_eye: '右眼 (OD)',
            stands: '"OD" 代表 "oculus dexter"，拉丁文中意为 "右眼"',
            left_eye: '左眼 (OS)',
            left_stands: 'OS 指的是「左眼」,拉丁文為 oculus sinister',
            sphere: 'Sphere (SPH)- 也稱為度數 (PWR) ',
            they_mean: '他們是一樣的意思: 你的度數強度。 如果你近視的話,你的數值前面會有減號(-),如果你遠視的話,你的數值前面會有加號(+)',
            cyl: 'Cylinder (CYL)',
            people: '這是針對有散光的人。這是一種眼睛的一部分需要比其他部分更多的校正的情形。散光度數是寫成帶有減號(-)的。 ',
            axis: 'Axis',
            this_is_only: 'Axis只是針對有散光的人。軸位是一個在0到180之間的數字,用來決定散光度數(CYL)的方向。',
            oK: '好的',

        },
        news: {
            blucap_unveils: 'Blucap 推出正式版 Blucap Moto 機車導航太陽眼鏡',
            the_blucap_team: 'Blucap團隊,2023年9月13日',
            september_13: '[2023年9月13日]-創新智能眼鏡品牌Blucap宣布推出Blucap Moto導航太陽眼鏡,為機車愛好者創造了新的導航和騎行體驗方式。',
            motorcycle: '革命性的機車導航',
            blucap_moto_is: ' Blucap Moto是一款在騎士視線前方直接顯示資訊的導航設備。它提供零分心的體驗,彎道箭頭僅在需要時出現。作為導航眼鏡,Blucap Moto以其地圖導航功能突出,可以通過車把遙控器控制,滿足您在複雜路況下的所有導航需求。',
            in_addition: '此外,Blucap Moto通過藍牙與手機連接,在使用導航功能時無需保持手機屏幕打開。騎士可以選擇將手機放在包或口袋裡,避免了在支架上安裝手機的不便,以及潛在的攝像頭鏡頭震動損壞、雨水損壞、手機過熱和電量消耗等問題。 ',
            with_blucap_moto: ' 有了Blucap Moto,騎士可以安全無分心地騎行,沉浸在騎行體驗中,無需低頭看手機。',
            navigation_display: '導航顯示',
            blucap_has_developed: 'Blucap已經開發出了一款功能全面、專為機車騎士設計的導航App。這款App提供了多條路線選擇、添加多個停靠點以及避開交通的選項等功能。它可以取代市面上主流的導航應用程序。',
            app_functionality: '基礎APP功能',
            blucap_has_designed: 'Blucap設計了一個智能路線生成功能,讓騎士可以獲得由算法生成的推薦騎行路線。該路線基於騎士的個人偏好產生,包括大致的騎行方向和距離,以及是否偏好彎路或景點。這項功能為騎士提供了個性化且愉悅的騎行體驗',
            basic_features: '',
            app_advanced: 'App功能 - 高級',
            design: '設計卓越,穿戴體驗極佳 ',
            according_to_blucap: ' 根据Blucap的設計理念,智能眼鏡首先應提供舒適的穿戴體驗,就像普通眼鏡一樣。與其他智能眼鏡或AR眼鏡不同,Blucap Moto結構輕巧,外觀與普通太陽眼鏡無異。細長的鏡架和末端柔韌的材料,使其可以舒適地佩戴在安全帽下。',
            the_blucap_design: 'Blucap設計團隊為電子功能互動定義了「反科技」設計。這款眼鏡沒有任何按鈕、LED指示燈或USB接口。從外觀來看,它們看起來就像一副樸實無華的經典款太陽眼鏡,但實際上內含尖端的AR技術。',
            when_unfolding: '摺疊開啟並佩戴眼鏡時,它們會自動通電並通過藍牙與手機連接。這使得日常使用就像佩戴和移除普通眼鏡一樣簡單,沒有任何多餘的步驟。',
            power_on: '打開電源',
            the_charging: '充電LED和低頻按鈕已經被重新定位到磁性充電座上,這導致眼鏡上的電子元件更少。這項設計變更進一步減少了眼鏡的重量和功耗。Blucap還開發了一項獨特的設計功能,名為ShiftLockTM,它使外部鏡片可以快速輕鬆地替換為不同的光線透射等級。這使其可以適應夜間騎行並用作近視眼鏡。偏光和 photochromic鏡片也可單獨購買。',
            release: 'ShiftLock™ 快速拆卸設計',
            customized: '定制化的處方鏡片 ',
            traditional_motorcycle: '傳統的機車太陽眼鏡使用RX 配接器來配備處方鏡片。這不僅外觀不佳,也會增加眼鏡的重量。但是,Blucap已設計並建立了一個獨特的供應商系統,可以通過升級服務定制處方鏡片。這意味著 Blucap Moto可以配備處方鏡片,而無需改變外觀或添加 RX 配接器。這為騎士提供了有吸引力且輕巧的解決方案。',
            prescription_lenses: '處方鏡片',
            cutting_edge: '尖端AR技術',
            focused: 'Blucap 兩年前就聚焦於尖端的微型 LED 和波導鏡片作為其光學解決方案,以避免傳統 AR 眼鏡笨重的形狀、沉重的重量和高耗電量,以及其粗厚的鏡腿。這是唯一能夠滿足 Blucap 品牌在眼鏡外觀和重量方面的設計要求的方法。Blucap Moto採用JBD的0.13寸微型LED,這是全球最小、最亮的顯示器。由它組成的光引擎只有鉛筆末端橡皮擦的大小,可以隱蔽地植入眼鏡腿的根部。與其他AR視覺解決方案相比,它不僅體積小不侵占視野,還具有高亮度和低功耗的優點。',
            microled: 'Blucap Moto採用了微型LED光引擎技術',
            uniquely: 'Blucap Moto獨特地設計了繞射波導鏡片以適應實際的騎行場景。與普通波導鏡片相比,這種波導鏡片能夠滿足複雜的光學性能要求,如高透明度、高亮度傳輸、防霧、防眩光反射以及處方定制。在AR供應商和生產合作夥伴的大力支持下,Blucap Moto已在量產前完成了關鍵技術確認的準備。',
            sales_plan: '銷售計劃 ',
            official: 'Blucap Moto的官方零售價為399美元。經銷商計劃正在進行中,有意成為Blucap經銷商的人可以通過填寫官方經銷商申請表申請。9月底,Blucap Moto將在Kickstarter上推出項目,提供預購優惠價格。更多詳情,請關注後續的Kickstarter頁面。',
            fully: 'Blucap Moto雖然還未正式發布,但在過去3個月內,它已在Facebook、Instagram等社交媒體平台上聚集了超過26,000名粉絲。1個月前才開始的經銷商計劃已收到了全球200多份申請。申請人包括來自北美、歐洲和亞洲的機車經銷商、設備商店、定制店、眼鏡商店以及各種網絡渠道和個人。全球各地騎手對Blucap Moto這款創新產品的強烈興趣和期待,證明了它的成功潛力。 ',
            market_resonance: '市場反響 ',
            product_key: '產品關鍵特性',
            real_time: '導航:實時HUD地圖導航,轉向提示。戴上眼鏡時通過藍牙與手機連接,利用手機的計算能力和GPS硬件;連接和使用期間手機可以保持鎖定。 ',
            other_hud: '其他HUD功能:速度、超速提示、來電提示和消息提示。',
            glasses_appearance: '普通眼鏡外觀,完全兼容全頭頭盔,反科技設計:眼鏡框架上沒有按鈕、LED燈或USB接口',
            external_lens: 'ShiftLock™ 外置鏡片快速拆卸設計,適合夜間騎行。 ',
            replacement_lenses: '可選更換鏡片:10種顏色偏光鏡片和光變鏡片,全部具有100% UVA/UVB防護,防霧,抗刮花,疏水和抗油塗層,以及對比度增強。',
            inner_lens: '內置鏡片支持訂製處方鏡片。用戶購買Blucap Moto時,可以在官網下單時同時提交處方資訊。',
            voice_control: '語音控制功能可以通過遙控器啟動,並與其他品牌的藍牙耳機兼容。 ',
            nose_pad: '鼻托有M號和L號兩種尺寸可供更換。',
            comes_with_a: 'Blucap Moto配備了磁吸式充電轉接頭,方便充電。',
            light_engine: '超低功耗微型LED光引擎和計算芯片,支持全天騎行導航(平均導航使用時長超過10小時)。',
            remote_control: '遙控器通過藍牙與手機App連接。配備多種安裝配件,支持各種車把類型。',
            about_blucap: '關於Blucap',
            about_us: '關於我們→',
            this_article: '文章正文',
            copy_text: '復製文章',
            download_all: '下載所有照片',
            zip569: 'ZIP | 56.9MB',
            advertisement: 'Advertisement_Video_EN.mp4',
            mp4106: 'MP4 | 106MB',
            vertical_advertisement: '30s_Vertical_Advertisement_Video_EN.mp4',
            mp4378: 'MP4 | 37.8MB',
            cg: 'CG_QuickShift_Video.mp4',
            mp447: 'MP4 | 4.7MB',
            cg_auto: 'CG_AutoConnection_Video.mp4',
            mp445: 'MP4 | 4.5MB',
            for_more: 'For more information, please contact:',
            zhaot: 'Zhao Tingting',
            brand_marketing: 'Brand Marketing',
            ztt: 'ztt@blucapsport.com',
            b_m: 'Blucap media',
            mb: 'media@blucapsport.com',
            b_m_img: 'Blucap Moto Image 1',
            b_m_pic: 'Blucap Moto Model Picture 1',
            b_m_color: 'Blucap Moto Color Lenses Image 1',
            b_m_unique: 'Blucap Moto\'s Unique Lenses',
            b_m_factor: 'Blucap Moto Factory Photo',
            press_contacts: 'Press Contacts',
        },
        about: {
            us: '關於我們',
            starting: '從願望出發',
            initially: '朱寧最初在騎行時使用手機導航遇到問題,無法找到理想的機車HUD產品。因此,他開始通過DIY努力創建自己的HUD設備。',
            journey: '旅程的開始',
            zhu_ning: '2021年,朱寧創建了Blucap,聚集了一個由熱愛騎行的工程師、設計師、眼鏡專家和地圖系統專家組成的團隊。我們對技術創新和品味設計的熱忱驅動我們不斷推動機車騎行體驗的可能性邊界。',
            built: '耐心打造',
            throughout: '在我們三年來探索第一款產品Blucap Moto的過程中,我們:',
            bravely: '勇敢選擇尖端AR技術,配合供應鏈的大規模成熟生產。 ',
            spent: '花費了半年多的時間探索和驗證一種可以平衡HUD騎行交互、眼鏡體積以及功耗需求的技術解決方案。',
            redesigned: '重新設計了整副眼鏡,使鏡片的快速拆卸設計更可靠,視覺體驗更好,即使這意味著推遲產品發佈超過半年。',
            continuously: '我們不斷通過團隊騎行員和全球測試用戶的反饋測試並改進產品。我們調整了眼鏡原型超過100次,持續優化軟件系統。',
            reliability_0: '可靠性,專業性 ',
            reliability_1: ', 與 ',
            reliability_2: '真誠 ',
            reliability_3: '是Blucap的核心價值觀。我們注重產品的每一個細節,耐心打磨每一個環節以達到最好。歡迎加入Blucap。創新與公路的邂逅。',
            hq: 'Hangzhou HQ',
            dream: 'No.22 Dream Town Hangzhou, ZJ 311100, P.R.C',
            support: 'support@blucapsport.com',
            hk: 'Hong Kong',
            ug: 'Unit 2A, 17/F, Glenealy Tower, No.1 Glenealy Central',
            zb: 'zn@blucapsport.com',
            portland: 'Portland',
            upcoming: 'Upcoming'

        }
    }
}