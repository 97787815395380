<template>
  <div class="all" @touchend="playVideo('last')">
    <div class="zz"></div>
    <div class="four">
      <video
        playsInline
        loop
        muted
        preload="metadata"
        class="video_1"
        id="last"
      >
        <source
          src="https://blk001.oss-us-west-1.aliyuncs.com/last.mp4"
          type="video/mp4"
        />
      </video>
    </div>
    <div class="content">
      <div class="title">{{ $t('message.index.outer_title') }}</div>
      <div class="txt-1">
        {{ $t('message.index.outer_content') }}
      </div>
      <div class="senvenbtn">
        <button01 class="m-t-007" :tab="tab1" />
      </div>
    </div>
  </div>
</template>

<script>
import button01 from '@/components/com/button01.vue' //头部
export default {
  components: {
    button01,
  },
  data() {
    return {
      tab1: 2,
    }
  },
  methods: {
    playVideo(ids) {
      var myvideo = document.getElementById(ids)
      myvideo.play()
    },
  },
}
</script>
<style lang="less" scoped>
.all {
  width: 100%;
  padding-bottom: 0.6rem;
  position: relative;
  height: 7.2rem;
  .zz {
    position: absolute;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50.68%, #000 100%);
    width: 100%;
    height: 4rem;
  }

  .four {
    width: 100%;

    .video_1 {
      width: 100%;
      display: block;
      height: 4rem;
      z-index: -1;
      // opacity: 0.6;
      object-fit: cover;
    }
  }
  .content {
    width: 3rem;
    // margin: 0 auto;
    // margin-top: -0.2rem;
    position: absolute;
    left: 0.3rem;
    text-align: left;
    top: 2.95rem;
    z-index: 10;
    .title {
      font-size: 0.42rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: #fff;
    }
    .txt-1 {
      color: rgba(255, 255, 255, 0.7);
      /* Mobile-text1 */
      font-family: League Spartan;
      font-size: 0.21rem;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 25.2px */
      margin-top: 0.24rem;
      margin-bottom: 0.25rem;
    }
  }
}
</style>
