<template>
  <div class="all" @touchend="playVideo('video3')">
    <div class="four">
      <video
        playsInline
        loop
        muted
        preload="metadata"
        class="video_1"
        id="video3"
      >
        <source
          src="https://blk001.oss-us-west-1.aliyuncs.com/Navigation_map.mp4"
          type="video/mp4"
        />
      </video>
    </div>

    <div class="five">
      <div class="five-title">{{ $t('message.index.navigation_map') }}</div>
      <div class="five-txt-1 color-07">
        {{ $t('message.index.navigation_map_seamless') }}<br />
        That's why we call them <span class="border">navigation glasses</span>.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    playVideo(ids) {
      var myvideo = document.getElementById(ids)
      myvideo.play()
    },
  },
}
</script>

<style lang="less" scoped>
.all {
  width: 100%;
  .four {
    width: 100%;

    .video_1 {
      width: 100%;
      display: block;
      height: 2.3rem;
      z-index: -1;
      // opacity: 0.6;
      object-fit: cover;
    }
  }
  .five {
    background-color: #000;
    padding: 0.35rem 0.3rem 0.6rem 0.3rem;
    font-family: League Spartan;
    color: #fff;
    text-align: left;

    .five-title {
      font-size: 0.42rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .five-txt-1 {
      font-size: 0.21rem;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      margin-top: 0.24rem;
      color: rgba(255, 255, 255, 0.7);
      .border {
        font-weight: 500;
        color: #fff;
      }
    }

    .m-t-0 {
      margin-top: 0rem;
    }
  }
}
</style>
