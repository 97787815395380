<template>
  <div class="all">
    <div
      class="w-8-0"
      v-lazy:background-image="require('../../../assets/20230905/index008.png')"
    >
      <div class="w-8-2">
        <div class="content01">
          <div class="title">
            {{ $t('message.index.lenses') }}
          </div>
          <div class="txt-1 color-07">
            {{ $t('message.index.lenses_seamlessly') }}
          </div>
          <button01 class="m-t-007" :tab="tab" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import button01 from '@/components/com/button01.vue' //头部
export default {
  components: {
    button01,
  },
  data() {
    return {
      tab: 1,
    }
  },
}
</script>

<style lang="less" scoped>
.all {
  width: 100%;
  .w-8-0 {
    width: 100%;
    max-width: 2560px;
    background-repeat: no-repeat;
    background-size: cover;
    height: 820px;
    position: relative;
    background-position: center;
    margin: 0 auto;
    .w-8-2 {
      width: 1920px;
      margin: 0 auto;

      //position: relative;
      .m-t-007 {
        margin-top: 36px;
      }
      .content01 {
        width: 820px;
        color: #fff;
        margin-left: 270px;
        position: absolute;
        bottom: 90px;

        .title {
          font-family: League Spartan;
          font-size: 42px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-align: left;
        }

        .txt-1 {
          font-family: League Spartan;
          font-size: 21px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          text-align: left;
          margin-top: 10px;
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }
  }
}
</style>
