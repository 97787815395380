// en.js
export default {
    message: {
        headers: {
            Moto: 'Moto',
            Prescription: 'Prescription',
            Replacement_Lenses: 'Lentilles de rechange',
            Blucap_Go: 'Blucap Go',
            News: 'Actualités',
            About_Us: 'A propos de Nous',
            Dealer: 'Concessionnaire'
        },
        buttons: {
            button1: 'Pré-commande',
            // button2:'Réservez maintenant !',
            // button3:'Réservez maintenant !',
            button2: 'Kickstarter',
            button3: 'Buy Now',
        },
        index: {
            //tips:'Lancement de Kickstarter en fin septembre. Réservez dès maintenant pour bénéficier d\'une réduction de 25 % sur le prix.',
            //tips:'PDSF:399 dollars. Campagne Kickstarter bientôt lancée.',//0928
            tips: 'En direct sur Kickstarter ! Temps limité pour bénéficier de la remise spéciale de 20 % !',//1003
            meet: 'Découvrez les premières lunettes de navigation pour moto au monde, qui vous plongent dans chaque instant de la conduite.',
            subscribe: 'S\'abonner',
            subscribe_for: 'S\'Abonnez-vous aux offres !',
            email_placeholder: 'Adresse e-mail',
            thanks_for: 'Merci de vous être abonné !',
            thanks_error: 'Échec de l\'abonnement',
            pleass_input: 'Veuillez saisir la bonne adresse électronique',
            watch_video: 'Regarder la vidéo',
            most_cutting: 'La technologie AR la plus avancée',
            only_the: ' Seule l\'utilisation d\'une technologie de pointe peut répondre aux exigences élevées de Blucap en matière de conception et de performance.',
            innovation: 'Lentille à guide d\'ondes Blucap™ Innovation',
            customized: 'La lentille à guide d\'ondes personnalisée et spécialisée de Blucap excelle par sa grande transparence, ses capacités antibuée, sa réflexion antireflet et ses options de prescription, entre autres caractéristiques.',
            smallest: 'Le plus petit écran MicroLED du monde',
            temples: ' Le plus petit écran MicroLED de 0,13 pouce au monde, avec une luminosité élevée et une consommation d\'énergie ultra-faible, rend les lunettes aussi performantes que des lunettes ordinaires.',
            navigation_map: 'Carte de navigation',
            navigation_map_seamless: ' Faites l\'expérience d\'une navigation transparente avec une carte en temps réel dans votre champ de vision. Contrôlez-la sans effort à l\'aide d\'une télécommande sur le guidon, pour répondre à tous vos besoins de navigation dans des conditions routières complexes. C\'est pourquoi nous les appelons lunettes de navigation.',
            turn_directions: 'Indications de virage',
            turn_directions_seamlessly: 'Blucap Moto affiche automatiquement les clignotants lors des virages. Une fois les intersections franchies, les lunettes passent en toute transparence à l\'état de lunettes de soleil normales, sans distraction.',
            technology: 'Technologie dissimulée dans un design anti-technologique.',
            technology_seamlessly: 'Des lunettes sans boutons, diodes électroluminescentes ou prises USB : l\'élégance intemporelle rencontre l\'innovation invisible.',
            power: 'Mise en marche et arrêt instantanés',
            power_seamlessly: 'Il suffit d\'ouvrir les branches des lunettes de soleil pour les allumer et se connecter automatiquement à votre téléphone. Fermez les lunettes pour les éteindre.',
            shiftLock: 'Conception à dégagement rapide ShiftLock™',
            shiftLock_seamlessly: 'La conception exclusive ShiftLock™ de Blucap, à dégagement rapide, permet un remplacement facile. Répond aux besoins de la conduite de nuit, des lentilles de remplacement colorées et des lunettes de prescription.',
            polarized: 'Polarisées',
            anti_fog: 'Anti-buée',
            water: 'Hydrofuge et oléofuge',
            anti_scratch: 'Anti-rayures',
            protection: 'Protection à 100 % contre les UVA/UVB',
            performance: 'Montures haute performance',
            contrast: 'Amélioration des contrastes',
            definition: 'Haute définition',
            message: 'Message',
            call: 'Appel',
            alert: 'Alerte',
            stay_informed: 'Restez informé et concentré sur la route grâce aux alertes d\'appel, de message et de limite de vitesse en temps réel de Blucap Moto. Ne manquez jamais les notifications importantes tout en maintenant votre sécurité et votre concentration pendant votre trajet.',
            easy_to_use: 'Navigation facile à utiliser et complète',
            easy_to_use_seamlessly: 'Blucap Moto se connecte à l\'application Blucap via le Bluetooth pour la navigation, en utilisant pleinement les capacités matérielles GPS de votre téléphone intelligent.',
            it_contains_all: 'Il contient toutes les fonctions nécessaires à la conduite, telles que l\'ajout d\'arrêts multiples, les options d\'itinéraire et la possibilité d\'éviter le trafic.',
            smart_Routing: 'Itinéraire Intelligent, Aventure Inattendue',
            set_your_direction: 'Définissez votre direction et votre distance pour générer des trajets aller-retour épiques. Personnalisez encore davantage votre itinéraire en évitant certaines routes ou en ajustant la courbure, pour créer une expérience de conduite sur mesure, unique pour vous.',
            basic_features: '',
            voice_commands: 'Commandes vocales',
            perfectly_compatible: 'Parfaitement compatible avec votre propre casque Bluetooth pour la commande vocale, les appels mains libres et bien plus encore.',
            handlebar_remote_control: 'Télécommande du guidon',
            compatibility: 'Compatibilité avec tous les modèles de moto.',
            waterproof: 'Étanchéité',
            built_to_withstand: 'Conçu pour résister aux chocs et à l\'exposition à l\'eau.',
            battery_life: 'Durée de vie de la Batterie',
            powered_by_a_button: 'Alimenté par une batterie bouton, il ne nécessite aucun remplacement pendant une période pouvant aller jusqu\'à six mois.',
            full_featured_navigation: 'Roulez avec style',
            full_featured_seamlessly: 'Blucap Moto présente une prochaine gamme d\'options de lentilles élégantes et de haute qualité, vous permettant de rouler avec une touche d\'individualité et une performance optique exceptionnelle.',
            lenses: 'Lentilles de prescription',
            lenses_seamlessly: 'Enlevez les lentilles extérieures des lunettes de soleil pour transformer votre Blucap Moto en lunettes normales avec des capacités d\'Affichage Tête Haute (HUD) !',
            contact: 'contact : support@blucapsport.com',
            copyright: 'Droit d\'auteur @ 2023. Tous droits réservés.',
            privacy_policy: 'Politique de confidentialité'
        },
        lenses: {
            replacement: 'Lentilles de remplacement ShiftLock™ pour Blucap Moto',
            money: '$49.00',
            sunglasses: 'Les lentilles de remplacement de lunettes de soleil uniques ShiftLock™ Multi-fonctionnel de Blucap offrent une vue nette sous une forte lumière, et ajoutent des possibilités infinies à chaque voyage',
            polarization: 'Polarisation',
            bayer_level: 'Résistance aux rayures de niveau 7 de Bayer',
            oil_water: 'Revêtement hydrofuge et oléofuge',
            anti_fog: 'Anti-buée',
            protection: '100 % de protection UVA/UVB',
            contrast: 'Amélioration du contraste',
            photochromic_lens: 'La lentille photochromique ShiftLock™ unique de Blucap permet de passer d\'une lentille à l\'autre en quelques secondes. ',
            light: 'Transmittance lumineuse très large : 80% - 10%',
            color: 'Couleur',
            Navy_Blue: 'Bleu marine',
            Dark_Green: 'Vert foncé',
            Dark_Grey: 'Gris foncé',
            Tea_Brown: 'Brun thé',
            Mirrored_Yellow: 'Jaune miroir',
            Mirrored_Green: 'Vert miroir',
            Mirrored_Red: 'Rouge miroir',
            Mirrored_Silver: 'Argent miroir',
            Mirrored_Blue: 'Bleu miroir',
            Black: 'Noir',
            Photochromic: 'Photochromique',
            coming_soon: 'Bientôt disponible',
            care_instructions: 'Instructions d\'Entretien',
            gentle_cleaning: 'Nettoyage en Douceur : Utilisez de l\'eau tiède et un détergent doux pour nettoyer délicatement vos lentilles de prescription. Évitez d\'utiliser des nettoyants contenant de l\'alcool, des acides forts ou des alcalis, car ils peuvent endommager les revêtements ou les matériaux de la monture.',
            special_cloth: 'Chiffon spécial : Utilisez le chiffon de nettoyage spécial fourni pour essuyer délicatement les lentilles. Évitez d\'utiliser des mouchoirs ou des vêtements ordinaires, car ils risquent de rayer la surface des lentilles.',
            avoid_scratches: 'Évitez les rayures : Lorsque vous ne portez pas vos lunettes, rangez-les dans un étui à lunettes spécial pour éviter les rayures ou la contamination des lentilles.',
            avoid_high: 'Évitez les Températures Élevées : Gardez vos lunettes à l\'écart des environnements à haute température, tels que les voitures chaudes ou la lumière directe du soleil, pour éviter d\'endommager les lentilles ou les montures.',
            no_saliva: 'Pas de salive ni de vêtements : Évitez d\'utiliser de la salive, des produits d\'entretien ménager ou des vêtements ordinaires pour nettoyer les lentilles, car cela peut les salir ou les endommager.',
            regular_inspection: 'Inspection régulière : Inspectez régulièrement vos lentilles pour vérifier qu\'elles ne sont pas rayées ou endommagées. En cas de problème, contactez rapidement un magasin de lunettes professionnel.',
            avoid_chemical: 'Évitez le contact avec les produits chimiques : Évitez que vos lunettes n\'entrent en contact avec des acides forts, des alcalis forts, des nettoyants chimiques et des substances similaires afin de ne pas endommager les revêtements ou les lentilles.',

        },
        prescription: {
            prescription_lenses: 'Lentilles de prescription pour Blucap Moto',
            money: '$209.00',
            order_for: 'Votre commande pour l\'ordonnance sera exécutée et expédiée dans un délai de 6 à 8 semaines à compter de la date d\'achat.',
            experience: 'Si vous souhaitez utiliser Blucap Moto avec des lentilles de prescription, vous pouvez commander des lentilles de prescription séparément.',
            smart_glasses: '*Les lunettes intelligentes ne sont pas incluses et doivent être achetées séparément.',
            prescription_information: 'Informations sur la prescription',
            how_to_read: 'Comment lire votre prescription ?',
            right_sphere: 'Sphère droite',
            select: 'Sélectionner',
            left_sphere: 'Sphère gauche',
            right_cylinder: 'cylindre droite',
            none: 'Aucun',
            left_cylinder: 'cylindre gauche',
            right_axis: 'AXE droite',
            left_axis: 'AXE gauche',
            right_pd: 'DP droite',
            left_pd: 'DP gauche',
            pd: 'DP',
            have_2pd: 'J\'ai 2 numéros de DP',
            the_AXIS: 'The AXIS values you’ve inserted are not correct!These values must be between 1 and 180.',
            remark: 'Remarque',
            type_your: 'Saisissez votre message ici',
            i_confirm: 'Je confirme la validité et l\'exactitude de ma prescription, en m\'assurant qu\'elle correspond à mes besoins physiques. Je reconnais également que je ne ferai aucune réclamation ou demande concernant toute information ou spécification qui s\'écarterait de la prescription.',
            i_acknowledge: 'Je reconnais que les spécifications écrites fournies, qui découlent de la prescription, sont essentielles pour remplir la commande. Je comprends également et je donne mon accord à Blucap pour l\'utilisation et le partage des détails de ma prescription avec ses fournisseurs et partenaires tiers, exclusivement dans le but de traiter la/les transaction(s) et d\'exécuter ma commande.',
            i_understand: 'Après avoir passé une commande, un opticien professionnel vous contactera pour confirmer les informations relatives à votre prescription. Une fois les informations de l\'ordonnance confirmées, elles ne seront pas remboursables.',
            i_ifthere: 'En cas de retard de livraison pour des raisons liées à Blucap, nous vous contacterons dans les meilleurs délais. Vous pouvez choisir de continuer à attendre ou de demander un remboursement.',
            coming_soon: 'Bientôt disponible',
            understanding: 'Comprendre votre prescription',
            right_eye: 'Œil droit (OD)',
            stands: 'OD signifie "oculus dexter", ce qui signifie "œil droit" en latin.',
            left_eye: 'Œil gauche (OS)',
            left_stands: 'OS signifie "oculus sinister", ce qui signifie "œil gauche" en latin.',
            sphere: 'Sphère (SPH) - également connue sous le nom de puissance (PWR)',
            they_mean: 'Ces termes désignent la même chose : la force de votre prescription. Si vous êtes myope, vos valeurs seront précédées d\'un moins (-), et si vous êtes hypermétrope, elles seront précédées d\'un plus (+).',
            cyl: 'Cylindre (CYL)',
            people: 'Cette mesure est destinée aux personnes astigmates. C\'est le cas lorsqu\'une partie de l\'œil a besoin d\'une correction plus importante que le reste. La valeur du cylindre s\'écrit avec un signe moins (-).',
            axis: 'Axe',
            this_is_only: 'Cette valeur est réservée aux personnes astigmates. L\'axe est un nombre compris entre O et 180 et détermine l\'orientation du cylindre (CYL).',
            oK: 'OK',

        },
        news: {
            blucap_unveils: 'Blucap annonce officiellement le lancement de ses lunettes de navigation pour moto, Blucap Moto.',
            the_blucap_team: 'Équipe Blucap, 13 septembre 2023',
            september_13: '[September 13, 2023] – Blucap, la marque pionnière de lunettes intelligentes, annonce la sortie des lunettes de navigation Blucap Moto. Elles créent une nouvelle façon de naviguer et offrent une nouvelle expérience de conduite pour les passionnés de moto.',
            motorcycle: 'Une navigation révolutionnaire pour moto.',
            blucap_moto_is: ' Blucap Moto est un dispositif de navigation qui affiche des informations directement dans le champ de vision du conducteur. Il offre une expérience sans distraction, avec des flèches de direction qui n\'apparaissent que lorsque c\'est nécessaire. Blucap Moto se distingue par sa navigation cartographique adaptée aux conditions routières complexes.',
            in_addition: 'De plus, Blucap Moto se connecte aux téléphones via Bluetooth, éliminant le besoin de maintenir l\'écran du téléphone allumé lors de l\'utilisation de ses fonctionnalités de navigation. Les motards peuvent choisir de garder leur téléphone dans un sac ou une poche, évitant ainsi l\'inconvénient de monter le téléphone sur un support et les problèmes potentiels tels que les dommages causés par la vibration de l\'objectif de la caméra, la pluie, la surchauffe du téléphone et la consommation de la batterie.',
            with_blucap_moto: ' Avec Blucap Moto, les motards peuvent rouler en toute sécurité et sans distractions, profitant d\'une expérience de conduite immersive sans avoir besoin de regarder leur téléphone.',
            navigation_display: 'Affichage de la navigation',
            blucap_has_developed: 'Blucap a développé une application de navigation complète spécialement conçue pour les motocyclistes. Cette application offre des fonctionnalités telles que plusieurs options d\'itinéraire, la possibilité d\'ajouter plusieurs arrêts et l\'option d\'éviter le trafic. Elle peut remplacer les applications de navigation grand public disponibles sur le marché.',
            app_functionality: 'Fonctionnalités de base de l\'application',
            blucap_has_designed: 'Fonctionnalités de base de l\'application',
            basic_features: '',
            app_advanced: 'Fonctionnalités avancées de l\'application',
            design: 'Excellence en matière de design et d\'expérience de port',
            according_to_blucap: ' Selon la philosophie de conception de Blucap, les lunettes intelligentes doivent avant tout offrir une expérience de port confortable, tout comme les lunettes ordinaires. Contrairement à d\'autres lunettes intelligentes ou AR, la Blucap Moto est légère et a l\'apparence de lunettes de soleil ordinaires. Les branches minces et le matériau flexible à l\'extrémité les rendent faciles à porter confortablement sous un casque.',
            the_blucap_design: 'L\'équipe de conception Blucap a créé un design "Anti-Tech" pour l\'interaction des fonctions électroniques. Les lunettes n\'ont pas de boutons, de voyants LED ou de ports USB. De l\'extérieur, elles ressemblent à une paire de lunettes de soleil sobres et classiques, mais elles contiennent en réalité une technologie AR de pointe à l\'intérieur.',
            when_unfolding: 'Lorsqu\'on déplie et porte les lunettes, elles s\'allument automatiquement et se connectent à un téléphone portable via Bluetooth. Cela rend leur utilisation quotidienne aussi simple que de porter et retirer des lunettes normales, sans étapes redondantes.',
            power_on: 'Mise sous tension',
            the_charging: 'Le voyant de charge et le bouton basse fréquence ont été déplacés vers le dock de chargement magnétique. Cette modification de conception réduit le nombre de composants électroniques sur les lunettes, ce qui diminue leur poids et leur consommation d\'énergie. Blucap a également développé une fonctionnalité de conception unique appelée ShiftLock™, qui permet un remplacement rapide et facile des lentilles externes avec différents niveaux de transmission de la lumière. Cette fonctionnalité permet de s\'adapter à la conduite de nuit et à l\'utilisation comme lunettes de prescription. Des lentilles polarisées et photochromiques sont également disponibles à l\'achat.',
            release: 'Conception de libération rapide ShiftLock™',
            customized: 'Lunettes de Prescription Personnalisées',
            traditional_motorcycle: 'Certaines lunettes de moto et autres lunettes AR utilisent un adaptateur RX pour des lentilles de prescription. Cela non seulement semble peu attrayant, mais ajoute également du poids aux lunettes. Cependant, Blucap a conçu et mis en place un système unique de personnalisation de la prescription grâce à un service de mise à niveau. Cela signifie que Blucap Moto peut être équipé de verres de prescription sans altérer l\'apparence ou ajouter un adaptateur RX. Cette solution légère et attrayante offre une alternative pour les motocyclistes.',
            prescription_lenses: 'Lentilles de prescription',
            cutting_edge: 'La technologie AR la plus avancée',
            focused: 'Blucap s\'est concentré sur la technologie de pointe Micro LED et les lentilles à guide d\'ondes comme solution optique il y a deux ans, afin d\'éviter la forme volumineuse, le poids élevé et la consommation d\'énergie des lunettes AR traditionnelles, ainsi que leurs branches épaisses. C\'était la seule façon de satisfaire aux exigences de conception de la marque Blucap en matière d\'apparence et de poids des lunettes. Blucap Moto utilise le Micro LED de 0,13 pouce de JBD, qui est l\'affichage le plus petit et le plus lumineux au monde. Le moteur de lumière qui en est composé n\'est que de la taille d\'une gomme à effacer à l\'extrémité d\'un crayon, ce qui permet de l\'implanter discrètement à la base des branches des lunettes. Comparé à d\'autres solutions visuelles AR, il présente non seulement une petite taille qui ne gêne pas le champ de vision, mais aussi les avantages d\'une luminosité élevée et d\'une faible consommation d\'énergie.',
            microled: 'Blucap Moto et le moteur de lumière MicroLED',
            uniquely: 'Blucap Moto a conçu une lentille à guide d\'ondes diffractive unique pour répondre aux conditions réelles de conduite de moto. Ce type de lentille à guide d\'ondes peut répondre à des exigences de performance optique complexes, telles que la haute transparence, la haute luminosité, la résistance au brouillard, la réflexion anti-éblouissante et la personnalisation de prescription, par rapport aux lentilles à guide d\'ondes ordinaires. Avec un fort soutien des fournisseurs AR et des partenaires de fabrication, Blucap Moto a terminé la préparation de la confirmation technique clé avant la production de masse.',
            sales_plan: 'Plan de vente',
            official: 'Le prix de vente au détail officiel de Blucap Moto est de 399 $. Nous avons également un programme de concessionnaire en cours, et les personnes intéressées à devenir un concessionnaire Blucap peuvent postuler en remplissant le formulaire de demande officiel du concessionnaire. En septembre, Blucap Moto lancera un projet sur Kickstarter avec des prix spéciaux pour les premiers contributeurs. Pour plus de détails, restez à l\'écoute pour une page de suivi Kickstarter.',
            fully: 'Bien que Blucap Moto n\'ait pas encore été entièrement lancé, il a déjà rassemblé plus de 15 000 abonnés sur des plateformes de médias sociaux telles que Facebook et Instagram au cours des trois derniers mois. Le programme revendeur, lancé il y a seulement un mois, a reçu plus de 100 demandes du monde entier. Les demandeurs comprennent des concessionnaires de motos, des magasins d\'équipement, des ateliers de personnalisation, des magasins de lunettes et divers canaux en ligne et individus d\'Amérique du Nord, d\'Europe et d\'Asie. Cet intérêt et cette forte anticipation pour le produit innovant de Blucap Moto de la part de coureurs du monde entier démontrent son potentiel de succès.',
            market_resonance: 'Résonance du marché',
            product_key: 'Caractéristiques clés du produit',
            real_time: 'Navigation : Navigation par carte HUD en temps réel, indications de virage. Se connecte au téléphone par Bluetooth lorsqu\'il est porté comme lunettes, en utilisant la puissance de calcul du téléphone et le matériel GPS ; le téléphone peut rester verrouillé pendant la connexion et l\'utilisation.',
            other_hud: 'Autres fonctions HUD : Vitesse, alertes d\'excès de vitesse, appels entrants et messages.',
            glasses_appearance: 'Apparence de lunettes ordinaires, compatibilité totale avec les casques, conception anti-technique : Pas de boutons, de LED ou de prises USB sur la monture.',
            external_lens: 'Conception ShiftLock™ de fixation rapide de la lentille externe, adaptée à la conduite de nuit.',
            replacement_lenses: 'Lentilles de remplacement en option : Lentilles polarisées 10 couleurs et lentilles photochromiques, toutes avec une protection 100 % UVA/UVB, antibuée, résistante aux rayures, revêtement hydrophobe et oléophobe, et amélioration du contraste.',
            inner_lens: 'La lentille intérieure permet de personnaliser les verres correcteurs. Lors de l\'achat de Blucap Moto, l\'utilisateur peut simultanément soumettre les informations relatives à sa prescription sur le site web officiel au moment de la commande.',
            voice_control: 'La fonction de commande vocale peut être déclenchée à l\'aide de la télécommande et est compatible avec les écouteurs Bluetooth d\'autres marques.',
            nose_pad: 'La plaquette nasale est interchangeable dans les tailles M et L.',
            comes_with_a: 'Le Blucap Moto est livré avec un adaptateur de charge magnétique pour faciliter le chargement.',
            light_engine: 'Le moteur MicroLED Light à très faible consommation d\'énergie et la puce informatique permettent de naviguer toute la journée (avec une autonomie de batterie de plus de 10 heures.).',
            remote_control: 'La télécommande se connecte à l\'application du téléphone via Bluetooth. Avec plusieurs accessoires d\'installation, il peut prendre en charge différents types de guidon.',
            about_blucap: 'À propos de Blucap',
            about_us: 'A propos de nous→',
            this_article: 'Texte de cet article',
            copy_text: 'Copier le texte',
            download_all: 'Téléchargez tous les éléments des photos',
            zip569: 'ZIP | 56.9MB',
            advertisement: 'Advertisement_Video_EN.mp4',
            mp4106: 'MP4 | 106MB',
            vertical_advertisement: '30s_Vertical_Advertisement_Video_EN.mp4',
            mp4378: 'MP4 | 37.8MB',
            cg: 'CG_QuickShift_Video.mp4',
            mp447: 'MP4 | 4.7MB',
            cg_auto: 'CG_AutoConnection_Video.mp4',
            mp445: 'MP4 | 4.5MB',
            for_more: 'Pour plus d\'information veuillez contacter:',
            zhaot: 'Zhao Tingting',
            brand_marketing: 'Marketing de marque',
            ztt: 'ztt@blucapsport.com',
            b_m: 'Blucap media',
            mb: 'media@blucapsport.com',
            b_m_img: 'Blucap Moto Image 1',
            b_m_pic: 'Modèle Blucap Moto Image 1',
            b_m_color: 'Blucap Moto Lentilles de couleur Image 1',
            b_m_unique: 'Les lentilles uniques de Blucap Moto',
            b_m_factor: 'Photo de l\'usine Blucap Moto',
            press_contacts: 'Press Contacts',
        },
        about: {
            us: 'A propos de nous',
            starting: 'À partir du désir',
            initially: 'Zhu Ning a initialement rencontré le problème d\'utiliser un téléphone portable pour la navigation pendant la conduite à moto, et il n\'a pas pu trouver le produit HUD idéal. Par conséquent, il a commencé à créer son propre dispositif HUD grâce à des efforts de bricolage.',
            journey: 'Le voyage a commencé.',
            zhu_ning: 'En 2021, Zhu Ning a fondé Blucap, qui a rassemblé une équipe de motards engagés, d\'ingénieurs, de designers, d\'experts en lunetterie et d\'experts en systèmes cartographiques. Notre dévouement à l\'innovation technologique et au design de bon goût nous pousse constamment à repousser les limites de ce qui est possible dans le monde de l\'expérience de la conduite à moto.',
            built: 'Construit avec patience.',
            throughout: 'Tout au long de nos trois années d\'exploration de notre premier produit, Blucap Moto, nous avons :',
            bravely: 'Nous avons courageusement choisi la technologie AR de pointe pour accompagner la maturité de la production à grande échelle de la chaîne d\'approvisionnement.',
            spent: 'Nous avons passé plus de six mois à explorer et à vérifier une solution technique qui pourrait équilibrer les exigences en matière d\'interaction HUD pour la conduite, le volume des lunettes et la consommation d\'énergie.',
            redesigned: 'Nous avons repensé l\'ensemble des lunettes pour leur donner une conception plus fiable permettant une libération rapide des lentilles et une meilleure expérience visuelle, même si cela signifiait retarder le lancement du produit de plus de six mois.',
            continuously: 'Nous avons continuellement testé et amélioré le produit en nous basant sur les retours de nos motards au sein de l\'équipe et des utilisateurs de test du monde entier. Nous avons ajusté le prototype des lunettes plus de 100 fois et optimisé en permanence le système logiciel.',
            reliability_0: 'Fiabilité, professionnalisme.',
            reliability_1: ', et ',
            reliability_2: 'Sincérité ',
            reliability_3: 'La sincérité est l\'une des valeurs fondamentales de Blucap. Nous accordons une grande attention à chaque détail de nos produits et nous polissons patiemment chaque aspect pour atteindre l\'excellence ultime. Bienvenue chez Blucap. Là où l\'innovation rencontre la route ouverte.',
            hq: 'Hangzhou HQ',
            dream: 'No.22 Dream Town Hangzhou, ZJ 311100, P.R.C',
            support: 'support@blucapsport.com',
            hk: 'Hong Kong',
            ug: 'Unit 2A, 17/F, Glenealy Tower, No.1 Glenealy Central',
            zb: 'zn@blucapsport.com',
            portland: 'Portland',
            upcoming: 'Upcoming'

        }
    }
}