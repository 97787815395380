<template>
  <div class="allss">
    <div class="flex row-between row-center w-1" @click="tz">
      <img src="../../assets/20240109/ShoppingBag.svg" class="bimg" />
      <div class="txt-01">
        Awaiting Sales
        <!-- {{ $t('message.buttons.button3') }} -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    tz() {
      //window.location.href = 'https://igg.me/at/QJzBtGSyR5I/x/35860549#/'
    },
  },
}
</script>

<style lang="less" scoped>
.w-1 {
  // width: 175px;
  padding: 12px 14px;
  //   background-color: #fff;
  border: 1.5px solid #fff;
  backdrop-filter: blur(15px);
  cursor: not-allowed;
  width: fit-content;
  opacity: 0.5;
}

.bimg {
  width: 19px;
  height: 19px;
}

.txt-01 {
  font-family: League Spartan;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: #fff;
  margin-left: 10px;
}
</style>
