<template>
  <div class="all">
    <!--轮播图-->
    <div class="allSwipres common">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in swiperList"
            :key="index"
          >
            <img v-lazy="item" class="img" />
          </div>
        </div>
        <!-- 如果需要导航按钮 -->
        <div class="swiper-pagination"></div>
        <!-- 如果需要导航按钮 -->
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
      <div class="content">
        <div class="title">
          {{ $t('message.index.ant') }}
        </div>
        <div class="txt-1">
          Blucap Moto has
          <span class="border">no buttons, LEDs, or USB sockets.</span><br />
          <!-- {{ $t('message.index.ant_content1') }}<br /> -->
          {{ $t('message.index.ant_content2') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
export default {
  data() {
    return {
      // 轮播图
      swiperList: [
        require('../../../assets/20230905/scoll/06.png'),
        require('../../../assets/20230905/scoll/05.png'),
        require('../../../assets/20230905/scoll/04.png'),
        require('../../../assets/20230905/scoll/03.png'),
        require('../../../assets/20230905/scoll/02.png'),
        require('../../../assets/20230905/scoll/01.png'),
      ],
    }
  },
  activated() {
    this.$nextTick(() => {
      this.swiper()
    })
  },
  methods: {
    // 轮播图
    swiper() {
      new Swiper('.swiper-container', {
        pagination: { el: '.swiper-pagination' },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      })
    },
  },
}
</script>

<style lang="less">
/*小圆点的样式，需要单独写一个不带scoped属性的style标签*/
.swiper-pagination-bullet {
  width: 20px !important;
  height: 2px !important;
  background-color: #fff;
  border-radius: 0px;
  opacity: 0.3;

  &.swiper-pagination-bullet-active {
    background-color: #fff;
    opacity: 1;
  }
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: -11px !important;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url(../../../assets/20230905/ArrowCircleLeft.svg) !important;
  width: 42px !important;
  height: 42px !important;
  background-size: 100% 100% !important;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url(../../../assets/20230905/ArrowCircleRight.svg) !important;
  width: 42px !important;
  height: 42px !important;
  background-size: 100% 100% !important;
}
</style>

<style lang="less" scoped>
.all {
  width: 100%;
  background: #000;
  padding-top: 90px;
  padding-bottom: 90px;

  .common {
    width: 1380px;
    margin: 0 auto;

    .img {
      width: 100%;
      height: 600px;
    }

    .title {
      color: #fff;
      text-align: center;
      font-family: League Spartan;
      font-size: 42px;
      font-style: normal;
      font-weight: 500;

      margin-top: 42px;
    }

    .txt-1 {
      color: rgba(255, 255, 255, 0.7);
      text-align: center;
      font-feature-settings: 'ss02' on;
      font-family: League Spartan;
      font-size: 21px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      margin-top: 24px;
      .border {
        font-weight: 500;
        color: #fff;
      }
    }
  }
}
</style>
