// en.js
export default {
    message: {
        headers: {
            Moto: 'Moto',
            Prescription: 'Korrektur',
            Replacement_Lenses: 'Ersatzgläser',
            Blucap_Go: 'Blucap Go',
            News: 'Nachricht',
            About_Us: 'Über uns',
            Dealer: 'Händler'
        },
        buttons: {
            button1: 'Vorbestellen',
            // button2:'Jetzt reservieren!',
            // button3:'Jetzt reservieren!',
            button2: 'Kickstarter',
            button3: 'Buy Now',
        },
        index: {
            //tips:'UVP: 399 $. Einführung von Kickstarter Ende September. Reservieren Sie jetzt und sichern Sie sich den 25 % Rabatt auf den Super-Frühbucherpreis.',
            //tips:'UVP: 399 US-Dollar. Kickstarter-Kampagne startet bald.',//0928
            tips: 'Auf Kickstarter live! Begrenzte Zeit, um den 20% Sonder-Rabatt zu erhalten!',//1003
            meet: 'Lernen Sie die weltweit erste Motorrad-Navigationssonnenbrille kennen, mit der Sie in jeden Moment der Fahrt eintauchen können.',
            subscribe: 'Abonnieren',
            subscribe_for: 'Abonnieren Sie für Angebote!',
            email_placeholder: 'E-Mail-Adresse',
            thanks_for: 'Vielen Dank für Ihr Abonnement!',
            thanks_error: 'Abonnieren fehlgeschlagen',
            pleass_input: 'Bitte geben Sie die richtige E-Mail-Adresse ein',
            watch_video: 'Video ansehen',
            most_cutting: 'Modernste AR-Technologie',
            only_the: ' Nur der Einsatz von modernsten Technologie kann die hohen Anforderungen von Blucap an Design und Leistung erfüllen.',
            innovation: 'Blucap™ Innovation Waveguide-Linsen',
            customized: 'Die maßgeschneiderte, spezielle Waveguide-Linsen von Blucap zeichnet sich unter anderem durch hohe Transparenz, Antibeschlagfähigkeit, Blendschutz und Korrekturoptionen aus.',
            smallest: 'Das kleinste MicroLED-Display der Welt',
            temples: ' Das weltweit kleinste 0,13-Zoll-MicroLED-Display mit hoher Helligkeit und extrem niedrigem Stromverbrauch, das die Brillenbügel wie normale Brillen aussehen lässt.',
            navigation_map: 'Navigationskarte',
            navigation_map_seamless: ' Erleben Sie eine nahtlose Navigation mit einer Echtzeitkarte in Ihrer Sichtlinie. Die Steuerung erfolgt problemlos über eine Lenker-Fernbedienung und erfüllt alle Ihre Navigationsanforderungen bei komplexen Straßenverhältnissen. Deshalb nennen wir sie ja auch Navigationsbrille.',
            turn_directions: 'Turn-Anweisungen',
            turn_directions_seamlessly: 'Blucap Moto zeigt beim Abbiegen automatisch den Blinker an. Nach dem Passieren von Kreuzungen wechselt sie problemlos in den ablenkungsfreien Zustand einer normalen Sonnenbrille.',
            technology: 'Verborgene Technologie im Anti-Tech-Design.',
            technology_seamlessly: 'Eine Brille ohne Tasten, LEDs oder USB-Anschlüsse; einfach zeitlose Eleganz trifft auf unsichtbare Innovation.',
            power: 'Sofortiges Ein- und Ausschalten',
            power_seamlessly: 'Öffnen Sie zum Einschalten einfach die Bügel der Sonnenbrille und stellen Sie automatisch eine Verbindung zu Ihrem Telefon her. Schließen Sie die Brille, um sie auszuschalten.',
            shiftLock: 'ShiftLock™-Schnellverschluss-Design',
            shiftLock_seamlessly: 'Das exklusive ShiftLock™-Schnellverschluss-Design von Blucap ermöglicht einen einfachen Austausch. Es erfüllt die Anforderungen von Nachtfahrten, farbigen Ersatzgläser und Korrekturbrillen.',
            polarized: 'Polarisiert',
            anti_fog: 'Beschlagfrei',
            water: 'Wasser- und ölabweisend',
            anti_scratch: 'Kratzfest',
            protection: '100% UVA/UVB-Schutz',
            performance: 'Hochwertige Rahmen',
            contrast: 'Kontrasterhöhung',
            definition: 'Hohe Definition',
            message: 'Nachricht',
            call: 'Aufruf',
            alert: 'Warnung',
            stay_informed: 'Bleiben Sie informiert und konzentrieren Sie sich auf die Straße mit den Anruf-, Benachrichtigung- und Geschwindigkeitsbegrenzungswarnungen von Blucap Moto in Echtzeit. Verpassen Sie keine wichtigen Benachrichtigungen und sorgen Sie gleichzeitig für Sicherheit und Konzentration während der Fahrt.',
            easy_to_use: 'Benutzerfreundliche und funktionsreiche Navigation',
            easy_to_use_seamlessly: 'Blucap Moto verbindet sich zur Navigation über Bluetooth mit der Blucap-App und nutzt die GPS-Hardware-Funktionen Ihres Smartphones voll aus.',
            it_contains_all: 'Es enthält alle für das Fahren notwendigen Funktionen, wie z. B. das Hinzufügen mehrerer Stopps, Routenoptionen und die Möglichkeit um, Staus zu vermeiden.',
            smart_Routing: 'Intelligentes Routing, unerwartetes Abenteuer',
            set_your_direction: 'Legen Sie Ihre Richtung und Entfernung fest, um legendäre Hin- und Rückfahrten zu erstellen. Passen Sie Ihre Route noch individueller an, indem Sie bestimmte Straßen meiden oder oder die Kurvenlinie anpassen und so ein maßgeschneidertes Fahrerlebnis schaffen, das einzigartig für Sie ist.',
            basic_features: '',
            voice_commands: 'Sprachbefehle',
            perfectly_compatible: 'Perfekt kompatibel mit Ihrem eigenen Bluetooth-Headset für Sprachsteuerung, Freisprechanlage und mehr.',
            handlebar_remote_control: 'Lenker-Fernbedienung',
            compatibility: 'Kompatibilität mit allen Motorradmodellen.',
            waterproof: 'Wasserdicht',
            built_to_withstand: 'Gebaut, um Stößen und Wassereinwirkung standzuhalten.',
            battery_life: 'Batterie-Lebensdauer',
            powered_by_a_button: 'Angetrieben durch eine Knopfzellenbatterie, die bis zu sechs Monate lang keinen Austausch erfordert.',
            full_featured_navigation: 'Fahren Sie mit Stil.',
            full_featured_seamlessly: 'Blucap Moto präsentiert eine bevorstehende Auswahl an schicken und hochwertigen Objektivoptionen, die es Ihnen ermöglichen, mit einem Hauch von Individualität und außergewöhnlicher optischer Leistung zu fahren.',
            lenses: 'Korrekturgläser',
            lenses_seamlessly: 'Entfernen Sie die äußeren Sonnenbrillengläser und verwandeln Sie Ihre Blucap Moto in eine normale Brille mit Heads-Up Display (HUD)!',
            contact: 'Kontakt: support@blucapsport.com',
            copyright: 'Urheberrecht @ 2023. Alle Rechte vorbehalten.',
            privacy_policy: 'Datenschutzrichtlinie'
        },
        lenses: {
            replacement: 'ShiftLock™-Ersatzgläser für Blucap Moto',
            money: '$49.00',
            sunglasses: 'Die einzigartigen multifunktionalen ShiftLock™ Sonnenbrillen mit Ersatzgläsern von Blucap sorgen für eine klare Sicht bei starkem Licht und bieten unendliche Möglichkeiten auf jeder Reise.',
            polarization: 'Polarisierung',
            bayer_level: 'Bayer Grad 7 Kratzfestigkeit',
            oil_water: 'Öl- und wasserabweisende Beschichtung',
            anti_fog: 'Antibeschlag',
            protection: '100% UVA/UVB-Schutz',
            contrast: 'Kontrastverstärkung',
            photochromic_lens: 'Das einzigartiges ShiftLock™ photochromatisches Brillenglas von Blucap kann innerhalb von Sekunden zu anderen Brillengläsern wechseln. ',
            light: 'Lichtdurchlässigkeit über einen weiten Bereich: 80% - 10%',
            color: 'Farbe',
            Navy_Blue: 'Marineblau',
            Dark_Green: 'Dunkelgrün',
            Dark_Grey: 'Dunkelgrau',
            Tea_Brown: 'Tee-Braun',
            Mirrored_Yellow: 'Gespiegeltes Gelb',
            Mirrored_Green: 'Gespiegeltes Grün',
            Mirrored_Red: 'Gespiegeltes Rot',
            Mirrored_Silver: 'Gespiegelt Silber',
            Mirrored_Blue: 'Gespiegeltes Blau',
            Black: 'Schwarz',
            Photochromic: 'Photochrom',
            coming_soon: 'Demnächst',
            care_instructions: 'Pflegehinweise',
            gentle_cleaning: 'Sanfte Reinigung: Verwenden Sie lauwarmes Wasser und ein mildes Reinigungsmittel, um Ihre Korrekturgläser sanft zu reinigen. Vermeiden Sie die Verwendung von Reinigungsmitteln, die Alkohol oder starke Säuren und Laugen enthalten, da diese Beschichtungen oder Rahmenmaterialien beschädigen können.',
            special_cloth: 'Spezialtuch: Verwenden Sie das mitgelieferte Spezialreinigungstuch, um die Brillengläser vorsichtig abzuwischen. Verwenden Sie keine normalen Tücher oder Kleidungsstücke, da diese die Oberfläche der Brillengläser zerkratzen könnten.',
            avoid_scratches: 'Vermeiden Sie Kratzer: Wenn Sie Ihre Brille nicht tragen, bewahren Sie sie in einem dafür vorgesehenen Brillenetui auf, um Kratzer oder Verunreinigungen auf den Brillengläsern zu vermeiden.',
            avoid_high: 'Vermeiden Sie hohe Temperaturen: Halten Sie Ihre Brille von Umgebungen mit hohen Temperaturen fern, z. B. von heißen Autos oder direktem Sonnenlicht, um Schäden an den Brillengläsern oder Rahmen zu vermeiden.',
            no_saliva: 'Kein Speichel oder Kleidung: Vermeiden Sie die Verwendung von Speichel, Haushaltsreinigern oder gewöhnlicher Kleidung zum Reinigen der Brilllengläser, da dies zu Verschmutzungen oder Schäden führen kann.',
            regular_inspection: 'Regelmäßige Inspektion: Überprüfen Sie Ihre Brillengläser regelmäßig auf Kratzer oder Schäden. Wenden Sie sich bei Problemen umgehend an einen professionellen Optiker.',
            avoid_chemical: 'Vermeiden Sie Kontakt mit Chemikalien: Vermeiden Sie den Kontakt Ihrer Brille mit starken Säuren, starken Laugen, chemischen Reinigungsmitteln und ähnlichen Substanzen, um eine Beschädigung von Beschichtungen oder Brillengläsern zu vermeiden.',

        },
        prescription: {
            prescription_lenses: 'Korrektionsgläser für Blucap Moto',
            money: '$209.00',
            order_for: 'Ihre Bestellung für die Korrekturgläser wird innerhalb von 6–8 Wochen ab Kaufdatum bearbeitet und versendet.',
            experience: 'Wenn Sie Blucap Moto mit Korrekturgläsern erleben möchten, können Sie diese separat bestellen.',
            smart_glasses: '*Die intelligente Brille ist nicht im Lieferumfang enthalten und muss separat erworben werden..',
            prescription_information: 'Daten zu Korrekturgläsern',
            how_to_read: 'So lesen Sie mehr über Ihre Korrekturgläser?',
            right_sphere: 'Rechtes Sphäre',
            select: 'Wählen',
            left_sphere: 'Linkes Sphäre',
            right_cylinder: 'Rechtes Zylinder',
            none: 'Keiner',
            left_cylinder: 'Linkes Zylinder',
            right_axis: 'Rechtes Achse',
            left_axis: 'Linkes Achse',
            right_pd: 'Rechtes PD',
            left_pd: 'Linkes PD',
            pd: 'PD',
            have_2pd: 'Ich habe 2 PD-Nummern',
            the_AXIS: 'Die eingegebenen Achse-Werte sind nicht korrekt! Diese Werte müssen zwischen 1 und 180 liegen.',
            remark: 'Hinweis',
            type_your: 'Geben Sie hier Ihre Benachrichtigung ein',
            i_confirm: 'Ich bestätige die Gültigkeit und Genauigkeit meiner Korrekturgläser und stelle sicher, dass es mit meinen körperlichen Anforderungen übereinstimmt. Ich erkenne auch an, dass ich keine Ansprüche oder Anfragen bezüglich Daten oder Spezifikationen stellen werde, die von der Korrekturgläser abweichen. ',
            i_acknowledge: 'Ich erkenne an, dass die schriftlichen Angaben, die sich aus der Daten zu Korrekturgläsern ergeben, für die Ausführung der Bestellung unerlässlich sind. Ich erkläre mich außerdem damit einverstanden, dass Blucap meine Daten zu Korrekturgläsern ausschließlich zum Zweck der Abwicklung der Transaktion(en) und der Erfüllung meiner Bestellung nutzt und an seine Drittanbieter und Partner weitergibt.',
            i_understand: 'Nachdem Sie eine Bestellung aufgegeben haben, wird ein professioneller Optiker Sie kontaktieren, um Ihre Daten zu Korrekturgläsern zu bestätigen. Sobald die Daten zu Korrekturgläsern bestätigt sind, ist eine Rückerstattung nicht mehr möglich.',
            i_ifthere: 'Sollte es aus Gründen, die mit Blucap zusammenhängen, zu einer Verzögerung der Lieferung kommen, werden wir Sie umgehend kontaktieren. Sie können entweder weiter warten oder eine Rückerstattung anfordern.',
            coming_soon: 'Demnächst',
            understanding: 'Ihre Korrekturgläser verstehen',
            right_eye: 'Rechtes Auge (OD)',
            stands: 'OD steht für "oculus dexter", das ist lateinisch für "rechtes Auge"."',
            left_eye: 'Linkes Auge (OS)',
            left_stands: 'OS steht für "oculus sinister", das ist Lateinisch für "linkes Auge".',
            sphere: 'Sphäre (SPH) – auch bekannt als Power (PWR)',
            they_mean: 'Sie bedeuten dasselbe: die Stärke Ihrer Korrekturgläser. Wenn Sie kurzsichtig sind, haben Sie vor Ihren Werten ein Minus (-), und wenn Sie weitsichtig sind, haben Sie ein Plus (+).',
            cyl: 'Zylinder (ZYL)',
            people: 'Dies ist für Menschen mit Astigmatismus. Dies ist der Fall, wenn ein Teil des Auges stärker korrigiert werden muss als der Rest. Der Zylinderwert wird mit einem Minuszeichen (-) geschrieben.',
            axis: 'Achse',
            this_is_only: 'Dies ist nur für Menschen mit Astigmatismus. Die Achse ist eine Nummer zwischen O und 180 und bestimmt die Ausrichtung des Zylinders (CYL).',
            oK: 'OK',

        },
        news: {
            blucap_unveils: 'Blucap steltt die offizielle Version der Blucap Moto Motorrad-Navigations-Sonnenbrille vor.',
            the_blucap_team: 'Blucap Team, 13. September 2023',
            september_13: '[13. September 2023] - Blucap, die bahnbrechende Marke für intelligente Brillen, kündigt die Blucap Moto Navigationssonnenbrille an und schafft damit eine neue Art der Navigation und Fahrerfahrung für Motorrad-Enthusiasten.',
            motorcycle: 'Eine revolutionäre Motorradnavigation',
            blucap_moto_is: ' Blucap Moto ist ein Navigationsgerät, das Informationen direkt im Sichtfeld des Fahrers anzeigt. Es bietet ein ablenkungsfreies Erlebnis, mit Abbiegepfeilen, die nur dann erscheinen, wenn sie benötigt werden. Blucap Moto zeichnet sich durch seine Kartennavigation für komplexe Straßenverhältnisse aus.',
            in_addition: 'Zusätzlich verbindet sich Blucap Moto über Bluetooth mit Handys und macht es unnötig, den Bildschirm während der Nutzung seiner Navigationsfunktionen eingeschaltet zu halten. Fahrer können wählen, ihre Handys in einer Tasche oder Hosentasche zu behalten, um die Unannehmlichkeiten der Befestigung des Handys auf einem Halter zu vermeiden, sowie potenzielle Probleme wie Schäden durch Kameravibrationen, Regenschäden, Überhitzung des Telefons und Batterieverbrauch zu vermeiden.',
            with_blucap_moto: ' Mit Blucap Moto können Motorradfahrer sicher und ohne Ablenkungen fahren und ein immersives Fahrerlebnis genießen, ohne auf ihre Telefone schauen zu müssen.',
            navigation_display: 'Navigationsanzeige',
            blucap_has_developed: 'Blucap hat eine voll ausgestattete Navigations-App speziell für Motorradfahrer entwickelt. Diese App bietet Funktionen wie mehrere Routenoptionen, die Möglichkeit, mehrere Stopps hinzuzufügen und die Option, Verkehr zu vermeiden. Sie kann Mainstream-Navigationsanwendungen auf dem Markt ersetzen.',
            app_functionality: 'App-Funktionalität - Grundlagen',
            blucap_has_designed: 'Blucap hat eine intelligente Routing-Funktion entwickelt, die es Fahrern ermöglicht, eine von einem Algorithmus generierte empfohlene Fahrtroute zu erhalten. Die Route basiert auf den persönlichen Vorlieben des Motorradfahrers, einschließlich der allgemeinen Richtung und Entfernung der Fahrt sowie der Vorliebe für kurvenreiche Straßen oder landschaftlich reizvolle Orte. Diese Funktion bietet den Fahrern ein personalisiertes und angenehmes Fahrerlebnis.',
            basic_features: '',
            app_advanced: 'App-Funktionalität - Fortgeschritten',
            design: 'Design-Exzellenz und Trageerfahrung',
            according_to_blucap: ' Gemäß der Designphilosophie von Blucap sollten smarte Brillen in erster Linie ein komfortables Trageerlebnis wie bei gewöhnlichen Brillen bieten. Im Gegensatz zu anderen Smart- oder AR-Brillen ist die Blucap Moto leicht und hat das Aussehen von gewöhnlichen Sonnenbrillen. Die dünnen Bügel und das flexible Material an den Enden machen es einfach, sie bequem unter einem Helm zu tragen.',
            the_blucap_design: 'Das Design-Team von Blucap hat ein "Anti-Tech"-Design für die Interaktion mit elektronischen Funktionen entwickelt. Die Brille hat keine Tasten, LED-Anzeigen oder USB-Ports. Von außen sieht sie aus wie eine dezente Sonnenbrille im klassischen Stil, enthält aber tatsächlich modernste AR-Technologie im Inneren.',
            when_unfolding: 'Beim Ausklappen und Tragen der Brille schaltet sie automatisch ein und verbindet sich über Bluetooth mit einem Mobiltelefon. Dies vereinfacht die tägliche Nutzung, da keine überflüssigen Schritte erforderlich sind, um die Brille zu aktivieren oder zu verbinden.',
            power_on: 'Einschalten',
            the_charging: 'Die Lade-LED und die Niederfrequenz-Taste wurden in die magnetische Ladestation verlegt, um die Anzahl der elektronischen Komponenten an der Brille zu reduzieren. Diese Designänderung verringert das Gewicht und den Stromverbrauch der Brille weiter. Blucap hat auch eine einzigartige Designfunktion namens ShiftLock™ entwickelt, die einen schnellen und einfachen Austausch der äußeren Linsen mit unterschiedlichen Lichtdurchlässigkeitsstufen ermöglicht. Dadurch ist es möglich, sich an Nachtfahrten anzupassen und sie als verschreibungspflichtige Brillen zu verwenden. Polarisierte und photochromatische Linsen sind ebenfalls zum Kauf erhältlich.',
            release: 'ShiftLock™ Schnellspann-Design',
            customized: 'Maßgeschneiderte verschreibungspflichtige Gläser',
            traditional_motorcycle: 'Einige Motorrad-Sonnenbrillen und andere AR-Brillen verwenden einen RX-Adapter für verschreibungspflichtige Gläser. Dies sieht nicht nur unattraktiv aus, sondern erhöht auch das Gewicht der Brille. Blucap hat jedoch ein einzigartiges Lieferantensystem entworfen und etabliert, das eine Anpassung an verschreibungspflichtige Gläser durch einen Upgrade-Service ermöglicht. Das bedeutet, dass Blucap Moto mit verschreibungspflichtigen Gläsern ausgestattet werden kann, ohne das Aussehen zu verändern oder einen RX-Adapter hinzuzufügen. Dies bietet eine attraktive und leichte Lösung für Motorradfahrer.',
            prescription_lenses: 'Korrekturgläser',
            cutting_edge: 'Spitzen-AR-Technologie',
            focused: 'Vor zwei Jahren konzentrierte sich Blucap auf cutting-edge Micro LED und Waveguide-Linsen als optische Lösung, um die klobige Form, das hohe Gewicht und den hohen Stromverbrauch traditioneller AR-Brillen sowie ihre dicken Bügel zu vermeiden. Dies war der einzige Weg, um die Designanforderungen der Blucap-Marke für das Aussehen und Gewicht von Brillen zu erfüllen. Blucap Moto verwendet JBDs 0,13-Zoll-Micro LED, die kleinste und hellste Anzeige der Welt. Der aus ihm bestehende Lichtmotor hat nur die Größe eines Radiergummis am Ende eines Bleistifts und kann diskret an der Wurzel der Brille eingebettet werden. Im Vergleich zu anderen AR-Visuallösungen hat es nicht nur eine geringe Größe, die das Sichtfeld nicht beeinträchtigt, sondern auch die Vorteile von hoher Helligkeit und geringem Stromverbrauch.',
            microled: 'Blucap Moto und MicroLED-Lichtmaschine',
            uniquely: 'Blucap Moto hat eine diffraktive Waveguide-Linse entwickelt, die auf tatsächliche Fahrszenen zugeschnitten ist. Diese Art von Waveguide-Linse kann im Vergleich zu gewöhnlichen Waveguide-Linsen komplexe optische Leistungsanforderungen erfüllen, wie hohe Transparenz, hohe Helligkeit, Nebelbeständigkeit, Blendung und Rezeptanpassung. Mit starker Unterstützung von AR-Lieferanten und Fertigungspartnern hat Blucap Moto die Vorbereitung der wichtigen technischen Bestätigung vor der Massenproduktion abgeschlossen.',
            sales_plan: 'Verkaufsplan',
            official: 'Der offizielle Einzelhandelspreis für Blucap Moto beträgt 399 US-Dollar. Derzeit läuft das Händlerprogramm und alle Interessierten können sich durch Ausfüllen des offiziellen Händlerantragsformulars bewerben, um ein Blucap-Händler zu werden. Im September wird Blucap Moto ein Projekt auf Kickstarter starten, bei dem Frühbucherpreise erhältlich sind. Weitere Details finden Sie auf der folgenden Kickstarter-Seite.',
            fully: 'Obwohl Blucap Moto noch nicht vollständig veröffentlicht wurde, konnte das Unternehmen in den letzten drei Monaten bereits über 15.000 Follower auf Social-Media-Plattformen wie Facebook und Instagram gewinnen. Das Händlerprogramm, das erst vor einem Monat gestartet wurde, erhielt bereits über 100 Bewerbungen aus der ganzen Welt. Die Bewerber umfassen Motorradhändler, Ausrüstungsgeschäfte, Custom-Shops, Brillengeschäfte sowie verschiedene Online-Kanäle und Einzelpersonen aus Nordamerika, Europa und Asien. Dieses starke Interesse und die Vorfreude auf das innovative Produkt von Blucap Moto seitens Fahrern auf der ganzen Welt zeigen das Potenzial für zukünftigen Erfolg.',
            market_resonance: 'Marktresonanz',
            product_key: 'Product Key FeaturesProdukt Schlüsselfunktionen',
            real_time: 'Navigation: HUD-Kartennavigation in Echtzeit mit Abbiegehinweisen. Es verbindet sich über Bluetooth mit dem Telefon, wenn es als Brille getragen wird, und nutzt die Rechenleistung und GPS-Hardware des Telefons. Das Telefon kann während der Verbindung und Nutzung gesperrt bleiben',
            other_hud: 'Andere HUD-Funktionen: Geschwindigkeit, Geschwindigkeitswarnungen, eingehende Anrufe und Nachrichten.',
            glasses_appearance: 'Gewöhnliche Brillenoptik, volle Helmkompatibilität, Anti-Tech-Design: Keine Tasten, LEDs oder USB-Anschlüsse am Rahmen.',
            external_lens: 'ShiftLock™-Schnellverschluss für das Außenglas, geeignet für Nachtfahrten.',
            replacement_lenses: 'Optionale Ersatzgläser: 10-farbige polarisierte Gläser und phototrope Gläser, alle mit 100 % UVA/UVB-Schutz, Anti-Beschlag, kratzfester, hydrophober und oleophoher Beschichtung und Kontrastverstärkung.',
            inner_lens: 'Die Innenscheibe unterstützt die Anpassung von Korrektionsgläsern. Beim Kauf der Blucap Moto kann der Nutzer gleichzeitig mit der Bestellung auf der offiziellen Website seine Rezeptdaten eingeben.',
            voice_control: 'Die Sprachsteuerungsfunktion kann über die Fernbedienung ausgelöst werden und ist mit Bluetooth-Kopfhörern anderer Marken kompatibel.',
            nose_pad: 'Das Nasenpad ist in den Größen M und L austauschbar.',
            comes_with_a: 'Das Blucap Moto wird mit einem magnetischen Ladeadapter zum einfachen Aufladen geliefert.',
            light_engine: 'Die stromsparende MicroLED-Light-Engine und der Computerchip ermöglichen eine ganztägige Navigation (mit einer Akkulaufzeit von durchschnittlich über 10 Stunden).',
            remote_control: 'Die Fernbedienung kann über Bluetooth mit der Handy-App verbunden werden. Mit mehreren Installationszubehör kann es verschiedene Lenkertypen unterstützen.',
            about_blucap: 'Über Blucap',
            about_us: 'Über uns',
            this_article: 'Text dieses Artikels',
            copy_text: 'Text kopieren',
            download_all: 'Alle Fotografielemente herunterladen',
            zip569: 'ZIP | 56.9MB',
            advertisement: 'Advertisement_Video_EN.mp4',
            mp4106: 'MP4 | 106MB',
            vertical_advertisement: '30s_Vertical_Advertisement_Video_EN.mp4',
            mp4378: 'MP4 | 37.8MB',
            cg: 'CG_QuickShift_Video.mp4',
            mp447: 'MP4 | 4.7MB',
            cg_auto: 'CG_AutoConnection_Video.mp4',
            mp445: 'MP4 | 4.5MB',
            for_more: 'For more information, please contact:',
            zhaot: 'Zhao Tingting',
            brand_marketing: 'Markenmarketing',
            ztt: 'ztt@blucapsport.com',
            b_m: 'Blucap media',
            mb: 'media@blucapsport.com',
            b_m_img: 'Blucap Moto Bild 1',
            b_m_pic: 'Blucap Moto Modellbild 1',
            b_m_color: 'Blucap Moto Farblinsen Bild 1',
            b_m_unique: 'Die einzigartigen Linsen von Blucap Moto',
            b_m_factor: 'Blucap Moto Fabrikfoto',
            press_contacts: 'Pressekontakte',
        },
        about: {
            us: 'Über uns',
            starting: 'Anfangen mit dem Wunsch',
            initially: 'Zhu Ning stieß zunächst auf das Problem, während des Fahrens ein Handy zur Navigation zu verwenden, und er konnte kein ideales Motorrad-HUD-Produkt finden. Als Ergebnis begann er, sein eigenes HUD-Gerät durch DIY-Bemühungen zu erstellen.',
            journey: 'Die Reise hat begonnen',
            zhu_ning: 'Im Jahr 2021 gründete Zhu Ning Blucap, das ein Team engagierter Fahrer, Ingenieure, Designer, Brillenexperten und Kartensystemexperten versammelte. Unsere Hingabe an technologische Innovation und geschmackvolles Design treibt uns ständig an, die Grenzen dessen zu erweitern, was in der Welt des Motorradfahrens möglich ist.',
            built: 'Mit Geduld gebaut.',
            throughout: 'Während unserer dreijährigen Erkundung für unser erstes Produkt, Blucap Moto, haben wir:',
            bravely: 'Mutig die modernste Augmented Reality (AR) Technologie ausgewählt, um die Reife der Massenproduktion in der Lieferkette zu begleiten.',
            spent: 'Über ein halbes Jahr damit verbracht, eine technische Lösung zu erforschen und zu verifizieren, die die Anforderungen an die HUD-Fahrinteraktion, das Brillenvolumen und den Energieverbrauch in Einklang bringen konnte.',
            redesigned: 'Die gesamte Brille wurde neu gestaltet, um ein zuverlässigeres Design für den schnellen Austausch der Linsen und ein besseres visuelles Erlebnis zu ermöglichen, selbst wenn dies bedeutete, dass die Produkteinführung um mehr als ein halbes Jahr verzögert wurde.',
            continuously: 'Wir haben das Produkt kontinuierlich getestet und verbessert, basierend auf dem Feedback unserer Teamfahrer und Testnutzer aus der ganzen Welt. Wir haben den Prototyp der Brille mehr als 100 Mal angepasst und das Software-System kontinuierlich optimiert.',
            reliability_0: 'Zuverlässigkeit, Professionalität',
            reliability_1: ', Und ',
            reliability_2: 'Sicherheit ',
            reliability_3: 'Sind die Kernwerte von Blucap. Wir achten auf jedes Detail unserer Produkte und verfeinern geduldig jeden Aspekt, um das ultimative Beste zu erreichen. Willkommen bei Blucap. Wo Innovation auf offene Straßen trifft.',
            hq: 'Hangzhou HQ',
            dream: 'No.22 Dream Town Hangzhou, ZJ 311100, P.R.C',
            support: 'support@blucapsport.com',
            hk: 'Hong Kong',
            ug: 'Unit 2A, 17/F, Glenealy Tower, No.1 Glenealy Central',
            zb: 'zn@blucapsport.com',
            portland: 'Portland',
            upcoming: 'Upcoming'

        }
    }
}