import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueLazyload from 'vue-lazyload';
import {i18n} from "./lange/i18n";


Vue.config.productionTip = false;

import axios from 'axios'
Vue.prototype.$axios = axios

import vueSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
Vue.use(vueSwiper)

Vue.use(VueLazyload);


// // 全局方法 (只有在pc端有效)
// //弹出框禁止滑动
// Vue.prototype.stopScroll = function () {
//   var preD = function (e) {
//     e.preventDefault()
//   }
//   document.body.style.overflow = "hidden";
//   document.addEventListener("touchmove", preD, { passive: false }); // 禁止页面滑动
// }

// //弹出框可以滑动
// Vue.prototype.canScroll = function () {
//   var preD = function (e) {
//     e.preventDefault()
//   }
//   document.body.style.overflow = ""; // 出现滚动条
//   document.removeEventListener("touchmove", preD, {
//     passive: false,
//   });
// }


// 重写路由 防止多次点击tab切换页面时报错 
// push或replace 看情况使用
import VueRouter from "vue-router"
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};

// let getLocal = () => {//获取浏览器当前设置的语言
//   let myLocal = localStorage.getItem('language')//从本地缓存获取类型
//   console.log('myLocal',myLocal);
//   if (myLocal) {//如果本地有资源包，就从本地获取资源包
//       return myLocal=='zh'?'cn':myLocal;
//   }
//   // 否则获取当前网络语言(navigator对象，用于提供当前浏览器及操作系统等信息，这些信息都放在navigator的各个属性中)
//   let localName = navigator.language.indexOf('zh') !== -1 ? 'cn' : 'en';
//   // 将语言资源存储到本地中(防止刷新后需要重新设置语言)
//   localStorage.setItem('language', localName);
//   console.log('navigator.language',navigator.language,'localName',localName);
//   return localName;

// };

// import VueI18n from "vue-i18n";
// import cnLange from './lange/cn';
// import enLange from './lange/en';
// import twLange from './lange/tw';
// Vue.use(VueI18n)

// var i18n =new VueI18n({
//   locale: getLocal(),
//   messages:{
//     'cn':cnLange,
//     'en':enLange,
//     'tw':twLange
//   }
// })


new Vue({
  router,
  i18n, // i18n
  store,
  render: h => h(App)
}).$mount("#app");
