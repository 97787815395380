import Vue from "vue"
import Vuex from "vuex"
import axios from "axios"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // 监听屏幕高度用来适配加盟页的弹窗
        screenHeight: document.documentElement.clientHeight,
        // 监听屏幕宽度用来识别是pc还是mp
        screenWidth: document.documentElement.clientWidth, //屏幕宽度
        // 头部tab
        tabList: [
            { name: "Moto", path: '/' },
            { name: "Prescription", path: '/prescription' },
            { name: "Replacement_Lenses", path: '/lenses' },
            { name: "Blucap_Go", path: '/app' },
            { name: "News", path: '/news' },
            { name: "About_Us", path: '/about' },
            { name: "Dealer", path: 'dealer' }
        ],
        api: 'https://api-web.xiaofutech.com/api',
    },
    mutations: {},
    actions: {
        // 获取首页数据
        getData (context, data) {
            return new Promise((resolve) => {
                axios({
                    method: 'GET',
                    url: 'https://ipinfo.io?token=6991413f393432'
                }).then((res) => {
                    console.log('ip', res)
                    var country = res.data.country
                    console.log('country', country)
                    resolve(new Promise((resolve2) => {
                        axios({
                            method: "GET",
                            //url: 'https://web-api.blucapsport.com/api/shopping/sub-tem2?email=' + data.email + '&country=' + country+'&tags='+data.platform,
                            url: 'https://email-subscription-api.blucapsport.com/api?email=' + data.email + '&country=' + country + '&tags=' + data.platform,
                        }).then((res) => {
                            console.log('res', res)
                            resolve2(res)
                        })

                    })
                    )
                })
            })
        },
    },
    modules: {}
})
