// en.js
export default {
    message: {
        headers: {
            Moto: 'Moto',
            Prescription: 'Prescrizione',
            Replacement_Lenses: 'Lenti di ricambio',
            Blucap_Go: 'Blucap Go',
            News: 'Notizie',
            About_Us: 'Chi siamo',
            Dealer: 'Rivenditore'
        },
        buttons: {
            button1: 'Pre-ordine',
            // button2:'Prenota ora!',
            // button3:'Prenota ora!',
            button2: 'Kickstarter',
            button3: 'Buy Now',
        },
        index: {
            //tips:'PREZZO MASSIMO DI VENDITA: $399. Lancio su Kickstarter a fine settembre. Prenotate ora per ottenere lo sconto del 25% sul prezzo super precoce.',
            //tips:'Prezzo di listino: 399 dollari. Campagna Kickstarter in arrivo.',//0928
            tips: 'In diretta su Kickstarter! Tempo limitato per ottenere lo sconto speciale del 20%!',//1003
            meet: 'Vi presentiamo i primi occhiali da sole per la navigazione in moto, che vi permetteranno di immergervi in ogni momento del viaggio.',
            subscribe: 'Iscriviti',
            subscribe_for: 'Abbonati alle offerte!',
            email_placeholder: 'Indirizzo e-mail',
            thanks_for: 'Grazie per esserti iscritto!',
            thanks_error: 'Abbonamento fallito',
            pleass_input: 'Inserisci l\'indirizzo e-mail corretto',
            watch_video: 'Guarda il video',
            most_cutting: 'La tecnologia AR più all\'avanguardia',
            only_the: ' Solo l\'utilizzo di tecnologie all\'avanguardia può soddisfare le elevate esigenze di Blucap in termini di design e prestazioni.',
            innovation: 'Lente a guida d\'onda dell\'innovazione Blucap™',
            customized: 'Le lenti a guida d\'onda personalizzate e specializzate di Blucap eccellono per l\'elevata trasparenza, le capacità antiappannamento, il riflesso antiriflesso e le opzioni di prescrizione, tra le altre caratteristiche.',
            smallest: 'Il display MicroLED più piccolo del mondo',
            temples: ' Il più piccolo display MicroLED da 0,13 pollici al mondo, con un\'elevata luminosità e un consumo energetico bassissimo che rende le aste uguali a quelle dei normali occhiali.',
            navigation_map: 'Mappa di navigazione',
            navigation_map_seamless: ' Provate l\'esperienza di una navigazione senza interruzioni con una mappa in tempo reale all\'interno del vostro campo visivo. Il controllo avviene senza problemi tramite un telecomando da manubrio, per soddisfare tutte le vostre esigenze di navigazione in condizioni stradali complesse. Ecco perché li chiamiamo occhiali da navigazione.',
            turn_directions: 'Indicazioni per la svolta',
            turn_directions_seamlessly: 'Blucap Moto visualizza automaticamente gli indicatori di direzione durante le svolte. Dopo aver superato gli incroci, passa senza problemi allo stato di occhiali da sole normali senza distrazioni.',
            technology: 'Tecnologia nascosta in un design anti-tecnologico.',
            technology_seamlessly: 'Occhiali senza pulsanti, LED o prese USB: solo eleganza senza tempo e innovazione invisibile.',
            power: 'Accensione/spegnimento istantanei',
            power_seamlessly: 'È sufficiente aprire le aste degli occhiali da sole per accenderli e collegarli automaticamente al telefono. Chiudere gli occhiali per spegnerli.',
            shiftLock: 'Design a sgancio rapido ShiftLock™',
            shiftLock_seamlessly: 'L\'esclusivo design a sgancio rapido ShiftLock™ di Blucap consente una facile sostituzione. Soddisfa le esigenze della guida notturna, delle lenti di ricambio colorate e degli occhiali da vista.',
            polarized: 'Polarizzato',
            anti_fog: 'Antiappannamento',
            water: 'Repellente all\'acqua e all\'olio',
            anti_scratch: 'Antigraffio',
            protection: '100% di protezione UVA/UVB',
            performance: 'Montature ad alte prestazioni',
            contrast: 'Miglioramento del contrasto',
            definition: 'Alta definizione',
            message: 'Messaggioe',
            call: 'Chiamata',
            alert: 'Allarme',
            stay_informed: 'Rimanete informati e concentrati sulla strada con gli avvisi in tempo reale di chiamate, messaggi e limiti di velocità di Blucap Moto. Non perdete mai le notifiche importanti e mantenete la vostra sicurezza e concentrazione durante la guida.',
            easy_to_use: 'Navigazione facile da usare e completa',
            easy_to_use_seamlessly: 'Blucap Moto si collega all\'App Blucap tramite Bluetooth per la navigazione, sfruttando appieno le capacità hardware del GPS dello smartphone.',
            it_contains_all: 'Contiene tutte le funzioni necessarie per la guida, come l\'aggiunta di più fermate, le opzioni di percorso e la possibilità di evitare il traffico.',
            smart_Routing: 'Percorso intelligente, avventura inaspettata',
            set_your_direction: 'Impostate la direzione e la distanza per generare epici viaggi di andata e ritorno. Personalizzate ulteriormente il vostro percorso evitando alcune strade o regolando la curvatura, creando un\'esperienza di guida personalizzata e unica per voi.',
            basic_features: '',
            voice_commands: 'Comandi vocali',
            perfectly_compatible: 'Perfettamente compatibile con le vostre cuffie Bluetooth per il controllo vocale, le chiamate a mani libere e altro ancora.',
            handlebar_remote_control: 'Telecomando a manubrio',
            compatibility: 'Compatibilità con tutti i modelli di moto.',
            waterproof: 'Mpermeabile',
            built_to_withstand: 'Costruito per resistere agli urti e all\'esposizione all\'acqua.',
            battery_life: 'Durata della batteria',
            powered_by_a_button: 'Alimentato da una batteria a bottone, non richiede sostituzioni fino a sei mesi.',
            full_featured_navigation: 'Guida con Stile',
            full_featured_seamlessly: 'Blucap Moto presenta una futura gamma di opzioni di lenti eleganti e di alta qualità, permettendoti di guidare con un tocco di individualità e prestazioni ottiche eccezionali.',
            lenses: 'Lenti con prescrizione',
            lenses_seamlessly: 'Rimuovete le lenti esterne degli occhiali da sole per trasformare i vostri Blucap Moto in normali occhiali da vista con funzionalità Heads-Up Display (HUD)!',
            contact: 'contatto: support@blucapsport.com',
            copyright: 'Copyright @ 2023. Tutti i diritti riservati.',
            privacy_policy: 'Informativa sulla privacy'
        },
        lenses: {
            replacement: 'Lenti di ricambio ShiftLock™ per Blucap Moto',
            money: '$49.00',
            sunglasses: 'Le esclusive lenti sostitutive multifunzionali per occhiali da sole ShiftLock™ di Blucap offrono una visione nitida anche in presenza di luce forte e aggiungono infinite possibilità a ogni viaggio.',
            polarization: 'Polarizzazione',
            bayer_level: 'Resistenza ai graffi Bayer livello 7',
            oil_water: 'Rivestimento oleorepellente e idrorepellente',
            anti_fog: 'Antiappannamento',
            protection: 'Protezione 100% UVA/UVB',
            contrast: 'Miglioramento del contrasto',
            photochromic_lens: 'L\'esclusiva lente fotocromatica ShiftLock™ di Blucap può passare a lenti diverse in pochi secondi.Pulire quando è inattiva.',
            light: 'Trasmittanza luminosa: 80% - 10%.',
            color: 'Colore',
            Navy_Blue: 'Blu navy',
            Dark_Green: 'Verde scuro',
            Dark_Grey: 'Grigio scuro',
            Tea_Brown: 'Marrone di tè',
            Mirrored_Yellow: 'Giallo specchiato',
            Mirrored_Green: 'Verde specchiato',
            Mirrored_Red: 'Rosso specchiato',
            Mirrored_Silver: 'Argento specchiato',
            Mirrored_Blue: 'Blu specchiato',
            Black: 'Nero',
            Photochromic: 'Fotocromatico',
            coming_soon: 'In arrivo',
            care_instructions: 'Istruzioni per la manutenzione',
            gentle_cleaning: 'Pulizia delicata: Utilizzare acqua tiepida e un detergente delicato per pulire delicatamente le lenti da vista. Evitare l\'uso di detergenti contenenti alcol o acidi e alcali forti, poiché possono danneggiare i rivestimenti o i materiali della montatura.',
            special_cloth: 'Panno speciale: Usare il panno speciale in dotazione per pulire delicatamente le lenti. Evitare l\'uso di normali fazzoletti o indumenti, perché potrebbero graffiare la superficie delle lenti.',
            avoid_scratches: 'Evitare i graffi: Quando non si indossano gli occhiali, riporli in una custodia apposita per evitare graffi o contaminazioni delle lenti.',
            avoid_high: 'Evitare le alte temperature: Tenere gli occhiali lontano da ambienti ad alta temperatura, come automobili calde o luce solare diretta, per evitare di danneggiare le lenti o la montatura.',
            no_saliva: 'Niente saliva o indumenti: evitare di usare saliva, detergenti per la casa o indumenti comuni per pulire le lenti, in quanto ciò potrebbe causare sbavature o danni.',
            regular_inspection: 'Ispezione regolare: Controllare periodicamente che le lenti non presentino graffi o danni. Se si riscontrano problemi, contattare subito un negozio di occhiali professionale.',
            avoid_chemical: 'Evitare il contatto con sostanze chimiche: Evitare che gli occhiali entrino in contatto con acidi forti, alcali forti, detergenti chimici e sostanze simili per non danneggiare i rivestimenti o le lenti.',

        },
        prescription: {
            prescription_lenses: 'Lenti da vista per Blucap Moto',
            money: '$209.00',
            order_for: 'Il tuo ordine relativo alla prescrizione verrà evaso e spedito entro 6-8 settimane dalla data di acquisto.',
            experience: 'Se si desidera provare Blucap Moto con lenti da vista, è possibile ordinare le lenti da vista separatamente.',
            smart_glasses: '*Gli occhiali intelligenti non sono inclusi e devono essere acquistati separatamente.',
            prescription_information: 'Informazioni sulla prescrizione',
            how_to_read: 'Come leggere la prescrizione?',
            right_sphere: 'Destro sfera',
            select: 'Seleziona',
            left_sphere: 'Sinistro sfera',
            right_cylinder: 'Destro cilindro',
            none: 'Nessuno',
            left_cylinder: 'Sinistro cilindro',
            right_axis: 'Destro asse',
            left_axis: 'Sinistro asse',
            right_pd: 'Destro Distanza interpupillare',
            left_pd: 'Sinistro Distanza interpupillare',
            pd: 'Distanza interpupillare',
            have_2pd: 'Ho 2 numeri Distanza interpupillare',
            the_AXIS: 'I valori degli ASSI inseriti non sono corretti! Questi valori devono essere compresi tra 1 e 180.',
            remark: 'Osservazione',
            type_your: 'Scrivete qui il vostro messaggio',
            i_confirm: 'Confermo la validità e l\'accuratezza della mia prescrizione, assicurandomi che sia in linea con le mie esigenze fisiche. Riconosco inoltre che non solleverò alcun reclamo o richiesta in merito a informazioni o specifiche che si discostano dalla prescrizione.',
            i_acknowledge: 'Riconosco che le specifiche scritte fornite, derivanti dalla prescrizione medica, sono essenziali per completare l\'ordine. Inoltre, comprendo e concedo il mio consenso a Blucap per l\'utilizzo e la condivisione dei miei dati di prescrizione con i suoi fornitori e partner terzi, esclusivamente allo scopo di elaborare le transazioni e soddisfare il mio ordine.',
            i_understand: 'Dopo aver effettuato un ordine, un ottico professionista ti contatterà per confermare le informazioni relative alla tua prescrizione. Una volta confermate le informazioni della prescrizione, non sarà possibile effettuare un rimborso.',
            i_ifthere: 'Se si verificasse un ritardo nella spedizione per motivi legati a Blucap, ti contatteremo al più presto. Puoi scegliere se continuare ad attendere o richiedere un rimborso.',
            coming_soon: 'In arrivo',
            understanding: 'Capire la tua prescrizione',
            right_eye: 'Occhio destro',
            stands: 'OD sta per "oculus dexter", che in latino significa "occhio destro".',
            left_eye: 'Occhio sinistro',
            left_stands: 'OS sta per "oculus sinister", che in latino significa "occhio sinistro".',
            sphere: 'Sfera - nota anche come Potenza',
            they_mean: 'Significano la stessa cosa: l\'intensità della prescrizione. Se siete miopi avrete un meno (-) davanti ai vostri valori, mentre se siete presbiti avrete un più (+).',
            cyl: 'Cilindro',
            people: 'È indicato per le persone affette da astigmatismo. Questo si verifica quando una parte dell\'occhio necessita di una correzione maggiore rispetto al resto. Il valore del Cilindro è scritto con il segno meno (-).',
            axis: 'Asse',
            this_is_only: 'È indicato solo per le persone affette da astigmatismo. L\'asse è un numero compreso tra O e 180 e determina l\'orientamento del Cilindro (CYL).',
            oK: 'OK',

        },
        news: {
            blucap_unveils: 'Blucap presenta il lancio ufficiale degli occhiali da sole per la navigazione in moto, Blucap Moto.',
            the_blucap_team: 'Squadra Blucap, 13 settembre 2023',
            september_13: '[13 settembre 2023] – Blucap, il marchio pionieristico di occhiali intelligenti, annuncia il lancio degli occhiali da sole da navigazione Blucap Moto, creando così un nuovo modo di navigazione e di esperienza di guida per gli appassionati di moto.',
            motorcycle: 'Una rivoluzionaria navigazione in moto',
            blucap_moto_is: ' Blucap Moto è un dispositivo di navigazione che visualizza le informazioni direttamente davanti alla linea di vista del conducente. Offre un\'esperienza priva di distrazioni, con frecce di direzione che appaiono solo quando necessario. Nel campo degli occhiali da navigazione, Blucap Moto si distingue per la navigazione mappa, che può essere controllata con un telecomando montato sul manubrio, soddisfacendo tutte le tue esigenze di navigazione nelle complesse condizioni stradali.',
            in_addition: 'Inoltre, Blucap Moto si collega al telefono tramite Bluetooth, eliminando la necessità di tenere lo schermo del telefono acceso quando si utilizzano le funzioni di navigazione. I motociclisti possono scegliere di tenere il telefono in una borsa o in una tasca, evitando l\'inconveniente di montarlo su un supporto e i potenziali problemi come il danneggiamento delle vibrazioni dell\'obiettivo della fotocamera, i danni causati dalla pioggia, il surriscaldamento del telefono e il consumo della batteria.',
            with_blucap_moto: ' Con Blucap Moto, i motociclisti possono guidare in sicurezza e senza distrazioni, godendo di un\'esperienza di guida coinvolgente senza la necessità di guardare il telefono.',
            navigation_display: 'Display di navigazione',
            blucap_has_developed: 'Blucap ha sviluppato un\'applicazione di navigazione completa progettata appositamente per i motociclisti. L\'applicazione offre diverse opzioni di percorso, la possibilità di aggiungere più soste e l\'opzione di evitare il traffico. Può sostituire le principali applicazioni di navigazione presenti sul mercato.',
            app_functionality: 'Funzionalità dell\'app - Base',
            blucap_has_designed: 'Blucap ha progettato una funzione di instradamento intelligente che consente ai motociclisti di ottenere un percorso consigliato generato da un algoritmo. Il percorso si basa sulle preferenze personali del ciclista, tra cui la direzione generale e la distanza del tragitto, nonché la preferenza per le strade tortuose o i punti panoramici. Questa funzione fornisce un\'esperienza di guida personalizzata e piacevole per i motociclisti.',
            basic_features: '',
            app_advanced: 'Funzionalità dell\'app - Avanzate',
            design: 'Eccellenza del design ed esperienza di utilizzo',
            according_to_blucap: ' Secondo la filosofia di design di Blucap, gli occhiali intelligenti devono innanzitutto offrire un\'esperienza di utilizzo confortevole, proprio come gli occhiali normali. A differenza di altri occhiali intelligenti o AR, Blucap Moto è leggero e ha l\'aspetto di un normale occhiale da sole. Le aste sottili e il materiale flessibile alle estremità consentono di indossarli comodamente sotto il casco.',
            the_blucap_design: 'The Blucap design team has defined an "Anti-Tech" design for electronic function interaction. The glasses do not have any buttons, LED indicators, or USB ports. From the outside, they look like a pair of understated, classic-style sunglasses, but they actually contain cutting-edge AR technology inside.',
            when_unfolding: 'Quando si aprono e si indossano gli occhiali, questi si accendono automaticamente e si collegano a un telefono cellulare tramite Bluetooth. In questo modo, l\'utilizzo quotidiano è semplice come indossare e togliere i normali occhiali, senza passaggi superflui.',
            power_on: 'Accensione',
            the_charging: 'Il LED di ricarica e il pulsante a bassa frequenza sono stati riposizionati nella base di ricarica magnetica, riducendo così il numero di componenti elettronici sugli occhiali. Questa modifica al design riduce ulteriormente il peso e il consumo energetico degli occhiali. Inoltre, Blucap ha sviluppato un\'esclusiva caratteristica di design chiamata ShiftLock™, che consente di sostituire rapidamente e facilmente le lenti esterne con diversi livelli di trasmissione della luce. Ciò consente di adattarsi alla guida notturna e all\'utilizzo come occhiali da vista. Sono disponibili all\'acquisto anche lenti polarizzate e fotocromatiche.',
            release: 'Design di Rilascio Rapido ShiftLock™',
            customized: 'Lenti da vista personalizzate',
            traditional_motorcycle: 'Alcuni occhiali da moto e altri occhiali AR utilizzano un adattatore RX per lenti con prescrizione medica. Questo non solo ha un aspetto poco attraente, ma aggiunge anche peso agli occhiali. Tuttavia, Blucap ha progettato e realizzato un sistema di fornitori unico che consente la personalizzazione delle lenti da vista attraverso un servizio di aggiornamento. Ciò significa che Blucap Moto può essere dotato di lenti da vista senza alterarne l\'aspetto o aggiungere un adattatore RX. Questa soluzione rappresenta una scelta attraente e leggera per i motociclisti.',
            prescription_lenses: 'Lenti con prescrizione',
            cutting_edge: 'Tecnologia AR all\'avanguardia',
            focused: 'Due anni fa, Blucap si è concentrata sulle lenti Micro LED e sulla guida d\'onda all\'avanguardia come soluzione ottica, al fine di evitare la forma ingombrante, il peso elevato e l\'elevato consumo energetico dei tradizionali occhiali AR, nonché le loro aste spesse. Questo era l\'unico modo per soddisfare le esigenze di design del marchio Blucap per quanto riguarda l\'aspetto e il peso degli occhiali. Blucap Moto utilizza il Micro LED da 0,13 pollici di JBD, che è il display più piccolo e luminoso al mondo. Il motore di luce che lo compone ha le dimensioni di una gomma all\'estremità di una matita, permettendogli di essere impiantato con discrezione alla radice delle gambe degli occhiali. Rispetto ad altre soluzioni visive AR, non solo ha dimensioni ridotte che non invadono il campo visivo, ma presenta anche i vantaggi di un\'elevata luminosità e un basso consumo energetico.',
            microled: 'Blucap Moto e motore di luce MicroLED',
            uniquely: 'Blucap Moto ha progettato in modo unico una lente a guida d\'onda diffrattiva per soddisfare le reali esigenze di guida. Questo tipo di lente a guida d\'onda può soddisfare complessi requisiti di prestazioni ottiche, come elevata trasparenza, trasmissione ad alta luminosità, resistenza all\'appannamento, riflessione antiriflesso e personalizzazione della prescrizione, rispetto alle normali lenti a guida d\'onda. Grazie al forte supporto dei fornitori AR e dei partner di produzione, Blucap Moto ha completato la preparazione della conferma tecnica chiave prima della produzione di massa.',
            sales_plan: 'Piano di vendita',
            official: 'Il prezzo al dettaglio ufficiale di Blucap Moto è di $ 399. Il programma rivenditori è attualmente in corso e chi è interessato a diventare rivenditore Blucap può candidarsi compilando il modulo di richiesta rivenditore ufficiale. A settembre, Blucap Moto lancerà un progetto su Kickstarter con prezzi anticipati disponibili. Per maggiori dettagli, rimanete sintonizzati sulla pagina Kickstarter successiva.',
            fully: 'Blucap Moto non è ancora stato rilasciato completamente, ma negli ultimi tre mesi ha già accumulato oltre 15.000 follower su piattaforme di social media come Facebook e Instagram. Il programma per i concessionari, lanciato appena un mese fa, ha ricevuto oltre 100 richieste da tutto il mondo. Tra i richiedenti figurano concessionari di motociclette, negozi di attrezzature, negozi personalizzati, negozi di occhiali e vari canali online e individui provenienti dal Nord America, Europa e Asia. Questo forte interesse e anticipazione per il prodotto innovativo di Blucap Moto da parte dei motociclisti di tutto il mondo dimostra il suo potenziale di successo.',
            market_resonance: 'Resonanza di mercato',
            product_key: 'Caratteristiche principali e specifiche del prodotto:',
            real_time: 'Navigazione: navigazione su mappa HUD in tempo reale, con indicazioni di svolta. Si collega al telefono tramite Bluetooth quando si indossano gli occhiali, utilizzando la potenza di calcolo del telefono e l\'hardware GPS; il telefono può rimanere bloccato durante la connessione e l\'uso.',
            other_hud: 'Altre funzioni HUD: velocità, avvisi di eccesso di velocità, chiamate in arrivo e messaggi.',
            glasses_appearance: 'Aspetto normale degli occhiali, piena compatibilità con il casco, design anti-tecnologico: nessun pulsante, LED o presa USB sulla montatura.',
            external_lens: 'Design a sgancio rapido delle lenti esterne ShiftLock™, adatto alla guida notturna.',
            replacement_lenses: 'Lenti di ricambio opzionali: lenti polarizzate in 10 colori e lenti fotocromatiche, tutte con protezione UVA/UVB al 100%, antiappannamento, antigraffio, rivestimento idrofobico e oleofobico e miglioramento del contrasto.',
            inner_lens: 'La lente interna supporta la personalizzazione delle lenti correttive. All\'acquisto di Blucap Moto, l\'utente può contemporaneamente inviare le informazioni sulla prescrizione sul sito web ufficiale al momento dell\'ordine',
            voice_control: '"La funzione di controllo vocale può essere attivata attraverso il telecomando ed è compatibile con cuffie Bluetooth di altre marche.',
            nose_pad: 'Il nasello è intercambiabile nelle misure M e L.',
            comes_with_a: '"Blucap Moto è dotato di un adattatore di ricarica magnetico per una facile ricarica."',
            light_engine: 'Il motore di luce MicroLED a ultra-basso consumo energetico e il chip di calcolo supportano la navigazione per l\'intera giornata (con una durata media della batteria di oltre 10 ore con un utilizzo di navigazione continuo).',
            remote_control: '"Il telecomando si connette all\'applicazione del telefono tramite Bluetooth. Con vari accessori di montaggio, può essere utilizzato su vari tipi di manubri.',
            about_blucap: 'Informazioni su Blucap',
            about_us: 'Chi siamo→',
            this_article: 'Testo di questo articolo',
            copy_text: 'Copia testo',
            download_all: 'Scarica tutti gli elementi foto',
            zip569: 'ZIP | 56.9MB',
            advertisement: 'Advertisement_Video_EN.mp4',
            mp4106: 'MP4 | 106MB',
            vertical_advertisement: '30s_Vertical_Advertisement_Video_EN.mp4',
            mp4378: 'MP4 | 37.8MB',
            cg: 'CG_QuickShift_Video.mp4',
            mp447: 'MP4 | 4.7MB',
            cg_auto: 'CG_AutoConnection_Video.mp4',
            mp445: 'MP4 | 4.5MB',
            for_more: 'Per maggiori informazioni, contattare:',
            zhaot: 'Zhao Tingting',
            brand_marketing: 'Marketing del Brand',
            ztt: 'ztt@blucapsport.com',
            b_m: 'Blucap media',
            mb: 'media@blucapsport.com',
            b_m_img: 'Immagine 1 di Blucap Moto',
            b_m_pic: 'Immagine del Modello 1 di Blucap Moto',
            b_m_color: 'Immagine 1 delle lenti colorate Blucap Moto',
            b_m_unique: 'Lenti uniche di Blucap Moto',
            b_m_factor: 'Foto della fabbrica Blucap Moto',
            press_contacts: 'Contatti stampa',
        },
        about: {
            us: 'Chi siamo',
            starting: 'Partendo dal Desiderio',
            initially: 'Zhu Ning inizialmente si è imbattuto nel problema di utilizzare il telefono cellulare per la navigazione durante la guida in moto, e non è stato in grado di trovare il prodotto HUD ideale per motociclette. Di conseguenza, ha iniziato a creare il proprio dispositivo HUD attraverso sforzi fai-da-te. ',
            journey: 'Viaggio iniziato',
            zhu_ning: 'Nel 2021, Zhu Ning ha fondato Blucap, che ha raccolto un team di motociclisti, ingegneri, designer, esperti di occhiali e sistemi di mappe appassionati. La nostra dedizione all\'innovazione tecnologica e al design ricercato ci spinge a superare costantemente i limiti di ciò che è possibile nel mondo dell\'esperienza di guida in motocicletta. ',
            built: 'Costruito con pazienza',
            throughout: 'Durante i nostri tre anni di esplorazione del nostro primo prodotto, Blucap Moto, abbiamo: ',
            bravely: 'Scelto coraggiosamente tecnologia AR all\'avanguardia per accompagnare la maturità della produzione su larga scala della catena di approvvigionamento. ',
            spent: 'Abbiamo trascorso oltre mezzo anno esplorando e verificando una soluzione tecnica che potesse bilanciare i requisiti per l\'interazione di guida HUD, il volume degli occhiali e il consumo di energia. ',
            redesigned: 'Abbiamo riprogettato completamente gli occhiali per avere un design più affidabile per il rilascio rapido delle lenti e una migliore esperienza visiva, anche se questo ha significato ritardare il lancio del prodotto di oltre mezzo anno. ',
            continuously: 'Abbiamo testato e migliorato continuamente il prodotto con il feedback dei piloti del nostro team e degli utenti di test in tutto il mondo. Abbiamo adattato il prototipo degli occhiali più di 100 volte e ottimizzato continuamente il sistema software. ',
            reliability_0: 'Affidabilità, Professionalità ',
            reliability_1: ', e ',
            reliability_2: 'Sincerità',
            reliability_3: 'sono i valori fondamentali di Blucap. Prestiamo attenzione ad ogni dettaglio dei nostri prodotti e levighiamo pazientemente ogni aspetto per raggiungere il meglio assoluto.Benvenuti in Blucap. Dove l\'innovazione incontra la strada aperta. ',
            hq: 'Hangzhou HQ',
            dream: 'No.22 Dream Town Hangzhou, ZJ 311100, P.R.C',
            support: 'support@blucapsport.com',
            hk: 'Hong Kong',
            ug: 'Unit 2A, 17/F, Glenealy Tower, No.1 Glenealy Central',
            zb: 'zn@blucapsport.com',
            portland: 'Portland',
            upcoming: 'Upcoming'

        }
    }
}