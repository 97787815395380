<template>
    <div class="all two">
        <div class="swiper-container swiper01">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item, index) in swiperList" :key="index">
                    <img v-lazy="item" alt class="img" />
                </div>
            </div>
            <div class="swiper-pagination"></div>
        </div>
    </div>
</template>

<script>
import Swiper from "swiper";
// import "swiper/dist/css/swiper.min.css";


export default {
    data() {
        return {
            // 轮播图
            swiperList: [
                require("../../assets/20230905/mob/app3/06.png"),
                require("../../assets/20230905/mob/app3/05.png"),
                require("../../assets/20230905/mob/app3/04.png"),
                require("../../assets/20230905/mob/app3/03.png"),
                require("../../assets/20230905/mob/app3/02.png"),
                require("../../assets/20230905/mob/app3/01.png"),
            ],
        };
    },
    activated() {
        this.$nextTick(() => {
            this.swiper();
        });
    },

    methods: {
        // 轮播图
        swiper() {
            new Swiper(".two .swiper-container", {
                pagination: { el: ".two .swiper-pagination" },
                observer: true,//修改swiper自己或子元素时，自动初始化swiper
                observeParents: true//修改swiper的父元素时，自动初始化swiper
                //loop: true,
                // autoplay: {
                //     disableOnInteraction: false,
                //     delay: 2000,
                // },
            });
        },
    },
};
</script>
<style lang='less' scoped>
.all {
    width: 100%;

    .img {
        width: 100%;
    }

    // .swiper-pagination {
    //     width: 2.21rem;
    //     height: 0.4rem;
    //     border-radius: 0.2rem;
    //     position: absolute;
    //     left: 50%;
    //     margin-left: -1.105rem;
    //     padding: 0.155rem 0.5rem;
    //     margin-top: 0.1rem;
    // }
}
</style>