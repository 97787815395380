<template>
  <div class="all" ref="all">
    <MPHeader1 :tab-index1="tabIndexs1" :changeClass1="changeClass1" />
    <div class="show-text dhsm">
      <!-- MSRP: $399. Sales expected to start in summer 2024. -->
    </div>
    <div class="first">
      <video playsInline autoPlay muted class="video_1" id="video1">
        <!-- <source src="https://blk001.oss-us-west-1.aliyuncs.com/v1_1.mp4" type="video/mp4" /> -->
        <source src="../../assets/video2/v1_1.mp4?source=1" type="video/mp4" />
      </video>
      <div class="sm-1 blokc">
        <img src="../../assets/20230905/mo01.svg" class="simg-1" />
        <div class="sm-1-txt-1">
          {{ $t('message.index.meet') }}
        </div>
        <div class="m-t-36-1">
          <button02 />
        </div>
      </div>
    </div>
    <div>
      <email
        background="bl"
        class="m-e-t"
        style="padding-bottom: 0.3rem; margin-top: 0.9rem"
      />
    </div>

    <div class="w-5" @touchend="playVideo('video2')">
      <div class="w-5-1">
        <video
          playsInline
          loop
          muted
          preload="auto"
          class="video_1"
          id="video2"
        >
          <source
            src="https://blk001.oss-us-west-1.aliyuncs.com/banner_1.mp4"
            type="video/mp4"
          />
        </video>
      </div>
      <div class="btn-w">
        <div class="btn-w-1 flex row-start" @click="showPlay">
          <img src="../../assets/20230905/Play.svg" class="play_img" />
          <div class="btn-txt">{{ $t('message.index.watch_video') }}</div>
        </div>
      </div>
    </div>

    <div>
      <!--TurnDirections-->
      <TurnDirections />
    </div>

    <div>
      <!--NavigationMap-->
      <NavigationMap />
    </div>

    <div>
      <!--Whether-->
      <Whether />
    </div>

    <div>
      <!--Video01-->
      <Video01 />
    </div>

    <div>
      <!--FullFeatured-->
      <FullFeatured />
    </div>

    <div>
      <!--RidersCan-->
      <RidersCan />
    </div>

    <!-- <MPIndexSwipper00 /> -->
    <div class="third">
      <MPIndexSwipper00 />
    </div>

    <div>
      <MPIndexSwipper02 />
    </div>
    <div class="five">
      <div class="five-txt-1 m-t-0 color-07">
        {{ $t('message.index.stay_informed') }}
      </div>
    </div>

    <div>
      <MPIndexSwipper01 />
    </div>
    <div class="five">
      <div class="five-title">{{ $t('message.index.ant') }}</div>
      <div class="five-txt-1 color-07">
        Blucap Moto has
        <span class="border">no buttons, LEDs, or USB sockets.</span><br />
        <!-- {{ $t('message.index.ant_content1') }}<br /> -->
        {{ $t('message.index.ant_content2') }}
      </div>
    </div>

    <div>
      <!--Comfortable-->
      <Comfortable />
    </div>

    <div class="four" @touchend="playVideo('video5')">
      <video
        playsInline
        loop
        muted
        preload="metadata"
        class="video_1"
        id="video5"
      >
        <source
          src="https://blk001.oss-us-west-1.aliyuncs.com/OUT3.mp4"
          type="video/mp4"
        />
      </video>
    </div>

    <div class="five">
      <div class="five-title">{{ $t('message.index.power') }}</div>
      <div class="five-txt-1 color-07">
        <!-- {{ $t('message.index.power_seamlessly') }} -->
        To power on the sunglasses, simply open the temples. They will
        automatically connect to your phone. To power off, just close the
        glasses. There's no need to remember button functions or complex touch
        gestures.<span class="border">
          All you have to do is wear the glasses</span
        >.
      </div>
    </div>

    <div class="four" @touchend="playVideo('video6')">
      <video
        playsInline
        loop
        muted
        preload="metadata"
        class="video_1"
        id="video6"
      >
        <source
          src="https://blk001.oss-us-west-1.aliyuncs.com/OUT5.mp4"
          type="video/mp4"
        />
      </video>
    </div>

    <div class="five">
      <div class="five-title">{{ $t('message.index.shiftLock') }}</div>
      <div class="five-txt-1 color-07">
        {{ $t('message.index.shiftLock_seamlessly') }}
      </div>
    </div>

    <div class="four" @touchend="playVideo('video8')">
      <video
        playsInline
        loop
        muted
        preload="metadata"
        class="video_1"
        id="video8"
      >
        <source
          src="https://blk001.oss-us-west-1.aliyuncs.com/Voice Commands_1.mp4"
          type="video/mp4"
        />
      </video>
    </div>

    <div class="five">
      <div class="five-title">{{ $t('message.index.voice_commands') }}</div>
      <div class="five-txt-1 color-07">
        {{ $t('message.index.perfectly_compatible') }}
      </div>
    </div>

    <div class="seven eight">
      <div>
        <img
          v-lazy="require('../../assets/20230905/mindex10.png')"
          class="eightimg"
        />
      </div>
      <div class="seven-title">{{ $t('message.index.lenses') }}</div>
      <div class="seven-txt-1 color-07">
        {{ $t('message.index.lenses_seamlessly') }}
      </div>
      <div class="senvenbtn">
        <button01 class="m-t-007" :tab="tab" />
        <!-- <img src="../../assets/20230905/mob/BUTTON2.png" class="senvenbtn" @click="gourl(1)"> -->
      </div>
    </div>

    <div>
      <MPIndex04_1 />
    </div>

    <div class="seven">
      <div>
        <img
          v-lazy="require('../../assets/20230905/mindex09.png')"
          class="sevenimg"
        />
      </div>
      <div class="seven-title">
        {{ $t('message.index.full_featured_navigation') }}
      </div>
      <div class="seven-txt-1 color-07">
        {{ $t('message.index.full_featured_seamlessly') }}
      </div>
      <div class="senvenbtn">
        <button01 class="m-t-007" :tab="tab0" />
        <!-- <img src="../../assets/20230905/mob/BUTTON2.png" class="senvenbtn" @click="gourl(0)"> -->
      </div>
    </div>
    <div>
      <Last />
    </div>
    <!-- <div class="seven" @touchend="playVideo('last')">
      <div>
        <video
          playsInline
          loop
          muted
          preload="metadata"
          class="video_last"
          id="last"
        >
          <source
            src="https://blk001.oss-us-west-1.aliyuncs.com/last.mp4"
            type="video/mp4"
          />
        </video>
      </div>
      <div class="seven-title" style="margin-top: -1.7rem">
        {{ $t('message.index.outer_title') }}
      </div>
      <div class="seven-txt-1 color-07">
        {{ $t('message.index.outer_content') }}
      </div>
      <div class="senvenbtn">
        <button01 class="m-t-007" :tab="tab1" />
      </div>
    </div> -->

    <div class="night">
      <email background="bl1" class="m-e-t" />
      <div>
        <div class="flex row-start mmw">
          <img src="../../assets/20230905/Chats.svg" class="eimg" />
          <div class="mtxt">{{ $t('message.index.contact') }}</div>
        </div>
      </div>
    </div>

    <MPFooter />

    <div class="tcc" v-show="showYT">
      <iframe
        v-show="this.$i18n.locale != 'zh'"
        id="player"
        type="text/html"
        class="tcc-w-3-video"
        :src="vsrc"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
      <iframe
        class="tcc-w-3-video"
        v-show="this.$i18n.locale == 'zh'"
        :src="vsrc2"
        scrolling="no"
        frameborder="no"
        framespacing="0"
        allowfullscreen="true"
      >
      </iframe>
      <div class="abso" @click="showPlay">
        <img src="../../assets/20230905/X.svg" class="closeImage" />
      </div>
    </div>
  </div>
</template>

<script>
// import Swiper from "swiper";
// import "swiper/dist/css/swiper.css";

import MPHeader1 from '@/components/header/MPHeader04.vue' //头部
import MPFooter from '@/components/footer/MPFooter04.vue' //底部
import email from '@/components/com/mpemail.vue' //邮件
import TurnDirections from './compone_phone/TurnDirections.vue'
import NavigationMap from './compone_phone/NavigationMap.vue'
import Whether from './compone_phone/Whether01.vue'
import Video01 from './compone_phone/Video01.vue'
import FullFeatured from './compone_phone/FullFeatured.vue'
import RidersCan from './compone_phone/RidersCan.vue'
import MPIndexSwipper02 from '@/components/index/MPIndexSwipper02.vue' //swipper
import MPIndexSwipper01 from '@/components/index/MPIndexSwipper01.vue' //swipper
import Comfortable from './compone_phone/Comfortable.vue'
import Last from './compone_phone/Last.vue'

import MPIndexSwipper00 from '@/components/index/MPIndexSwipper00.vue' //swipper

import MPIndex04_1 from '@/components/index/MPIndex04_1.vue' //swipper

// import MPIndexSwipper03 from '@/components/index/MPIndexSwipper03.vue' //swipper
// import MPIndexSwipper04 from '@/components/index/MPIndexSwipper04.vue' //swipper
// import MPIndexSwipper05 from '@/components/index/MPIndexSwipper05.vue' //swipper
import button02 from '@/components/com/button02.vue' //头部
import button01 from '@/components/com/button01.vue' //头部

export default {
  components: {
    MPHeader1,
    MPFooter,
    email,
    TurnDirections,
    NavigationMap,
    Whether,
    Video01,
    FullFeatured,
    RidersCan,
    MPIndexSwipper02,
    MPIndexSwipper01,
    Comfortable,
    MPIndexSwipper00,

    MPIndex04_1,

    // MPIndexSwipper03,
    // MPIndexSwipper04,
    // MPIndexSwipper05,
    button02,
    button01,
    Last,
  },
  data() {
    return {
      changeClass1: 1,
      tabIndexs1: 0, //用来传值给子组件,来显示点击了第几个
      // 轮播图
      // swiperList: [
      //   require("../../assets/20230905/m01.png"),
      //   require("../../assets/20230905/m02.png"),
      //   require("../../assets/20230905/m03.png"),
      // ],

      showYT: false,
      isShow: false,
      tab: 1,
      tab0: 0,
      tab1: 2,
      bg1: require('../../assets/20230905/bg-Handlebar.png'),
      vsrc: '',
      vsrc2: '',
    }
  },
  activated() {
    // this.$nextTick(() => {
    //   this.swiper();
    // });
  },
  mounted() {
    //监听页面滚动事件
    // 要防抖
    window.addEventListener('scroll', this.debounce(this.handleScroll, 10))
  },
  methods: {
    playVideo(ids) {
      var myvideo = document.getElementById(ids)
      myvideo.play()
    },
    // 滑动
    handleScroll: function () {
      let top =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      if (top < 60) {
        this.changeClass1 = 1
      } else {
        this.changeClass1 = 2
      }
    },
    // 防抖函数
    debounce(fn, wait) {
      let timeout = null
      return function () {
        if (timeout !== null) clearTimeout(timeout)
        timeout = setTimeout(fn, wait)
      }
    },
    gourl(index) {
      if (index == 0) {
        this.$router.push({ path: '/lenses' })
      } else if (index == 1) {
        this.$router.push({ path: '/prescription' })
      }
    },
    // 轮播图
    // swiper() {
    //   new Swiper(".mySwiper", {
    //     pagination: { el: ".swiper-pagination" },
    //     loop: true,
    //     autoplay: {
    //       disableOnInteraction: false,
    //       delay: 2000,
    //     },
    //   });
    // },
    showPlay() {
      // var player=document.getElementById('player');
      // player.pauseVideo()
      if (this.showYT) {
        this.vsrc = ''
        this.vsrc2 = ''
      } else {
        this.vsrc =
          'https://www.youtube.com/embed/hZXzbKbc53c?si=BLKDzATD9mFpSCqY'
        this.vsrc2 =
          'https://player.bilibili.com/player.html?aid=788942498&bvid=BV1FC4y1f7eW&cid=1280556712&p=1'
      }
      this.showYT = !this.showYT
    },
    tzwb() {
      window.location.href =
        'https://www.kickstarter.com/projects/blucapsport/blucap-moto-motorcycle-navigation-sunglasses?ref=75idbt'
    },
  },
}
</script>

<style lang="less">
/*小圆点的样式，需要单独写一个不带scoped属性的style标签*/
.swiper-pagination-bullet {
  width: 20px !important;
  height: 2px !important;
  background-color: #fff !important;
  border-radius: 0px;
  opacity: 0.3 !important;

  &.swiper-pagination-bullet-active {
    background-color: #fff;
    opacity: 1 !important;
  }
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  // bottom: -0.4rem !important;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 5px !important;
}
</style>

<style lang="less" scoped>
.m-t-36-1 {
  width: 100%;
  // margin-left: 1.09rem;
  margin-top: 0.36rem;
}

.mmw {
  // width: 2.5rem;
  margin: 0 auto;
  margin-top: 0.12rem;
  padding-bottom: 0.3rem;
  width: fit-content;

  .eimg {
    width: 0.18rem;
  }

  .mtxt {
    font-family: League Spartan;
    font-size: 0.16rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    color: rgba(255, 255, 255, 0.7);
    margin-left: 0.1rem;
  }
}

.show-text {
  background-color: #236192;
  padding: 0.12rem 0.3rem;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: League Spartan;
  font-size: 0.16rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: #fff;
  margin-top: 0.73rem;
  cursor: pointer;
}
.dhsm {
  display: none;
}

.all {
  width: 100%;

  .first {
    width: 100%;
    height: 5.52rem;
    overflow: hidden;

    .video_1 {
      width: 100%;
      display: block;
      margin-top: -1.4rem;
      // height: 5.52rem;
      // z-index: -1;
      // // opacity: 0.6;
      // object-fit: cover;
    }

    .sm-1 {
      width: 100%;
      text-align: left;
      padding-left: 0.3rem;
      padding-right: 0.3rem;
      .simg-1 {
        width: 2.4rem;
      }

      .sm-1-txt-1 {
        font-family: League Spartan;
        font-size: 0.21rem;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        color: #fff;
        margin-top: 0.16rem;
      }
    }

    .blokc {
      position: absolute;
      top: 4.2rem;
    }
  }

  .second {
    width: 100%;

    .w-3-video {
      width: 100%;
    }
  }

  .m-e-t {
    // padding-top: 0.3rem;
    //padding-bottom: 0.3rem;
  }

  .third {
    background-color: #303030;
    padding: 0.3rem 0 0.1rem 0;

    .swimg {
      width: 3.33rem;
    }

    // .swiper-pagination {
    //   width: 2.21rem;
    //   height: 0.4rem;
    //   border-radius: 0.2rem;
    //   position: absolute;
    //   left: 50%;
    //   margin-left: -1.105rem;
    //   padding: 0.155rem 0.5rem;
    //   margin-top: 0.1rem;
    // }
  }
  .video_last {
    width: 100%;
    display: block;
    height: 3.9rem;
    z-index: -1;
    // opacity: 0.6;
    object-fit: cover;
  }
  .four {
    width: 100%;

    .video_1 {
      width: 100%;
      display: block;
      height: 2.3rem;
      z-index: -1;
      // opacity: 0.6;
      object-fit: cover;
    }
  }

  .five {
    background-color: #000;
    padding: 0.35rem 0.3rem 0.6rem 0.3rem;
    font-family: League Spartan;
    color: #fff;
    text-align: left;

    .five-title {
      font-size: 0.42rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .five-txt-1 {
      font-size: 0.21rem;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      margin-top: 0.24rem;
      color: rgba(255, 255, 255, 0.7);
      .border {
        font-weight: 500;
        color: #fff;
      }
    }

    .m-t-0 {
      margin-top: 0rem;
    }
  }

  .six {
    width: 100%;
    //background-image: url(../../assets/20230905/bg-Handlebar.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    color: #fff;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    padding-bottom: 0.3rem;

    .six-title {
      padding-top: 4.78rem;
      font-family: League Spartan;
      font-size: 0.42rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .six-txt-1 {
      font-family: League Spartan;
      font-size: 0.21rem;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }
  }

  .seven {
    width: 100%;
    color: #fff;
    padding-bottom: 0.6rem;
    border-bottom: 0.01rem solid #303030;

    .sevenimg {
      width: 100%;
    }

    .seven-title {
      font-size: 0.42rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-left: 0.3rem;
      padding-right: 0.3rem;
      margin-top: -0.7rem;
    }

    .seven-txt-1 {
      font-size: 0.21rem;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      margin-top: 0.24rem;
      padding-left: 0.3rem;
      padding-right: 0.3rem;
    }

    .senvenbtn {
      padding-left: 0.3rem;
      padding-right: 0.3rem;
      // width: 1.74rem;
      margin-top: 0.36rem;
    }
  }

  .eight {
    width: 100%;
    // padding-top: 1rem;
    padding-bottom: 0.6rem;
    border-bottom: none;

    .eightimg {
      width: 100%;
    }
  }

  .night {
    background: #1b1b1b;
    padding-top: 0.3rem;
  }

  .w-5 {
    width: 100%;
    position: relative;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    .video_1 {
      width: 100%;
      height: 1.4rem;
      display: block;
      z-index: -1;
      // opacity: 0.6;
      object-fit: cover;
    }

    .btn-w {
      position: absolute;
      width: 100%;
      z-index: 10;
      cursor: pointer;
      left: 1.1rem;
      top: 1.1rem;

      .btn-w-1 {
        border: 0.01rem solid #fff;
        // width: 1.74rem;
        height: 0.5rem;
        font-size: 0.21rem;
        font-weight: 400;
        color: #fff;
        line-height: 0.21rem;
        padding-left: 0.14rem;
        padding-right: 0.14rem;
        background: rgba(0, 0, 0, 0.01);
        -webkit-backdrop-filter: blur(15px);
        backdrop-filter: blur(15px);
        width: fit-content;
      }

      .btn-txt {
        line-height: 0.5rem;
        margin-left: 0.1rem;
      }
    }
  }

  .tcc {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    z-index: 200;
    top: 0;
    display: table-cell;
    vertical-align: middle;
    text-align: center;

    .tcc-w-3-video {
      width: 100%;
      height: 2.2rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
    }

    .abso {
      position: absolute;
      right: 0.2rem;
      top: 0.2rem;
    }
  }

  .color-07 {
    color: rgba(255, 255, 255, 0.7);
  }
}
</style>
