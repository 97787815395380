<template>
  <div class="all" @mouseover="playVideo('video_tk')">
    <div class="w-1">
      <div class="flex row-between">
        <div class="left">
          <div class="title">{{ $t('message.index.comfortable') }}</div>
          <div class="txt-1">
            <!-- {{ $t('message.index.comfortable_content') }} -->
            Blucap was founded by a group of experienced
            <span class="border">riders</span>, we understand the feeling of
            wearing eyewear when we ride. We have minimized the size and weight
            of the Blucap Moto to the extreme, ensuring that it is
            <span class="border">comfortable</span> to wear under a helmet
            during <span class="border">long rides</span>.
          </div>
        </div>
        <div class="right">
          <div class="w-2">
            <video
              playsInline
              loop
              muted
              preload="metadata"
              class="video_2"
              id="video_tk"
            >
              <source
                src="https://blk001.oss-us-west-1.aliyuncs.com/tk.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    playVideo(ids) {
      var myvideo = document.getElementById(ids)
      myvideo.play()
    },
  },
}
</script>

<style lang="less" scoped>
.all {
  width: 100%;
  padding-top: 90px;
  padding-bottom: 90px;
  background-color: #1b1b1b;

  .w-1 {
    width: 1380px;
    margin: 0 auto;

    .left {
      width: 645px;

      .title {
        color: #fff;
        font-feature-settings: 'ss02' on;
        font-family: League Spartan;
        font-size: 42px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 225px;
      }

      .txt-1 {
        color: rgba(255, 255, 255, 0.7);
        font-feature-settings: 'ss02' on;
        font-family: League Spartan;
        font-size: 21px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        margin-top: 24px;
        .border {
          font-weight: 500;
          color: #fff;
        }
      }
    }

    .right {
      width: 645px;

      .video_2 {
        width: 645px;
        height: 645px;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}
</style>
