// en.js
export default {
    message: {
        headers: {
            Moto: '眼镜',
            Prescription: '近视定制',
            Replacement_Lenses: '镜片配件',
            Blucap_Go: 'Blucap Go',
            News: '新闻',
            About_Us: '关于',
            Dealer: '零售商'
        },
        buttons: {
            button1: '预订',
            // button2: '立即预定',
            // button3: '立即预定',
            button2: 'Kickstarter',
            button3: 'Buy Now',
        },
        index: {
            //tips: '北美区标准售价: $399, 即将登陆 Kickstarter众筹平台。',
            //tips:'建议零售价：399美元。即将启动Kickstarter众筹活动',//0928
            tips: '已在Kickstarter推出！限时享受20%特别折扣！',//1003
            meet: '世界上第一款摩托导航眼镜，让你全身心沉浸在每一个骑行时刻。',
            subscribe: '订阅',
            subscribe_for: '订阅 Kickstarter 众筹活动和特惠信息',
            email_placeholder: '电子邮件地址',
            thanks_for: '感谢您的订阅！',
            thanks_error: '订阅失败',
            pleass_input: '请输入正确的电子邮件地址',
            watch_video: '观看影片',
            most_cutting: '最前沿的AR元器件',
            only_the: '只有使用最先进的技术才能满足 Blucap 对设计和性能的要求。',
            innovation: 'Blucap™ 创新波导镜片',
            customized: 'Blucap 定制的运动级波导镜片具有高透明度、防雾能力、防眩光反射等特性功能。',
            smallest: '世界上最小的 MicroLED 显示屏',
            temples: '世界上最小的 0.13 英寸 MicroLED 显示屏，高亮度、超低功耗，使AR眼镜的外观与普通眼镜一样。',
            navigation_map: '导航地图',
            navigation_map_seamless: '使用车把遥控器轻松控制地图显示与关闭，在复杂的路况中满足所有您的导航需求，这就是为什么我们称它为导航眼镜。',
            turn_directions: '转向指示',
            turn_directions_seamlessly: 'Blucap Moto 在入弯前自动浮现转向指示。经过路口后，自动消失，回到无干扰的普通眼镜镜状态。',
            technology: 'Anti-Tech™隐科技设计',
            technology_seamlessly: '无按钮、无LED 、无USB插口，Blucap设计团队或许不喜欢电子产品。',
            power: '展开镜腿，自动与手机完成连接。',
            power_seamlessly: '戴头盔、穿手套、点火启动，帅气步骤已经够多。别再来个长按开关机了，像普通眼镜一样戴上就可以起步了。',
            shiftLock: 'ShiftLock™ 镜片快拆设计',
            shiftLock_seamlessly: '独家快速拆卸设计，轻松更换镜片。可满足日夜骑行，更可替换个性化多彩墨镜片。',
            polarized: '偏光或变色',
            anti_fog: '防雾',
            water: '防水防油',
            anti_scratch: '耐刮擦',
            protection: '100% UVA/UVB',
            performance: '高性能材质镜框',
            contrast: '对比度增强',
            definition: '高清晰度',
            message: '微信消息',
            call: '来电名称',
            alert: '设速提醒',
            stay_informed: '通过 Blucap Moto 的实时通话、消息和限速警报保持信息灵通，保持安全和专注。',
            easy_to_use: '易使用且全功能的导航',
            easy_to_use_seamlessly: 'Blucap Moto 通过蓝牙连接到 Blucap App 以进行导航，充分利用您智能手机的硬件级GPS性能。',
            it_contains_all: 'Blucap App包含骑行所需的所有功能，如多途径点、路线选项和避开交通拥堵等功能，该有的它都有。',
            smart_Routing: '智能路线规划，意想不到的冒险',
            set_your_direction: '设置你今天想要溜车方向和路程，算法自动生成探险路线。通过避开某些道路或调整道路弯度，进一步自定义您的路线，打造独特的骑行体验。',
            basic_features: '注: 中国区导航功能基于高德引擎，与国际版有功能差异',
            voice_commands: '语音命令',
            perfectly_compatible: '与您自己的蓝牙耳机完美兼容，支持语音控制、免提通话等功能。',
            handlebar_remote_control: '车把遥控器',
            compatibility: '无论是仿赛还是踏板，通过多种安装配件，所有车型车把都可安装。',
            waterproof: '防水',
            built_to_withstand: '无惧淋雨或水坑。',
            battery_life: '超低功耗',
            powered_by_a_button: '由2颗按钮电池供电，约六个月无需更换',
            full_featured_navigation: '风格骑行',
            full_featured_seamlessly: 'Blucap Moto 提供一系列时尚且高质量的镜片选项，让您骑行时拥有独特的个性和卓越的光学性能。',
            lenses: '近视镜片定制',
            lenses_seamlessly: '移除外层遮阳镜片，Blucap Moto就像是一副普通眼镜，但能抬头显示(HUD)',
            contact: '联系：support@blucapsport.com',
            copyright: '版权©',
            privacy_policy: '隐私政策',
        },
        lenses: {
            replacement: 'ShiftLock™ Blucap Moto 替换镜片',
            money: '$49.00',
            sunglasses: 'Blucap 独特的 ShiftLock™ 多功能太阳镜替换镜片在强光下提供清晰的视野，并为每次旅程增加了无限可能性。',
            polarization: '偏光',
            bayer_level: '拜尔 7 级防刮',
            oil_water: '防水和防油涂层',
            anti_fog: '防雾',
            protection: '100% UVA/UVB 防护',
            contrast: '增强对比度',
            photochromic_lens: 'Blucap 独特的 ShiftLock™ 变光镜片可以在几秒钟内切换到不同的镜片。',
            light: '光透射率极广的范围：80% - 10%',
            color: '颜色',
            Navy_Blue: '海军蓝',
            Dark_Green: '深绿',
            Dark_Grey: '深灰',
            Tea_Brown: '茶色',
            Mirrored_Yellow: '镜面黄',
            Mirrored_Green: '镜面绿',
            Mirrored_Red: '镜面红',
            Mirrored_Silver: '镜面银',
            Mirrored_Blue: '镜面蓝',
            Black: '黑色',
            Photochromic: '光致变色',
            coming_soon: '即将推出',
            care_instructions: '保养说明',
            gentle_cleaning: '轻柔清洁：使用温水和温和的洗涤剂轻轻清洁您的处方镜片。避免使用含有酒精、强酸或强碱的清洁剂，因为它们可能损坏镜片的涂层或镜框材料。',
            special_cloth: '专用布：使用提供的专用清洁布轻轻擦拭镜片。避免使用普通纸巾或衣物，因为它们可能会划伤镜片表面。',
            avoid_scratches: '避免刮擦：当不佩戴眼镜时，请将它们存放在专用的眼镜盒中，以防止划伤或污染镜片。',
            avoid_high: '避免高温：将眼镜远离高温环境，如炎热的车内或直射阳光，以防止镜片或镜框损坏。',
            no_saliva: '不要使用唾液或衣物：避免使用唾液、家用清洁剂或普通衣物清洁镜片，因为这可能会导致污渍或损坏。',
            regular_inspection: '定期检查：定期检查您的镜片是否有划痕或损坏。如果发现任何问题，请及时联系专业眼镜店。',
            avoid_chemical: '避免与化学物质接触：防止眼镜与强酸、强碱、化学清洁剂等物质接触，以防损坏涂层或镜片。',

        },
        prescription: {
            prescription_lenses: 'Blucap Moto 处方镜片',
            money: '$209.00',
            order_for: '您的处方镜片订单将在购买日期后的 6-8 周内履行并发货。',
            experience: '如果您想要体验带有处方镜片的 Blucap Moto，您可以单独订购处方镜片。',
            smart_glasses: '*智能太阳镜不包含在内，必须单独购买。',
            prescription_information: '处方信息',
            how_to_read: '如何阅读您的处方？',
            right_sphere: '右眼度数',
            select: '选择',
            left_sphere: '左眼度数',
            right_cylinder: '右眼散光度数',
            none: '无',
            left_cylinder: '左眼散光度数',
            right_axis: '右眼散光轴位',
            left_axis: '左眼散光轴位',
            right_pd: '右眼瞳距',
            left_pd: '左眼瞳距',
            pd: '瞳距',
            have_2pd: '我有两个瞳距数值',
            the_AXIS: '您输入的轴位数值不正确！这些数值必须介于 1 和 180 之间。',
            remark: '备注',
            type_your: '在此输入您的消息',
            i_confirm: '我确认我的处方的有效性和准确性，确保与我的身体要求相符。我也承认我不会提出任何与处方不符的信息或规格的索赔或请求。',
            i_acknowledge: '我承认所提供的书面规格来自处方，对于完成订单至关重要。我也理解并同意 Blucap 利用和与其第三方供应商和合作伙伴分享我的处方详细信息，仅用于处理交易和履行我的订单的目的。',
            i_understand: '下订单后，专业验光师将与您联系，确认您的处方信息。一旦确认处方信息，将无法退款。',
            i_ifthere: '如果由于与 Blucap 相关的原因导致发货延迟，我们将立即与您联系。您可以选择继续等待或请求退款。',
            coming_soon: '即将推出',
            understanding: '理解您的处方',
            right_eye: '右眼 (OD)',
            stands: 'OD 代表拉丁文中的 "oculus dexter"，意为 "右眼"',
            left_eye: '左眼 (OS)',
            left_stands: 'OS 代表拉丁文中的 "oculus sinister"，意为 "左眼"',
            sphere: '球面度数 (SPH) - 也称为度数 (PWR)',
            they_mean: '它们的含义相同：处方的强度。如果您近视，数值前面会有减号 (-)，如果您远视，数值前面会有加号 (+)。',
            cyl: '柱面度数 (CYL)',
            people: '这适用于散光的人。当眼睛的一部分需要比其他部分更正时，就需要柱面度数。柱面度数的数值前面带有减号 (-)。',
            axis: '轴向',
            this_is_only: '这仅适用于有散光的人。轴向是介于 0 到 180 之间的数字，确定了柱面度数 (CYL) 的方向。',
            oK: '确定',

        },
        news: {
            blucap_unveils: 'Blucap Moto摩托车导航太阳镜的官方发布',
            the_blucap_team: 'Blucap团队，2023年9月13日',
            september_13: '[2023年9月13日] - Blucap，这个开创性的智能眼镜品牌，宣布推出Blucap Moto摩托车导航太阳镜，为摩托车爱好者创造了一种全新的导航和骑行体验。',
            motorcycle: '革命性的摩托车导航',
            blucap_moto_is: 'Blucap Moto是一款可将信息直接显示在骑手视线前的导航设备。它提供了一个无干扰的体验，只有在需要时才会出现转向箭头。作为导航眼镜的代表，Blucap Moto以其地图导航脱颖而出，可以通过手柄遥控器控制，满足复杂道路条件下的所有导航需求。',
            in_addition: '此外，Blucap Moto通过蓝牙连接到手机，导航时无需开启屏幕。骑手可以选择将手机放在包或口袋中，避免了安装手机支架、潜在问题如相机镜头震动损坏、雨水损坏、手机过热和电池消耗等不便。',
            with_blucap_moto: '使用Blucap Moto，骑手可以安全骑行而无需分心，享受沉浸式的骑行体验，无需低头查看手机。',
            navigation_display: '导航显示',
            blucap_has_developed: 'Blucap专为摩托车骑手设计了一款全功能导航应用程序。该应用程序提供了多条路线选项、添加多个停靠点以及避免交通的选项等功能。它可以替代市场上主流的导航应用程序。',
            app_functionality: '应用程序 - 基本功能',
            blucap_has_designed: 'Blucap设计了一个智能路线功能，允许骑手获取由算法生成的推荐骑行路线。路线基于骑手的个人偏好，包括骑行的大致方向和距离，以及他们对弯曲道路或风景点的偏好。这一功能为骑手提供了个性化和愉悦的骑行体验。',
            basic_features: '',
            app_advanced: '应用程序 - 高级功能',
            design: '卓越的设计和佩戴体验',
            according_to_blucap: '根据Blucap的设计理念，智能眼镜首先应该提供舒适的佩戴体验，就像普通眼镜一样。与其他智能或AR眼镜不同，Blucap Moto轻巧，并具有普通太阳镜的外观。薄薄的镜腿和末端的柔软材料使它可以在头盔下轻松佩戴。',
            the_blucap_design: 'Blucap设计团队为电子功能交互定义了"反科技"设计。这款眼镜没有任何按钮、LED指示灯或USB端口。从外表看，它们就像一副低调、经典风格的太阳镜，但实际上内含先进的AR技术。',
            when_unfolding: '当展开并佩戴眼镜时，它们会自动开机并通过蓝牙连接到手机。这使得日常使用就像佩戴和取下普通眼镜一样简单，没有多余的步骤。',
            power_on: '开机',
            the_charging: '充电LED和低频按钮已经移到了磁性充电底座上，眼镜上的电子组件更少。这一设计变更进一步降低了眼镜的重量和功耗。Blucap还开发了一项独特的设计功能，称为ShiftLock™，可快速轻松地更换具有不同光透射级别的外部镜片。这使得适应夜间骑行和使用处方眼镜成为可能。还可以购买偏振和光度可变镜片。',
            release: 'ShiftLock™快速释放设计',
            customized: '定制处方镜片',
            traditional_motorcycle: '传统摩托车太阳镜使用处方镜片的内置支架。这不仅不雅观，还会增加太阳镜的重量。然而，Blucap设计并建立了一个独特镜片系统，通过升级服务可以定制处方镜片。这意味着可以为骑手提供吸引人且轻巧的解决方案，而无需改变外观或添加内置镜片支架。',
            prescription_lenses: '处方镜片',
            cutting_edge: '尖端AR技术',
            focused: 'Blucap专注于尖端Micro LED和波导镜片作为其光学解决方案，以避免传统AR眼镜的臃肿形状、沉重重量和高能耗，以及它们的粗镜腿。这是满足Blucap品牌对眼镜外观和重量的设计要求的唯一方式。Blucap Moto使用JBD的0.13英寸Micro LED，是世界上最小最亮的显示器。由它组成的光引擎仅仅是铅笔末端橡皮擦大小，使它可以离散地植入眼镜腿的根部。与其他AR视觉解决方案相比，它不仅具有不侵犯视野的小尺寸，还具有高亮度和低功耗的优势。',
            microled: 'Blucap Moto和MicroLED光机',
            uniquely: 'Blucap Moto独特设计了一种衍射波导镜片，以满足实际骑行场景的复杂光学性能要求，如高透明度、高亮度传输、防雾、防反光反射和处方定制，相比普通波导镜片。在AR供应商和制造合作伙伴的大力支持下，Blucap Moto已完成了大规模生产前的关键技术确认准备。',
            sales_plan: '销售计划',
            official: 'Blucap Moto的官方零售价为399美元。经销商计划目前正在进行中，有兴趣成为Blucap经销商的人可以填写官方经销商申请表申请。9月底，Blucap Moto将在Kickstarter上启动一个项目，提供早鸟价格。更多详情请关注后续的Kickstarter页面。',
            fully: 'Blucap Moto尚未完全发布，但在过去的三个月里，已在Facebook和Instagram等社交媒体平台上吸引了超过26,000名粉丝。刚刚一个月前推出的经销商计划已经收到了来自世界各地的200多份申请。申请人包括摩托车经销商、装备商店、定制店、眼镜商店以及来自北美、欧洲和亚洲的各种在线渠道和个人。来自世界各地的骑手对Blucap Moto创新产品的浓厚兴趣和期待展示了它的成功潜力。',
            market_resonance: '市场共鸣',
            product_key: '产品关键特点',
            real_time: '导航：实时HUD地图导航，转向指示。佩戴为眼镜时通过蓝牙连接到手机，利用手机的计算能力和GPS硬件；在连接和使用期间，手机可以保持锁定状态。',
            other_hud: '其他HUD功能：速度、超速警报、来电和消息。',
            glasses_appearance: '普通眼镜外观，完全兼容头盔，反科技设计：框架上没有按钮、LED或USB插孔。',
            external_lens: 'ShiftLock™外部镜片快速更换设计，适用于夜间骑行。',
            replacement_lenses: '可选的替代镜片：10种偏振镜片和光度可变镜片，全部具有100%紫外线防护、防雾、防刮擦、疏水和疏油涂层以及对比度增强功能。',
            inner_lens: '内镜支持处方镜片定制。购买Blucap Moto时，用户可以在官方网站下订单时同时提交处方信息。',
            voice_control: '语音控制功能可以通过遥控器触发，并与其他品牌的蓝牙耳机兼容。',
            nose_pad: '鼻垫可更换，尺寸为M和L。',
            comes_with_a: 'Blucap Moto附带磁性充电适配器，便于充电。',
            light_engine: '超低功耗MicroLED光机和计算芯片支持全天骑行导航（平均导航使用时间超过10小时）。',
            remote_control: '遥控器通过蓝牙连接到手机应用程序。具有多种安装附件，支持各种摩托车类型。',
            about_blucap: '关于Blucap',
            about_us: '关于我们→',
            this_article: '文章正文',
            copy_text: '复制文章',
            download_all: '下载所有照片',
            zip569: 'ZIP | 56.9MB',
            advertisement: 'Advertisement_Video_EN.mp4',
            mp4106: 'MP4 | 106MB',
            vertical_advertisement: '30s_Vertical_Advertisement_Video_EN.mp4',
            mp4378: 'MP4 | 37.8MB',
            cg: 'CG_QuickShift_Video.mp4',
            mp447: 'MP4 | 4.7MB',
            cg_auto: 'CG_AutoConnection_Video.mp4',
            mp445: 'MP4 | 4.5MB',
            for_more: 'For more information, please contact:',
            zhaot: 'Zhao Tingting',
            brand_marketing: '品牌营销',
            ztt: 'ztt@blucapsport.com',
            b_m: 'Blucap 媒体',
            mb: 'media@blucapsport.com',
            b_m_img: 'Blucap Moto 照片1',
            b_m_pic: 'Blucap Moto 模型照片 1',
            b_m_color: 'Blucap Moto 彩色镜片 Image 1',
            b_m_unique: 'Blucap Moto 定制镜片',
            b_m_factor: 'Blucap Moto 工厂照片',
            press_contacts: '联系我们',
        },

        about: {
            us: '关于我们',
            starting: '始于渴望',
            initially: '朱宁最初在骑行时遇到了使用手机导航的问题，却找不到理想的摩托车HUD产品。因此，他开始通过DIY的努力创建自己的HUD设备。',
            journey: '旅程开始',
            zhu_ning: '2021年，朱宁创办了Blucap，汇聚了一支充满执着的骑手、工程师、设计师、眼镜专家和地图系统专家的团队。我们对技术创新和品味设计的执着驱使我们不断突破在摩托车骑行体验领域所能达到的极限。',
            built: '耐心打造',
            throughout: '在我们对首款产品Blucap Moto进行为期三年的探索过程中，我们：',
            bravely: '大胆选择了前沿的AR技术，以配合供应链的大规模生产成熟度。',
            spent: '花费了半年以上的时间探索和验证一个技术解决方案，该方案可以平衡HUD骑行互动、眼镜体积和功耗的要求。',
            redesigned: '对整个眼镜进行了重新设计，以获得更可靠的镜片快速更换设计和更好的视觉体验，即使这意味着产品发布的延期超过半年。',
            continuously: '不断地根据我们团队的骑手和全球测试用户的反馈测试和改进产品。我们对眼镜的原型进行了100多次的调整，并持续优化软件系统。',
            reliability_0: '可靠性、专业性',
            reliability_1: ', 和 ',
            reliability_2: '诚信 ',
            reliability_3: '这些是Blucap的核心价值观。我们关注产品的每一个细节，并耐心打磨每一个方面，以实现最终的最佳效果。欢迎来到Blucap，创新与开放的道路交汇之地。',
            hq: 'Hangzhou HQ',
            dream: 'No.22 Dream Town Hangzhou, ZJ 311100, P.R.C',
            support: 'support@blucapsport.com',
            hk: 'Hong Kong',
            ug: 'Unit 2A, 17/F, Glenealy Tower, No.1 Glenealy Central',
            zb: 'zn@blucapsport.com',
            portland: 'Portland',
            upcoming: 'Upcoming'

        }
    }
}