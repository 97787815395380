<template>
  <div class="all" @mouseover="playVideo('last')">
    <div class="w-1-1">
      <video
        playsInline
        loop
        muted
        class="video_3"
        preload="metadata"
        id="last"
      >
        <source
          src="https://blk001.oss-us-west-1.aliyuncs.com/last.mp4"
          type="video/mp4"
        />
      </video>
    </div>
    <div class="w-1-2">
      <div class="content">
        <div class="title">
          {{ $t('message.index.outer_title') }}
        </div>
        <div class="txt-1 color-07">
          Adapts to different light intensities. Transition from clear to dark
          in just <span class="border">20 seconds</span> when exposed to
          sunlight.
          <!-- {{ $t('message.index.outer_content') }} -->
        </div>
        <button01 class="m-t-007" :tab="tab" />
      </div>
    </div>

    <!-- <div
      class="w-8-0"
      v-lazy:background-image="require('../../../assets/20240109/003.jpg')"
    >
      <div class="w-8-2">
        <div class="content01">
          <div class="title">
            {{ $t('message.index.outer_title') }}
          </div>
          <div class="txt-1 color-07">
            {{ $t('message.index.outer_content') }}
          </div>
          <button01 class="m-t-007" :tab="tab" />
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import button01 from '@/components/com/button01.vue' //头部
export default {
  components: {
    button01,
  },
  data() {
    return {
      tab: 2,
    }
  },
  methods: {
    playVideo(ids) {
      var myvideo = document.getElementById(ids)
      myvideo.play()
    },
  },
}
</script>

<style lang="less" scoped>
.all {
  width: 100%;
  position: relative;
  .w-1-1 {
    height: 820px;
    .video_3 {
      width: 100%;
      height: 820px;
      max-width: 2560px;
      object-fit: cover;
      object-position: center;
      display: block;
      margin: 0 auto;
    }
  }
  .w-1-2 {
    width: 1920px;
    margin: 0 auto;
    .content {
      width: 1090px;
      color: #fff;
      padding-left: 270px;
      position: absolute;
      bottom: 90px;
      .m-t-007 {
        margin-top: 36px;
      }
      .title {
        font-family: League Spartan;
        font-size: 42px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: left;
      }

      .txt-1 {
        font-family: League Spartan;
        font-size: 21px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        text-align: left;
        margin-top: 10px;
        color: rgba(255, 255, 255, 0.7);
        .border {
          font-weight: 500;
          color: #fff;
        }
      }
    }
  }
}
</style>
